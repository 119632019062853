import React, { useState, useEffect, useContext } from 'react';

import { css } from '@emotion/css'
import CardWrapper from "../elements/cards/CardWrapper"
import Input from "../elements/Input"
import { AuthApi, ClientApi } from '../api';

import { grey1, blue, button } from "../data/styles"
import AuthContext from '../context/AuthContext';
import { Select } from 'baseui/select';
import RadioButton from "../elements/inputs/RadioButton";

function InviteClient() {
    const { user, funds } = useContext(AuthContext)
    const [name, setName] = useState('')
    const [clientNum, setClientNum] = useState('')
    const [email, setEmail] = useState('')
    const [selectedFunds, setSelectedFunds] = useState([])
    const [status, setStatus] = useState('oninvite')
    const [type, setType] = useState('Individual')
    const [error, setError] = useState('')

    async function handleLogin() {
        if (name.length === 0 || clientNum.length === 0 || email.length === 0) return setError('You must fill out all fields')
        if (!email.includes('@') || !email.includes('.')) return setError('Please check the email address, it has an error in it.')
        if (selectedFunds.length === 0) return setError('Please add this client to at least 1 fund')

        try {
            const _funds = selectedFunds.map(s => s._id)
            let _email = email.toLowerCase().trim()
            const { data } = await ClientApi.create({ company_id: user.company_id, email: _email, name, client_number: clientNum, funds: _funds, type })
            setEmail('')
            setClientNum('')
            setName('')

            setStatus('invited')
        } catch (e) {
            console.log(e)
            setError('Couldnt create client at this time', e)
        }
    }

    return (
        <div style={{ backgroundColor: "#F9F9F9", minHeight: "100vh" }}>

            <div className={css`
                    position: absolute;
                    top: 100px;
                    left: 32px;
              `}>
                <button className={button} style={{ backgroundColor: grey1 }} onClick={() => window.location.pathname = '/'}>Back to Dashboard</button>
            </div>

            <div
                className={css`
	  				width: 100%;
	  				position: relative;

		            @media (min-width: 768px) {
				       display: flex; 
				       flex-direction: column
				    }
		        `}
                style={{ paddingTop: "20vh", width: 480, maxWidth: "100%", margin: "0 auto", paddingBottom: 64 }}
            >


                {status === 'invited' &&
                    <CardWrapper>
                        <div style={{ width: "100%", display: "block" }}>
                            {/* <Success  /> */}
                            <button
                                className={css` 
                                    cursor: pointer;
                                    margin-top: 32px;
				        				font-optical-sizing: auto;
									    font-family: "Outfit";
									    font-style: normal;
									    font-stretch: normal;
									    line-height: initial;
									    font-size: 15px;
									    margin-left: 16px;
									    width: calc(100% - 32px);
				        			`}
                                style={{ backgroundColor: blue, border: "none", color: "#fff", padding: "12px 36px", borderRadius: 4 }}
                                onClick={() => setStatus('oninvite')}
                            >

                                Invite Another Client
                            </button>
                            <button
                                className={css` 
                                    cursor: pointer;
                                    margin-top: 32px;
				        				font-optical-sizing: auto;
									    font-family: "Outfit";
									    font-style: normal;
									    font-stretch: normal;
									    line-height: initial;
									    font-size: 15px;
									    margin-left: 16px;
									    width: calc(100% - 32px);
				        			`}
                                style={{ backgroundColor: grey1, border: "none", color: 'black', padding: "12px 36px", borderRadius: 4 }}
                                onClick={() => window.location = '/'}
                            >

                                Back to Dashboard
                            </button>

                        </div>
                    </CardWrapper>
                }

                {status === 'oninvite' &&
                    <CardWrapper>
                        <div style={{ width: "100%", display: "block" }}>
                            <div>
                                <div style={{ paddingBottom: 0, paddingLeft: 16, paddingRight: 16 }}>
                                    <Input
                                        label='Name'
                                        update={(l, v) => setName(v)}
                                    />
                                </div>
                                <div style={{ paddingBottom: 0, paddingLeft: 16, paddingRight: 16 }}>
                                    <Input
                                        label='Client Number'
                                        update={(l, v) => setClientNum(v)}
                                    />
                                </div>
                                <div style={{ paddingBottom: 0, paddingLeft: 16, paddingRight: 16 }}>
                                    <Input
                                        label='Email'
                                        update={(l, v) => setEmail(v)}
                                    />
                                </div>
                                <div >
                                    <label>Client Type</label>
                                    <RadioButton
                                        value='Individual'
                                        options={['Individual', 'Corporation']}
                                        onSelect={(v) => setType(v.toLowerCase())}
                                    />
                                </div>
                                <div style={{ paddingBottom: 0, paddingLeft: 16, paddingRight: 16 }}>
                                    <p style={{ fontFamily: 'Outfit', marginBottom: 12 }}>Add to Funds</p>
                                    <Select
                                        options={funds}
                                        labelKey='name'
                                        valueKey='_id'
                                        multi={true}
                                        onChange={({ value }) => setSelectedFunds(value)}
                                        value={selectedFunds}
                                    />
                                </div>
                            </div>
                            {error && <p style={{ color: 'red' }}>{error}</p>}

                            <div style={{ paddingBottom: 16, marginTop: 32 }}>
                                <button
                                    className={css` 
				        				font-optical-sizing: auto;
									    font-family: "Outfit";
									    font-style: normal;
									    font-stretch: normal;
									    line-height: initial;
									    font-size: 15px;
									    margin-left: 16px;
									    width: calc(100% - 32px);
                                        cursor: pointer;
				        			`}
                                    style={{ backgroundColor: blue, border: "none", color: "#fff", padding: "12px 36px", borderRadius: 4 }}
                                    onClick={() => handleLogin()}
                                >

                                    Invite
                                </button>
                            </div>
                        </div>
                    </CardWrapper>
                }

            </div>
        </div>
    );
}

export default InviteClient;
