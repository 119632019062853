import React, { useState } from 'react';
import { css } from '@emotion/css'

const switchWidth = "48px"
const switchHeight = "24px"
const switchKnobSize = "16px"
const switchOffset = "4px"

export default function Toggle(props) {
	const [checked, setChecked] = useState(props.defaultValue)

	function toggle(e){
		console.log(e.target.checked)
		setChecked(!checked)
		props.update(!checked)
	}
    return (  
    	<div style={{position: "relative", display: "block", cursor: 'pointer', direction: 'ltr'}}>
        	<input 
        		id={"lng"} 
        		type="checkbox" 
        		onChange={toggle} 
        		checked={checked}
        		className={css`
        			position: absolute;
					opacity: 0;
	               	&:hover {
				        cursor: pointer;
				    }
				    &:checked + label:before {
						background-color: black;
						
					}

					&:checked + label:after {
						background-color: #fff;
						
						transform: translateX(calc(${switchWidth} - ${switchKnobSize} - ${switchOffset} * 2));
					}
	            `}
        	/>

            <label 
            	htmlFor={"lng"}
            	className={css`
        			align-items: center;
					padding-left: 0;
					height: 24px;
			    	line-height: 1;
			    	user-select: none;

					&:before {
						content: "";
					    position: absolute;
					    left: 0;
					    display: inline-block;
					    cursor: pointer;
					    width: ${switchWidth};
					    height: ${switchHeight};
					    background-color: blue;
					    border-radius: 50em;
					}
					&:after {
						content: "";
					    width: ${switchKnobSize};
					    height: ${switchKnobSize};
					    background-color: #fff;
					    display: block;
					    position: absolute;
					    top: ${switchOffset};
					    left: ${switchOffset};
					    border-radius: 50%;
					    transition: transform 0.2s;
					} 

					

					&:hover {
						cursor: pointer;
					}
	            `}
            ></label>
        </div>
    )
}