import React, { useEffect, useState } from 'react';
import { CapitalCallApi } from '../api';

const useCapitalCall = (fundId='fundId', capitalCallId, rerender=0) => {
    const [status, setStatus] = useState('loading')
    const [capitalCall, setCapitalCall] = useState({ })

    useEffect(() => {
        const getCapitalCall = async() => {
            if(!capitalCallId) return;
            setStatus('loading')

            try {
                const { data: cc } = await CapitalCallApi.get(fundId, capitalCallId)
                setCapitalCall(cc)
                setStatus('success')
              
                } catch (e) {
                setStatus('error')
                console.log(e)
            }

        }
        getCapitalCall()
    }, [fundId, capitalCallId, rerender])

    return { status, capitalCall }
}

export { useCapitalCall }