import * as React from "react"
import { Select } from "baseui/select"

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ value = "", options = [], placeholder = "", onSelect, labelKey = "label", valueKey = "id", multi = false, onBlur = () => {}, error = false }) => {
  console.log(value)

  if (value != null && value != "") {
    value = [{ [labelKey]: value, [valueKey]: valueKey }]
  } else {
    value = []
  }

  const firstUpdate = React.useRef(true)
  const [val, setValue] = React.useState(value)
  
  // Format options bc they read in from the data as just a str8 array
  const _options = options.map((o) => {
    return {
      [labelKey]: o,
      [valueKey]: o,
    }
  })

  React.useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false
      return
    }
    console.log(val)
    onSelect(val[0][valueKey])
  }, [val])

  return (
    <div className="selector">
      <Select
        multi={multi}
        options={_options}
        value={val}
        placeholder={placeholder}
        labelKey={labelKey}
        valueKey={valueKey}
        onChange={(params) => setValue(params.value)}
        onBlur={onBlur}
        clearable={false}
        error={error}
      />
    </div>
  )
}
