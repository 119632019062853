import { Modal, ModalHeader, ModalBody, ModalFooter, ModalButton, SIZE, ROLE } from "baseui/modal"
import { KIND as ButtonKind, KIND } from "baseui/button"
import { Input } from 'baseui/input';
import { blue, grey1 } from "../../data/styles";
import { useState } from "react";
import CurrencyInput from "react-currency-input-field";
import { useClientList } from "../../hooks";
import { css } from "@emotion/css";
import { DistributionApi } from "../../api";
import { Datepicker } from "baseui/datepicker";

const CreateDistribution = ({ isOpen, handleClose, fund }) => {
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)
    const [amount, setAmount] = useState()
    const [notes, setNotes] = useState('')
    const [date, setDate] = useState(new Date())

    const { clients } = useClientList(fund?._id)

    const clientsWithAmounts = () => {
        function amountPerClient(clientId) {
            const client = clients.find(c => c._id == clientId)
            if (!client || client?.status?.committed == 0) return 0;

            return {
                amount: ((client.status.committed / fund?.committed) * amount).toFixed(2),
                percentage: ((client.status.committed / fund?.committed) * 100).toFixed(3)
            }
        }

        for (let i = 0; i < clients.length; i++) {
            const stats = amountPerClient(clients[i]._id)
            clients[i].amountToDistribute = stats.amount
            clients[i].ownershipPercentage = stats.percentage
        }
        clients.sort((a, b) => b.ownershipPercentage - a.ownershipPercentage)
        return clients
    }

    const canSubmit = () => {
        return amount > 0 && date != null
    }

    async function submit() {
        const _clients = JSON.parse(JSON.stringify(clientsWithAmounts()))
        try {
            const distributions = {}
            for (let i = 0; i < _clients.length; i++) {
                distributions[_clients[i]._id] = Number(_clients[i].amountToDistribute)

                console.log(distributions, i)
            }
            await DistributionApi.create(fund._id, { amount, notes, distributions, date })
            handleClose()
        } catch (e) {
            setError('Could not create distribution at this time', e)
        } finally {
            setLoading(false)
        }
    }

    return (
        <Modal isOpen={isOpen} onClose={handleClose} overrides={{
            Dialog: {
                style: {
                    width: '800px',
                    height: '80vh',
                    display: 'flex',
                    flexDirection: 'column',
                    overflow: 'auto',
                    fontFamily: "Outfit"
                },
            }
        }}>
            <ModalHeader>Create Distribution for {fund?.name}</ModalHeader>
            <ModalBody>
                <div>
                    <div style={{ marginTop: 16 }}>

                        <div style={{ marginTop: 12 }}>
                            <label>Notes</label>
                            <Datepicker
                                value={date}
                                onChange={({ date }) => {
                                    setDate(date)
                                }}
                            />
                        </div>

                        <div style={{ marginTop: 12 }}>
                            <label>Amount of Funds to Distribute</label>
                            <CurrencyInput
                                style={{ height: 48, width: 200, paddingLeft: 12, borderRadius: 8, fontSize: 16, boxShadow: "none", width: "100%", backgroundColor: "rgb(238,238,238)", border: 'none', fontFamily: "Outfit" }}
                                placeholder={'SAR 10,000,000'}
                                prefix="SAR "
                                decimalsLimit={0}
                                value={amount}
                                onValueChange={(value) => setAmount(value)}
                            />
                        </div>
                        <div style={{ marginTop: 12 }}>
                            <label>Notes</label>
                            <Input placeholder="Notes" onChange={e => setNotes(e.target.value)} value={notes} />
                        </div>
                    </div>

                    <div style={{ marginTop: 16 }}>
                        <div className={css`
                                display: flex;
                                flex-direction: row;
                                justify-content: space-between;
                                margin-bottom: 8px;
                            `}>
                            <p>Name</p>
                            <p>Amount</p>
                        </div>
                        {clientsWithAmounts()?.map((c, i) => (
                            <div className={css`
                                display: flex;
                                flex-direction: row;
                                justify-content: space-between;
                                align-items: center;
                                // border-bottom: 2px solid rgb(220,220,220);
                                margin-bottom: 8px;
                            `}>
                                <div className={css`display: flex; flex-direction: column;`}>
                                    <p className={css`display: flex; flex: 1; font-size: 1rem; font-weight: bold;`}>{c.name} #{c?.client_number}</p>
                                    <p className={css`display: flex; flex: 1; font-size: 1rem;`}>{c.ownershipPercentage}%</p>
                                </div>
                                <div>
                                    <CurrencyInput
                                        style={{ height: 48, width: 200, paddingLeft: 12, borderRadius: 8, fontSize: 16, boxShadow: "none", width: "100%", backgroundColor: "rgb(238,238,238)", border: 'none', fontFamily: "Outfit" }}
                                        placeholder={'SAR 10,000,000'}
                                        prefix="SAR "
                                        decimalsLimit={0}
                                        value={(isNaN(c.amountToDistribute)) ? 0 : c.amountToDistribute}
                                        disabled={true}
                                    />
                                </div>
                            </div>
                        ))}
                    </div>

                    <p style={{ color: 'red' }}>{error}</p>
                </div>
            </ModalBody>
            <ModalFooter>
                <ModalButton onClick={handleClose} kind={KIND.secondary}>
                    Close
                </ModalButton>
                <ModalButton isLoading={loading} onClick={submit} disabled={!canSubmit()} overrides={{
                    BaseButton: {
                        style: ({ $theme }) => ({
                            backgroundColor: blue
                        })
                    }
                }} >Create Distribution</ModalButton>
            </ModalFooter>
        </Modal >
    )
}

export { CreateDistribution }