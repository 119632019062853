export default {
    _id: "abc123",
    data: {
        title: "i46 KYC Individual",
        instructions: ["A. Account Opening Agreement", "B. Know Your Customer (Individuals)", "All sections must be completed using CAPITAL LETTERS"],
        sections: [
            {
                title: "Personal Information",
                subsections: [
                    {
                        title: "",
                        fields: [
                            {
                                id: "g_class",
                                title: "Category for Investor",
                                type: "radio",
                                options: ["Qualified Client", "Retail Client"],
                                value: "Qualified Client",
                                group: "pi_1",
                            },
                            {
                                id: "g_title",
                                title: "Title",
                                type: "dropdown",
                                options: ["Mr.", "Mrs.", "Ms."],
                                value: "Mr.",
                                group: "pi_1",
                            },
                            {
                                id: "g_firstname",
                                title: "First Name",
                                type: "text",
                                group: "pi_2",
                            },
                            {
                                id: "g_fathername",
                                title: "Father's Name",
                                type: "text",
                                group: "pi_2",
                            },
                            {
                                id: "g_grandfathername",
                                title: "Grandfather's Name",
                                type: "text",
                                group: "pi_3",
                            },
                            {
                                id: "g_familyname",
                                title: "Family Name",
                                type: "text",
                                group: "pi_3",
                            },
                            {
                                id: "g_dob",
                                title: "Date of Birth",
                                type: "date",
                                group: "pi_4",
                                cantBe: "after",
                            },
                            {
                                id: "g_pob",
                                title: "Place of Birth",
                                type: "text",
                                group: "pi_4",
                            },
                        ],
                    },
                    {
                        title: "",
                        fields: [
                            {
                                id: "g_idtype",
                                title: "ID Type",
                                type: "dropdown",
                                options: ["National ID", "IQAMA", "Passport", "Others"],
                                value: "National ID",
                                d: "National ID",
                            },
                            {
                                id: "g_idnumber",
                                title: "ID Number",
                                type: "text",
                                d: "1234321234",
                            },
                            {
                                id: "g_datofissue",
                                title: "Date of Issue",
                                type: "date",
                                d: "2020-01-02",
                                cantBe: "after",
                            },
                            {
                                id: "g_placeofissue",
                                title: "Place of Issue",
                                type: "text",
                                d: "Riyadh",
                            },
                            {
                                id: "g_dateofexpiry",
                                title: "Date of Expiry",
                                type: "date",
                                d: "2020-01-02",
                            },
                            {
                                id: "g_gender",
                                title: "Gender",
                                type: "radio",
                                options: ["Male", "Female"],
                                value: "Male",
                            },
                            {
                                id: "g_marital",
                                title: "Marital Status",
                                type: "radio",
                                value: "Married",
                                options: ["Single", "Married", "Divorced"],
                                d: "Married",
                            },
                            {
                                id: "g_numdeps",
                                title: "Number of Dependents",
                                type: "number",
                                d: 0,
                            },
                            {
                                id: "g_nationality",
                                title: "Nationality",
                                type: "text",
                                d: "Saudi Arabia",
                            },
                            {
                                id: "g_addresstype",
                                title: "Address Type",
                                type: "dropdown",
                                options: ["Family", "Owned", "Rental", "Company"],
                                d: "Owned",
                            },
                            {
                                id: "g_phone",
                                title: "Phone Number",
                                type: "phone",
                                country: "SA",
                            },
                            {
                                id: "g_id",
                                title: "Upload a picture of your ID",
                                type: "uploadimage",
                            },
                            {
                                id: "g_issaudi",
                                title: "Are you Saudi?",
                                type: "radio",
                                options: ["Yes", "No"],
                                value: "Yes",
                            },
                            {
                                id: "g_country",
                                title: "Country",
                                type: "text",
                                group: "ci_1",
                                conditionRef: "g_issaudi",
                                condition: "No",
                                value: "Saudi Arabia",
                            },
                            {
                                id: "g_city",
                                title: "City",
                                type: "text",
                                group: "ci_1",
                                conditionRef: "g_issaudi",
                                condition: "No",
                                d: "Riyadh",
                            },
                            {
                                id: "g_district",
                                title: "District",
                                type: "text",
                                group: "ci_2",
                                conditionRef: "g_issaudi",
                                condition: "No",
                                d: "Al-Hejaz",
                            },
                            {
                                id: "g_street",
                                title: "Street",
                                type: "text",
                                group: "ci_2",
                                conditionRef: "g_issaudi",
                                condition: "No",
                                d: "123 Street",
                            },
                            {
                                id: "g_zip",
                                title: "Zip Code",
                                type: "zip",
                                conditionRef: "g_issaudi",
                                condition: "No",
                                d: "12343",
                            },
                        ],
                    },
                ],
            },
            {
                title: "Education & Employment",
                subsections: [
                    {
                        title: "",
                        fields: [
                            {
                                id: "educationLevel",
                                title: "Education Level",
                                type: "dropdown",
                                options: ["Post Graduate", "University", "High School", "Diploma", "Middle School", "Primary", "Other"],
                                d: "Post Graduate",
                            },
                            {
                                id: "hasWorkedInFinance",
                                title:
                                    "Have you worked in the financial sector during the past Five years? (This includes, for example: working for capital market institutions, banks, finance companies, insurance companies)",
                                type: "radio",
                                options: ["Yes", "No"],
                                value: "No",
                            },
                            {
                                id: "assocFirms",
                                title: "List associated firm(s) or organization(s)",
                                type: "list",
                                value: [""],
                                conditionRef: "hasWorkedInFinance",
                                condition: "Yes",
                            },
                            {
                                id: "hasXpInFinance",
                                title: "Do you have any other practical experience related to the financial sector?",
                                type: "radio",
                                options: ["Yes", "No"],
                                value: "No",
                            },
                            {
                                id: "persFinXp",
                                title: "Specify your practical finance experience",
                                type: "text",
                                conditionRef: "hasXpInFinance",
                                condition: "Yes",
                                d: "Managed my portfolio",
                            },
                            {
                                id: "profCerts",
                                title: "Do you have any professional certificates?",
                                type: "radio",
                                options: ["Yes", "No"],
                                value: "No",
                            },
                            {
                                id: "profCertList",
                                title: "List your professional certificates",
                                type: "list",
                                conditionRef: "profCerts",
                                condition: "Yes",
                                value: [""],
                            },
                            {
                                id: "profession",
                                title: "What is your professional status?",
                                type: "dropdown",
                                options: ["Businessman", "Retired", "Employee", "Student", "Not Working", "Other"],
                                d: "Employee",
                            },
                            {
                                id: "profession_employer",
                                title: "The name of the employer",
                                type: "text",
                                conditionRef: "profession",
                                condition: "Retired",
                                group: "prof_1",
                            },
                            {
                                id: "employ_address",
                                title: "Employment Address",
                                type: "text",
                                conditionRef: "profession",
                                condition: "Retired",
                                group: "prof_1",
                            },
                            {
                                id: "profession_phone",
                                title: "Employment Phone #",
                                type: "phone",
                                country: "SA",
                                conditionRef: "profession",
                                condition: "Retired",
                                group: "prof_2",
                            },
                            {
                                id: "profession_job",
                                title: "Job Title",
                                type: "text",
                                conditionRef: "profession",
                                condition: "Retired",
                                group: "prof_2",
                            },
                            {
                                id: "profession_endservice",
                                title: "End of service date",
                                type: "date",
                                conditionRef: "profession",
                                condition: "Retired",
                                group: "prof_3",
                            },
                            {
                                id: "profession_service",
                                title: "Years of service",
                                type: "number",
                                conditionRef: "profession",
                                condition: "Retired",
                                group: "prof_3",
                            },
                            {
                                id: "profession_company",
                                title: "Company Name",
                                type: "text",
                                conditionRef: "profession",
                                condition: "Businessman",
                                group: "prof_1",
                            },
                            {
                                id: "profession_address",
                                title: "Office Address",
                                type: "text",
                                conditionRef: "profession",
                                condition: "Businessman",
                                group: "prof_1",
                            },
                            {
                                id: "profession_officephone",
                                title: "Office Phone #",
                                type: "phone",
                                country: "SA",
                                conditionRef: "profession",
                                condition: "Businessman",
                                group: "prof_2",
                            },
                            {
                                id: "profession_position",
                                title: "Position",
                                type: "text",
                                conditionRef: "profession",
                                condition: "Businessman",
                                group: "prof_2",
                            },
                            {
                                id: "profession_yearsactivity",
                                title: "Years of activity",
                                type: "text",
                                conditionRef: "profession",
                                condition: "Businessman",
                                // group: "prof_3"
                            },
                            {
                                id: "profession_assets",
                                title: "Total Assets",
                                type: "number",
                                conditionRef: "profession",
                                condition: "Businessman",
                                group: "prof_3",
                            },
                            {
                                id: "profession_liabilities",
                                title: "Total Liabilities",
                                type: "number",
                                conditionRef: "profession",
                                condition: "Businessman",
                                group: "prof_3",
                            },
                            {
                                id: "profession_networth",
                                title: "Net Worth / Assets",
                                type: "number",
                                conditionRef: "profession",
                                condition: "Businessman",
                                group: "prof_4",
                            },
                            {
                                id: "profession_profit",
                                title: "Net Profit",
                                type: "number",
                                conditionRef: "profession",
                                condition: "Businessman",
                                group: "prof_4",
                            },
                            {
                                id: "profession_paidup",
                                title: "Paid Up Capital",
                                type: "number",
                                conditionRef: "profession",
                                condition: "Businessman",
                                // group: "prof_5"
                            },
                            {
                                id: "profession_annualincome",
                                title: "Annual Income",
                                type: "number",
                                conditionRef: "profession",
                                condition: "Businessman",
                                group: "prof_5",
                            },
                            {
                                id: "profession_netincome",
                                title: "Net Income",
                                type: "number",
                                conditionRef: "profession",
                                condition: "Businessman",
                                group: "prof_5",
                            },
                            {
                                id: "profession_countriesDealtWith",
                                type: "table_countryactivity",
                                title: "Details of commercial/freelance business activities you deal with",
                                conditionRef: "profession",
                                condition: "Businessman",
                                value: [{ country: "", activity: "" }],
                            },
                            // {
                            //   id: "companyInfo",
                            //   title: "Company Info",
                            //   conditionRef: "profession",
                            //   condition: "Retired|Employee",
                            //   type: "text",
                            // },
                        ],
                    },
                ],
            },
            {
                title: "General Information",
                subsections: [
                    {
                        title: "",
                        fields: [
                            {
                                id: "giDirectorOfPubC",
                                title: "Is the client a board of directors member, an audit committee member or a senior executive in a listed company?",
                                type: "radio",
                                options: ["Yes", "No"],
                                value: "No",
                            },
                            {
                                id: "__giDirectorOfPubC",
                                title: "Please provide a brief description",
                                type: "text",
                                conditionRef: "giDirectorOfPubC",
                                condition: "Yes",
                            },
                            {
                                id: "giPubFinInfo",
                                title: "Is there any information on the customers financial situation?",
                                type: "radio",
                                options: ["Yes", "No"],
                                value: "No",
                            },
                            {
                                id: "__giPubFinInfo",
                                title: "Please provide a brief description",
                                type: "text",
                                conditionRef: "giPubFinInfo",
                                condition: "Yes",
                            },
                            {
                                id: "giGovPos",
                                title:
                                    "Do you occupy, or are you a candidate for a diplomatic or senior civil office in the government, any military office, or an office in a government owned company? Is the client entrusted with prominent public functions in the Kingdom or a foreign country? Senior management positions or a position in an international organization?",
                                type: "radio",
                                options: ["Yes", "No"],
                                value: "No",
                            },
                            {
                                id: "__giGovPos",
                                title: "Please provide a brief description",
                                type: "text",
                                conditionRef: "giGovPos",
                                condition: "Yes",
                            },
                            {
                                id: "giPolCand",
                                title:
                                    "Does any company owner /board member / authorized signer / controlling individual(s) occupy, or is a candidate for, Political or A senior diplomatic or civil office in the government, any military office or an office in a government owned company etc.? or Customer a Director Officer of a Publicity Listed Company or have an association with a person entrusted with a prominent public function in the Kingdom or a foreign country, senior management positions, or a position in an international organization?",
                                type: "radio",
                                options: ["Yes", "No"],
                                value: "No",
                            },
                            {
                                id: "__giPolCand",
                                title: "Please provide a brief description",
                                type: "text",
                                conditionRef: "giPolCand",
                                condition: "Yes",
                            },
                            {
                                id: "giAdvImpExp",
                                title:
                                    "Does the client business activity include either of the following? Advisory firm, travel and tourism offices, precious metals, import and export services, financial institution e.g., money exchangers",
                                type: "radio",
                                options: ["Yes", "No"],
                                value: "No",
                            },
                            {
                                id: "giAccPersOrBus",
                                title: "Is this account for your personal use and you are the beneficial owner of the account or business relationship and it is not for the benefit of another person or persons?",
                                type: "radio",
                                options: ["Yes", "No"],
                                value: "No",
                            },
                            {
                                id: "__giAccPersOrBus",
                                title: "Please provide the details of the beneficiary owner",
                                type: "text",
                                conditionRef: "giAccPersOrBus",
                                condition: "No",
                            },
                            {
                                id: "giPoliticalPersons",
                                title: "Data of the family members of the political person",
                                type: "table_politicalpersons",
                                value: [{ name: "", relation: "", degree: "", employer: "", id_no: "" }],
                                conditionRef: "giPolCand",
                                condition: "Yes",
                            },
                        ],
                    },
                ],
            },
            {
                title: "Financial Information",
                subsections: [
                    {
                        title: "",
                        fields: [
                            {
                                id: "netWorth",
                                title: "Net Worth",
                                type: "money",
                                startEnahncer: "SAR",
                                d: "10000000",
                            },
                        ],
                    },
                    {
                        title: "Income",
                        fields: [
                            {
                                id: "mainSourceOfIncome",
                                title: "Main Source of Income",
                                type: "dropdown",
                                options: ["Job Salary", "Inheritance", "Return from Investments", "Private Business", "Other"],
                                d: "Private Business",
                            },
                            {
                                id: "approximateAnnualIncome",
                                title: "Approximate Annual Income",
                                type: "dropdown",
                                options: ["Less than SAR250,000", "SAR250,001 to SAR500,000", "SAR500,001 to SAR1,000,000", "SAR1,000,001 to SAR5,000,000", "More than SAR5,000,000", "N/A"],
                                value: "More than SAR5,000,000",
                            },
                            {
                                id: "AdditionalSourceOfIncome",
                                title: "Additional Sources of Income",
                                type: "dropdown",
                                options: ["Rental Income", "Salary", "Returns from Investments", "Exceptional Bonus Compensation", "Other"],
                                d: "Returns from Investments",
                            },
                            // {
                            //   id: "approximateNetWorth",
                            //   title: "Approximate Net Worth",
                            //   type: "dropdown",
                            //   options: ["Less than SAR500,000", "SAR500,001 to SAR1,000,000", "SAR1,000,001 to SAR2,000,000", "SAR2,000,001 to SAR5,000,000", "SAR5,000,001 to SAR10,000,000", "More than SAR10,000,000"],
                            // },
                        ],
                    },
                    {
                        title: "Investment Information",
                        fields: [
                            {
                                id: "investmentValue",
                                title: "Total Investment Value",
                                type: "dropdown",
                                options: ["Less than SAR250,000", "SAR250,001 to SAR500,000", "SAR500,001 to SAR1,000,000", "SAR1,000,001 to SAR5,000,000", "More than SAR5,000,000", "N/A"],
                                value: "More than SAR5,000,000",
                            },
                            {
                                id: "investmentShares",
                                title: "Shares",
                                type: "percentage",
                                d: "20",
                            },
                            {
                                id: "debtInstruments",
                                title: "Debt Instruments",
                                type: "percentage",
                                d: "20",
                            },
                            {
                                id: "investmentFunds",
                                title: "Investment Funds",
                                type: "percentage",
                                d: "15",
                            },
                            {
                                id: "realEstate",
                                title: "Real Estate",
                                type: "percentage",
                                d: "35",
                            },
                            {
                                id: "tradeFinance",
                                title: "Trade Finance",
                                type: "percentage",
                                d: "10",
                            },
                        ],
                    },
                    {
                        title: "Product Knowledge",
                        fields: [
                            {
                                id: "billsbonds",
                                title: "Bills / Bonds Issued by Governments, Corporates, or Banks",
                                type: "radio",
                                options: ["Have knowledge", "Have experience"],
                                value: "Have knowledge",
                            },
                            {
                                id: "perps",
                                title: "Perpetuals / Preference Shares / Convertible Bonds",
                                type: "radio",
                                options: ["Have knowledge", "Have experience"],
                                value: "Have knowledge",
                            },
                            {
                                id: "mutfunds",
                                title: "Mutual Funds",
                                type: "radio",
                                options: ["Have knowledge", "Have experience"],
                                value: "Have knowledge",
                            },
                            {
                                id: "hedgefunds",
                                title: "Hedge Funds / Fund Of Hedge Funds, Alternative Mutual Funds",
                                type: "radio",
                                options: ["Have knowledge", "Have experience"],
                                value: "Have knowledge",
                            },
                            {
                                id: "priveq",
                                title: "Private Equity Funds",
                                type: "radio",
                                options: ["Have knowledge", "Have experience"],
                                value: "Have knowledge",
                            },
                            {
                                id: "etfs",
                                title: "Shares / Exchange Traded Funds (ETFs)",
                                type: "radio",
                                options: ["Have knowledge", "Have experience"],
                                value: "Have knowledge",
                            },
                            {
                                id: "competfs",
                                title: "Complex ETFs (e.g. Inverse/ Commodity/ Leveraged/ Proprietary Indices / REIT/ Credit ETFs)",
                                type: "radio",
                                options: ["Have knowledge", "Have experience"],
                                value: "Have knowledge",
                            },
                            {
                                id: "fwds",
                                title: "Forwards",
                                type: "radio",
                                options: ["Have knowledge", "Have experience"],
                                value: "Have knowledge",
                            },
                            {
                                id: "swaps",
                                title: "Swaps And Contract-Based Derivatives (e.g. FX or Equity Accumulators or Decumulators / Bond options)",
                                type: "radio",
                                options: ["Have knowledge", "Have experience"],
                                value: "Have knowledge",
                            },
                        ],
                    },
                    {
                        title: "Risk Tolerance",
                        fields: [
                            {
                                id: "invrepaid",
                                title: "What is the expected time for your investment to be repaid?",
                                type: "dropdown",
                                options: ["Short term (less than one year)", "Medium term (1 - 5 years)", "Long term (greater than 5 years)"],
                                d: "Long term (greater than 5 years)",
                            },
                            {
                                id: "mainreason",
                                title: "What is the main reason for your investment? Your general investment goals?",
                                type: "dropdown",
                                options: ["Purchase an asset (e.g. real estate)", "Growth of capital", "Project financing", "Create savings for retirement", "Protection of capital", "Income", "Balanced"],
                                d: "Project financing",
                            },
                            {
                                id: "invawareness",
                                title: "Which of the following would best describe your level of investment awareness?",
                                type: "dropdown",
                                options: [
                                    "Very limited (little to no knowledge)",
                                    "Basic (understand difference between stocks and bonds)",
                                    "Average (aware of different investment options and risk levels)",
                                    "Extensive (thorough understanding of investment products & strategies",
                                ],
                                d: "Extensive (thorough understanding of investment products & strategies",
                            },
                            {
                                id: "favasset",
                                id: "rt_favAsset",
                                title: "What are your favorite investment assets?",
                                type: "dropdown",
                                options: ["Saudi Riyals", "USD", "Other", "No preference"],
                                d: "Saudi Riyals",
                            },
                            {
                                id: "favcurr",
                                title: "Specify the currency",
                                type: "text",
                                conditionRef: "rt_favAsset",
                                condition: "Other",
                            },
                            {
                                id: "invxp",
                                title: "Your investment knowledge and experience?",
                                type: "radio",
                                value: "Good",
                                options: ["Limited", "Good", "Excellent"],
                                group: "rt_1",
                                d: "Good",
                            },
                            {
                                id: "rskability",
                                title: "What is your ability to take risks?",
                                type: "radio",
                                value: "Medium",
                                options: ["Low", "Medium", "High"],
                                group: "rt_1",
                                d: "Medium",
                            },
                            { id: "yrsinbiz", title: "How many years have you been in business?", type: "radio", value: "More than 5", options: ["Less than 1", "1 - 3", "3 - 5", "More than 5"], d: "More than 5" },
                            {
                                id: "navbelow",
                                title:
                                    "In the event that the net asset value (NAV) of the fund that you invested fall below 15% of your original investment, or the value of your portfolio / portfolios has decreased by up to 15% due to the general decline in the markets what would you do?",
                                type: "dropdown",
                                options: ["Redeem and fully hold cash", "Hold on to it, no action will be taken", "Invest more to average my costs", "Top up aggressively"],
                                d: "Top up aggressively",
                            },
                            {
                                id: "percliabilities",
                                title: "What is the percentage of your monthly liabilities against your income?",
                                type: "radio",
                                value: "20% to 50%",
                                options: ["Less than 20%", "20% to 50%", "More than 75%"],
                                d: "Less than 20%",
                            },
                            {
                                id: "maxperc",
                                title:
                                    "What is the maximum percentage (%) of your investments with us that you can afford to lose in the next 12 months? Affordability refers to the % you could lose without impacting your current standard of living (e.g. being unable to fulfil your monthly living expenses or being unable to settle your monthly financial obligations). Please pick the one that applies.",
                                type: "dropdown",
                                options: ["Less than 1%", "1% - 10%", "10% to 15%", "15% - 20%", "20% - 30%", "More than 30%"],
                                d: "15% - 20%",
                            },
                        ],
                    },
                    {
                        title: "Investment Information",
                        fields: [
                            {
                                id: "whatassets",
                                title: "What assets do you own (type and retention period)",
                                type: "text",
                                group: "ii_1",
                                d: "Real estate for 50 years",
                            },
                            {
                                id: "ratiooftxs",
                                title: "The ratio of what the potential transaction represents to all your assets (for example: the value of subscribing to a fund - the value of a portfolio)",
                                type: "text",
                                group: "ii_1",
                                d: "N/A",
                            },
                            {
                                id: "numyearsinmarket",
                                title: "Number of years of investment in the stock market",
                                type: "text",
                                group: "ii_2",
                                d: "30",
                            },
                            {
                                id: "prodsprevin",
                                title: "Products previously invested in",
                                type: "text",
                                group: "ii_2",
                                d: "Stocks, bonds, mutual funds",
                            },
                            {
                                id: "precloans",
                                title: "The percentage of loans from invested money",
                                type: "percentage",
                                group: "ii_3",
                                d: "10",
                            },
                            {
                                id: "margindeals",
                                title: "Margin financing deals during the past five years",
                                type: "text",
                                group: "ii_3",
                                d: "Yes",
                            },
                            {
                                id: "dealsoutsideksa",
                                title: "Securities deals outside the Kingdom during the previous five years",
                                type: "text",
                                group: "ii_4",
                                d: "Foreign IPO",
                            },
                            {
                                id: "countriesoutsideksa",
                                title: "In which countries were the transactions executed, if securities transactions were executed outside the Kingdom during the previous five years",
                                type: "text",
                                group: "ii_4",
                                d: "Egypt and Italy",
                            },
                        ],
                    },
                ],
            },
        ],
    }
}
