import { useState } from "react"
import { css } from "@emotion/css"
import { blue, grey1 } from "../../data/styles"
import { formatPrettyDate, isDateString } from "../../util/time"
import Label from "../../elements/Label"
import CardWrapper from "../../elements/cards/CardWrapper"
import { useCapitalCallTotals, useClientList, useFundStatus } from "../../hooks"
import { trans } from "../../translations"

const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: "SAR",
    maximumFractionDigits: 0,
});


const englishLabels = ["Amount Distributed", "ROI %", "Date"]
const arabicLabels = ["تم استلام المبلغ", "مجموع ملتزم", "عدد العملاء", "حد اقصى"]

const DistributionItemClient = ({ isEnglish, language, fund, client, distribution, index }) => {
    const { fundStatus } = useFundStatus(fund?._id, client?._id)
    const { called } = useCapitalCallTotals(fund?._id)

    const [isClientDistributionModalOpen, setIsClientDistributionModalOpen] = useState(false)
    const clientName = client?.name
    const totalCommitted = fundStatus?.committed

    return (
        <CardWrapper>
            <div
                className={css`
                    direction: ${isEnglish ? 'ltr' : 'rtl'};
                `}
                style={{ display: "flex", flexDirection: "column", width: "calc(100% - 16px)", marginLeft: 8, marginRight: 8 }}>
                <div style={{ width: "100%", flexDirection: "row", direction: isEnglish ? 'ltr' : 'rtl', display: "flex", justifyContent: "space-between", alignItems: "center", borderBottom: "1px solid #ebebeb", paddingBottom: 16 }}>
                    <div
                        style={{ textAlign: (isEnglish) ? "left" : "right" }}
                        className={css` 
			            				font-optical-sizing: auto;
									    font-family: "Outfit";
									    font-weight: 500;
									    font-style: normal;
									    font-stretch: normal;
									    line-height: initial;
									    font-size: 21px;
									    font-weight: 500;
                                        width: 90%;
			            			`}>
                        {trans('Distribution', language)} {index + 1}</div>
                    {/* <div
                        // onClick={() => window.open(report?.url, '_blank')}
                        className={css` 
                    font-optical-sizing: auto;
                    font-family: "Outfit";
                    font-weight: 500;
                    font-style: normal;
                    font-stretch: normal;
                    line-height: initial;
                    font-size: 20px;
                    font-weight: 500;
                    cursor: pointer;
                    min-width: max-content;
                    align-items: center;
                    display: flex;
                    gap: 12px;
                    &:hover {
                        opacity: 0.5;
                    }
                    `}
                        onClick={() => setIsClientDistributionModalOpen(true)}
                        style={{ textDecoration: "underline", color: blue }}>
                        <span>View Clients</span>
                    </div> */}




                    <div
                        className={css` 
                    font-optical-sizing: auto;
                    font-family: "Outfit";
                    font-weight: 700;
                    font-style: normal;
                    font-stretch: normal;
                    line-height: initial;
                    font-size: 16px;
                    font-weight: 500;
                    cursor: pointer;
                    min-width: max-content;
                    margin-left: 32px;
                    `}>
                    </div>


                </div>

                <div style={{ paddingTop: 24, paddingBottom: 16, display: "block", direction: isEnglish ? 'ltr' : 'rtl' }}>
                    <div
                        className={css`
							            @media (min-width: 768px) {
									       display: flex; 
									    }
							        `}
                        style={{ width: "100%", justifyContent: "space-between", flexDirection: "row", direction: isEnglish ? 'ltr' : 'rtl' }}>
                        <div
                            className={css`
								            @media (max-width: 768px) {
										       padding-bottom: 16px;
										    }
								        `}
                            style={{ width: "16%" }}>
                            <>
                                <p className={css` 
				            				font-optical-sizing: auto;
										    font-family: "Outfit";
										    font-weight: 500;
										    font-style: normal;
										    font-stretch: normal;
										    line-height: initial;
										    font-size: 30px;
										    margin: 0; 
										    margin-top: -8px;
										    padding: 0;
										    padding-bottom: 4px;
				            			`}>{trans('Distribution', language)}</p>
                                {/* <ProgressBar */}
                                {/* progress={parseInt(0)} */}
                                {/* total={parseInt(0)} */}
                                {/* /> */}
                            </>
                        </div>
                        <div className={css`
							            @media (max-width: 768px) {
									       padding-bottom: 16px;
									    }
							        `}>
                            <Label
                                text={trans(englishLabels[0], language)}
                                color={grey1}
                            />
                            <p className={css` 
				            				font-optical-sizing: auto;
										    font-family: "Outfit";
										    font-weight: 500;
										    font-style: normal;
										    font-stretch: normal;
										    line-height: initial;
										    padding-top: 4px;
										    font-size: 18px;
				            			`}>{formatter.format(distribution?.distributions[client?._id])}</p>
                        </div>

                        <div className={css`
							            @media (max-width: 768px) {
									       padding-bottom: 16px;
									    }
							        `}>
                            <Label
                                text={trans(englishLabels[1], language)}
                                color={grey1}
                            />
                            <p className={css` 
				            				font-optical-sizing: auto;
										    font-family: "Outfit";
										    font-weight: 500;
										    font-style: normal;
										    font-stretch: normal;
										    line-height: initial;
										    padding-top: 4px;
										    font-size: 18px;
				            			`}>{(distribution?.distributions[client?._id] * 100 / fund?.committed).toFixed(3)}%</p>
                        </div>

                        <div className={css`
							            @media (max-width: 768px) {
									       padding-bottom: 16px;
									    }
							        `}>
                            <Label
                                text={trans(englishLabels[2], language)}
                                color={grey1}
                            />
                            <p className={css` 
				            				font-optical-sizing: auto;
										    font-family: "Outfit";
										    font-weight: 500;
										    font-style: normal;
										    font-stretch: normal;
										    line-height: initial;
										    padding-top: 4px;
										    font-size: 18px;
				            			`}>{formatPrettyDate(distribution?.createdAt)}</p>
                        </div>

                        {/* <div className={css`
							            @media (max-width: 768px) {
									       padding-bottom: 16px;
									    }
							        `}>
                            <Label
                                text={(isEnglish) ? englishLabels[3] : arabicLabels[3]}
                                color={grey1}
                            />
                            <p className={css` 
				            				font-optical-sizing: auto;
										    font-family: "Outfit";
										    font-weight: 500;
										    font-style: normal;
										    font-stretch: normal;
										    line-height: initial;
										    padding-top: 4px;
										    font-size: 18px;
				            			`}>{formatter.format(called)}</p>
                        </div> */}
                    </div>
                </div>
            </div>
        </CardWrapper>
    )
}

export { DistributionItemClient }