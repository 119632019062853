import React, { useContext, useState } from 'react';
import {
	Link
} from "react-router-dom";

import { css } from '@emotion/css'
import { black, blue, grey1 } from "../data/styles"
import AuthContext from '../context/AuthContext';
import { AddUserToClientModal } from './Dashboard/AddUserToClientModal';
import { UserClientModal } from './Dashboard/UserClientModal';
import { Button } from '../elements/Button';
import LanguageContext from '../context/LanguageContext';
import { trans } from '../translations';

const items = [
	{ name: "Profile", path: "/" },
	{ name: "Client List", path: "/list" },
	{ name: "Capital Calls", path: "/calls" },
	// { name: "Reports", path: "/reports" },
	// {name: "Forms", path: "/forms"},
	{ name: "Documents", path: "/docs" },
	{ name: "Distributions", path: "/distributions" },
	// {name: "Allocations", path: "/allocations"},
]

const clientItems = [
	{ name: "Profile", path: "/" },
	// {name: "Client List", path: "/list"},
	{ name: "Capital Calls", path: "/calls" },
	{ name: "Forms", path: "/forms" },
	{ name: "Reports", path: "/reports" },
	{ name: "Documents", path: "/docs" },
	{ name: "Distributions", path: "/distributions" },
	// {name: "Allocations", path: "/allocations"},
]

// admin arabic
const arabicItems = [
	{ name: trans('Dashboard', 'ar'), path: "/" },
	{ name: trans('Client List', 'ar'), path: "/list" },
	{ name: trans('Capital Calls', 'ar'), path: "/calls" },
	// { name: "Forms", path: "/forms" },
	// { name: "مكالمات رأس المال", path: "/reports" },
	{ name: trans('Documents', 'ar'), path: "/docs" },
	{ name: trans('Distributions', 'ar'), path: "/distributions" },
	// {name: "المخصصات", path: "/allocations"},
]
// console.log(trans(', Add Client']))
const arabicClientItems = [
	{ name: trans('Dashboard', 'ar'), path: "/" },
	{ name: trans('Capital Calls', 'ar'), path: "/calls" },
	{ name: trans('Forms', 'ar'), path: "/forms" },
	{ name: trans('Reports', 'ar'), path: "/reports" },
	{ name: trans('Documents', 'ar'), path: "/docs" },
	{ name: trans('Distributions', 'ar'), path: "/distributions" },
	// {name: "المخصصات", path: "/allocations"},
]

export default function SubNav({ onAddClient = () => undefined }) {
	const { user, fund, client, setClient } = useContext(AuthContext)
	const { isEnglish, language } = useContext(LanguageContext)

	// const [isAddUserOpen, setIsAddUserOpen] = useState(true)
	const [isUserClientModalOpen, setIsUserClientModalOpen] = useState(false)

	const tabs = () => {
		// return items
		if (!user) return []
		if (!isEnglish() && user.is === 'user') return arabicClientItems;
		if (!isEnglish() && user.is === 'admin') return arabicItems;
		if (user.is == 'admin') return items
		return clientItems
	}

	const isOnCurrentTab = (path) => {
		if (window.location.pathname == '/' && path == '/') return true;
		if (window.location.pathname == '/') return false;
		if (path.includes(window.location.pathname)) return true;
		return false
	}

	const name = () => {
		// if (user?.is == 'user') {
		// 	return client?.name
		// }
		return fund?.alias
	}

	const clientName = () => {
		const path = window.location.pathname;
		if (user?.is == 'user') return client?.name;

		if (path.includes('list')) {
			setClient(null)
		}

		if (path.includes('list') || path.includes('calls') || path == '/') {
			return null
		}
		return client?.name
	}

	const renderAddClient = () => {
		const path = window.location.pathname;
		if (user?.is == 'user') return;
		if (!path.includes('list')) return;

		return (
			<div>
				<Button text={trans('Add Client', language)} onClick={onAddClient} />
			</div>
		)
	}

	const renderBulkAddClient = () => {

	}

	return (
		<>
			<div
				className={css`
	  				font-family: "Outfit", sans-serif;
	  				padding: 16px;
		            @media (min-width: 1024px) {
				       padding: 0;
				       max-width: 100%;
				       width: 1100px;
				    }
		        `}
				style={{ position: "relative", paddingTop: 32, margin: "0 auto", textAlign: (isEnglish()) ? "left" : "right" }}>

				<div style={{ fontSize: 26, userSelect: "none", display: 'flex', direction: isEnglish() ? 'ltr' : 'rtl', justifyContent: 'space-between', paddingRight: 24 }}>
					{name()}
					{clientName() &&
						<p style={{ fontSize: '1rem', padding: 12, backgroundColor: blue, color: 'white', borderRadius: 32, cursor: 'pointer' }} onClick={() => setIsUserClientModalOpen(!isUserClientModalOpen)}>
							<div className={css`
								display: flex; 
								flex-direction: row;
								justify-content: space-between;
								align-items: center;
								gap: 12px;
							`}>

								{clientName()}
								<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-user"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg>
							</div>
						</p>
					}
					{renderAddClient()}

				</div>

				<ul style={{ listStyle: "none", display: "block", marginTop: 8 }}>
					{/* {(isEnglish) ?  */}
					<>
						{tabs().map((d, i) => {
							return (
								<Link to={d.path}>
									<li
										className={css`
								  				font-size: 14px;
									            @media (min-width: 768px) {
											       font-size: 16px;
											    }
									        `}
										style={{
											color: isOnCurrentTab(d.path) ? blue : grey1,
											borderBottom: isOnCurrentTab(d.path) ? "2px solid " + blue : grey1,
											display: "inline-block",
											marginRight: 22,

											userSelect: "none"
										}}

									>{d.name}</li>
								</Link>
							)
						})}
					</>
					{/* // : <>
			    	// 	{arabicItems.map((d,i) => {
			    	// 			return(
			    	// 				<Link to={d.path}>
				    // 					<li style={{
				    // 						color: (currentTab === i) ? blue : grey1,
				    // 						borderBottom: (currentTab === i) ? "2px solid " + blue  : grey1,
				    // 						display: "inline-block",
				    // 						marginRight: 22,
				    // 						fontSize: 16,
				    // 						userSelect: "none"
				    // 						}}
				    						
				    // 					>{d.name}</li>
				    // 				</Link>
			    	// 			)
			    	// 		})}
			    	// 	</>
			    	// } */}

				</ul>
				<UserClientModal isOpen={isUserClientModalOpen} user={user} onClose={() => setIsUserClientModalOpen(false)} />
				{/* <AddUserToClientModal client={client} isOpen={isAddUserOpen} onClose={() => setIsAddUserOpen(false)} /> */}
			</div>
		</>


	)
}