import { useState } from "react"
import { css } from "@emotion/css"
import { blue, grey1 } from "../../data/styles"
import { formatPrettyDate, isDateString } from "../../util/time"
import Label from "../../elements/Label"
import CardWrapper from "../../elements/cards/CardWrapper"
import ProgressBar from "../ProgressBar"
import { useCapitalCallTotals, useClientList, useFundStatus } from "../../hooks"
import { Download } from "../../svg"

import { Modal, ModalHeader, ModalBody, ModalFooter, ModalButton, SIZE, ROLE } from "baseui/modal"
import { KIND as ButtonKind, KIND } from "baseui/button"
import CurrencyInput from "react-currency-input-field";


const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: "SAR",
    maximumFractionDigits: 0,
});

const englishLabels = ["Amount Distributed", "ROI %", "Date"]
const arabicLabels = ["تم استلام المبلغ", "مجموع ملتزم", "عدد العملاء", "حد اقصى"]

const DistributionItemAdmin = ({ isEnglish, fund, client, distribution, index }) => {
    const { fundStatus } = useFundStatus(fund?._id, client?._id)
    const { called } = useCapitalCallTotals(fund?._id)

    const [isClientDistributionModalOpen, setIsClientDistributionModalOpen] = useState(false)
    const clientName = client?.name
    const totalCommitted = fundStatus?.committed

    return (
        <CardWrapper>
            <div
                className="card"
                style={{ display: "flex", flexDirection: "column", width: "calc(100% - 16px)", marginLeft: 8, marginRight: 8 }}>
                <div style={{ width: "100%", flexDirection: isEnglish ? "row" : "row-reverse", display: "flex", justifyContent: "space-between", alignItems: "center", borderBottom: "1px solid #ebebeb", paddingBottom: 16 }}>
                    <div
                        style={{ textAlign: (isEnglish) ? "left" : "right" }}
                        className={css` 
			            				font-optical-sizing: auto;
									    font-family: "Outfit";
									    font-weight: 500;
									    font-style: normal;
									    font-stretch: normal;
									    line-height: initial;
									    font-size: 21px;
									    font-weight: 500;
                                        width: 90%;
			            			`}>
                        Distribution {index + 1}</div>
                    <div
                        // onClick={() => window.open(report?.url, '_blank')}
                        className={css` 
                    font-optical-sizing: auto;
                    font-family: "Outfit";
                    font-weight: 500;
                    font-style: normal;
                    font-stretch: normal;
                    line-height: initial;
                    font-size: 20px;
                    font-weight: 500;
                    cursor: pointer;
                    min-width: max-content;
                    align-items: center;
                    display: flex;
                    gap: 12px;
                    &:hover {
                        opacity: 0.5;
                    }
                    `}
                        onClick={() => setIsClientDistributionModalOpen(true)}
                        style={{ textDecoration: "underline", color: blue }}>
                        <span>View Clients</span>
                        {/* <Download height={28} width={28} /> */}
                    </div>




                    <div
                        className={css` 
                    font-optical-sizing: auto;
                    font-family: "Outfit";
                    font-weight: 700;
                    font-style: normal;
                    font-stretch: normal;
                    line-height: initial;
                    font-size: 16px;
                    font-weight: 500;
                    cursor: pointer;
                    min-width: max-content;
                    margin-left: 32px;
                    `}>
                    </div>


                </div>

                <div style={{ paddingTop: 24, paddingBottom: 16, display: "block" }}>
                    <div
                        className={css`
							            @media (min-width: 768px) {
									       display: flex; 
									    }
							        `}
                        style={{ width: "100%", justifyContent: "space-between", flexDirection: (isEnglish) ? "row" : "row-reverse" }}>
                        <div
                            className={css`
								            @media (max-width: 768px) {
										       padding-bottom: 16px;
										    }
								        `}
                            style={{ width: "16%" }}>
                            <>
                                <p className={css` 
				            				font-optical-sizing: auto;
										    font-family: "Outfit";
										    font-weight: 500;
										    font-style: normal;
										    font-stretch: normal;
										    line-height: initial;
										    font-size: 30px;
										    margin: 0; 
										    margin-top: -8px;
										    padding: 0;
										    padding-bottom: 4px;
				            			`}>Distribution</p>
                                {/* <ProgressBar */}
                                {/* progress={parseInt(0)} */}
                                {/* total={parseInt(0)} */}
                                {/* /> */}
                            </>
                        </div>
                        <div className={css`
							            @media (max-width: 768px) {
									       padding-bottom: 16px;
									    }
							        `}>
                            <Label
                                text={(isEnglish) ? englishLabels[0] : arabicLabels[0]}
                                color={grey1}
                            />
                            <p className={css` 
				            				font-optical-sizing: auto;
										    font-family: "Outfit";
										    font-weight: 500;
										    font-style: normal;
										    font-stretch: normal;
										    line-height: initial;
										    padding-top: 4px;
										    font-size: 18px;
				            			`}>{formatter.format(distribution.amount)}</p>
                        </div>

                        <div className={css`
							            @media (max-width: 768px) {
									       padding-bottom: 16px;
									    }
							        `}>
                            <Label
                                text={(isEnglish) ? englishLabels[1] : arabicLabels[1]}
                                color={grey1}
                            />
                            <p className={css` 
				            				font-optical-sizing: auto;
										    font-family: "Outfit";
										    font-weight: 500;
										    font-style: normal;
										    font-stretch: normal;
										    line-height: initial;
										    padding-top: 4px;
										    font-size: 18px;
				            			`}>{(distribution.amount * 100 / fund?.committed).toFixed(3)}%</p>
                        </div>

                        <div className={css`
							            @media (max-width: 768px) {
									       padding-bottom: 16px;
									    }
							        `}>
                            <Label
                                text={(isEnglish) ? englishLabels[2] : arabicLabels[2]}
                                color={grey1}
                            />
                            <p className={css` 
				            				font-optical-sizing: auto;
										    font-family: "Outfit";
										    font-weight: 500;
										    font-style: normal;
										    font-stretch: normal;
										    line-height: initial;
										    padding-top: 4px;
										    font-size: 18px;
				            			`}>{formatPrettyDate(distribution?.createdAt)}</p>
                        </div>

                        {/* <div className={css`
							            @media (max-width: 768px) {
									       padding-bottom: 16px;
									    }
							        `}>
                            <Label
                                text={(isEnglish) ? englishLabels[3] : arabicLabels[3]}
                                color={grey1}
                            />
                            <p className={css` 
				            				font-optical-sizing: auto;
										    font-family: "Outfit";
										    font-weight: 500;
										    font-style: normal;
										    font-stretch: normal;
										    line-height: initial;
										    padding-top: 4px;
										    font-size: 18px;
				            			`}>{formatter.format(called)}</p>
                        </div> */}
                    </div>
                </div>
            </div>
         <ClientDistributionModal distribution={distribution} fund={fund} isOpen={isClientDistributionModalOpen} handleClose={() => setIsClientDistributionModalOpen(false)} />
        </CardWrapper>
    )
}

const ClientDistributionModal = ({ isOpen, handleClose, distribution, fund }) => {
    const { clients } = useClientList(distribution?.fund_id)

    const clientDistributions = () => {
        for(let i = 0; i < clients?.length; i++) {
            clients[i].amountToDistribute = distribution?.distributions[clients[i]._id]
        }
        return clients;
    }

    return (
        <Modal isOpen={isOpen} onClose={handleClose} overrides={{
            Dialog: {
                style: {
                    width: '800px',
                    height: '80vh',
                    display: 'flex',
                    flexDirection: 'column',
                    overflow: 'auto',
                    fontFamily: "Outfit"
                },
            }
        }}>
            <ModalHeader>Client Distributions</ModalHeader>
            <ModalBody>
                <div>
                    <div style={{ marginTop: 16 }}>
                        <div className={css`
                                display: flex;
                                flex-direction: row;
                                justify-content: space-between;
                                margin-bottom: 8px;
                            `}>
                            <p>Name</p>
                            <p>Amount</p>
                        </div>
                        {clientDistributions()?.map((c, i) => (
                            <div key={i} className={css`
                                display: flex;
                                flex-direction: row;
                                justify-content: space-between;
                                align-items: center;
                                // border-bottom: 2px solid rgb(220,220,220);
                                margin-bottom: 8px;
                            `}>
                                <div className={css`display: flex; flex-direction: column;`}>
                                    <p className={css`display: flex; flex: 1; font-size: 1rem; font-weight: bold;`}>{c.name}</p>
                                </div>
                                <div>
                                    <CurrencyInput
                                        style={{ height: 48, width: 200, paddingLeft: 12, borderRadius: 8, fontSize: 16, boxShadow: "none", width: "100%", backgroundColor: "rgb(238,238,238)", border: 'none', fontFamily: "Outfit" }}
                                        placeholder={'SAR 0'}
                                        prefix="SAR "
                                        decimalsLimit={0}
                                        value={(isNaN(c.amountToDistribute)) ? 0 : c.amountToDistribute}
                                        disabled={true}
                                    />
                                </div>
                            </div>
                        ))}
                    </div>

                </div>
            </ModalBody>
            <ModalFooter>
                <ModalButton onClick={handleClose} kind={KIND.secondary}>
                    Close
                </ModalButton>
            </ModalFooter>
        </Modal >
    )
}

export { DistributionItemAdmin }