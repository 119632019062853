import React, { useContext } from 'react';
import { css } from '@emotion/css'
import { grey1, blue } from "../data/styles"
import LanguageContext from '../context/LanguageContext';

const height = 10

export default function ProgressBar({ progress, total, color = blue }) {
	const { isEnglish } = useContext(LanguageContext)

	const getWidth = () => {
		if (progress / total * 100 >= 100) return '100%'

		return ((progress / total) * 100).toString() + '%'
	}
	return (
		<div style={{ width: "100%", height: height, backgroundColor: "#EBEBEB", borderRadius: "50em", direction: isEnglish() ? 'ltr' : 'rtl' }}>
			<div>
				<div
					style={{
						backgroundColor: color || blue,
						height: height,
						width: getWidth(),
						borderTopLeftRadius: "50em",
						borderBottomLeftRadius: "50em",
						borderTopRightRadius: "50em",
						borderBottomRightRadius: "50em",
					}}>
				</div>
			</div>
		</div>
	)
}