import React, { useEffect, useState } from 'react';
import { FormApi } from '../api';

const useForms = (query, trigger=0) => {
    const [status, setStatus] = useState('loading')
    const [forms, setForms] = useState([])

    useEffect(() => {
        const getForms = async () => {
            if (!query) return;
            if(!query?.client_id || query?.client_id == 'undefined') return            
            setStatus('loading')

            try {
                const { data: _forms } = await FormApi.find(query)
                setForms(_forms)
                setStatus('success')
            } catch (e) {
                setStatus('error')
            }

        }
        getForms()
    }, [query?.client_id, trigger])

    return { status, forms }
}

export { useForms }