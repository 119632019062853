import React, { useEffect, useState } from 'react';
import { FundApi } from '../api';

const useFundStatus = (fundId, clientId, rerender=0) => {
    const [status, setStatus] = useState('loading')
    const [fundStatus, setFundStatus] = useState({})

    useEffect(() => {
        const getFundStatus = async() => {
            if(!fundId || !clientId) return;
            setStatus('loading')

            try {
                const { data: _fundStatus } = await FundApi.getStatus(fundId, clientId)
                setFundStatus(_fundStatus)
                setStatus('success')
            } catch(e) {
                setStatus('error')
            }

        }
        getFundStatus()
    }, [fundId, clientId, rerender])

    return { status, fundStatus }
}

export { useFundStatus }