import { trans } from "../translations"

export const nameForDocType = (type, language) => {
    switch (type) {
        case 'capital_call':
            return trans('Capital Call', language)
        case 'capital_call_payment':
            return trans('Payment', language)
        case 'tc':
            return trans('T&C', language)
        case 'fund_tc':
            return trans('Fund T&C', language)
        case 'id':
            return trans('ID', language)
        case 'kyc':
            return trans('KYC', language)
        case 'fatca':
            return trans('FATCA', language)
        case 'sub_confirmation':
            return trans('Subscription', language)
        case 'commercial-registration':
            return trans('Commercial Registration', language)
        case 'board-decision':
            return trans('Board Decision', language)
        case 'articles-of-association':
            return trans('Articles of Association', language)
        case 'qfi_proof':
            return trans('QFI Proof', language)
        case 'misc':
            return ''
        default:
            return type;
    }
}