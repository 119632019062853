import {
  BrowserRouter,
  Route,
  Switch
} from 'react-router-dom';

import { useEffect, useState, Suspense, lazy } from 'react'
import { Client as Styletron } from 'styletron-engine-atomic';
import { Provider as StyletronProvider } from 'styletron-react';
import { LightTheme, BaseProvider, styled } from 'baseui';
import { FundApi, UserApi } from './api';

import Nav from './components/Nav'
import SectionManager from './components/Form/SectionManager';
import { AuthProvider } from './context/AuthProvider';
import { LanguageProvider } from './context/LanguageProvider';
import InviteClient from './pages/InviteClient';
import { Preview } from './pages/Preview';
import { Profile } from './components/Profile/Profile';
import { AddFund } from './pages/Fund/AddFund';
import Distributions from './pages/Distributions';
import { css } from '@emotion/css';
import { ToastProvider } from './context/ToastProvider';


const Allocations = lazy(() => import('./pages/Allocations'))
const ClientList = lazy(() => import('./pages/ClientList'))
const Calls = lazy(() => import('./pages/Calls'))
const Docs = lazy(() => import('./pages/Docs'))
const Dashboard = lazy(() => import('./pages/Dashboard'))
const Forms = lazy(() => import('./pages/Forms'))
const Reports = lazy(() => import('./pages/Reports'))
const DataRoom = lazy(() => import('./pages/DataRoom'))
const Compliance = lazy(() => import('./pages/Compliance'))
const Home = lazy(() => import('./pages/Home'))


const Login = lazy(() => import('./pages/Login'))

// const engine = new Styletron();

function App() {
  const [currentFund, setCurrentFund] = useState("idk")
  const [isEnglish, setisEnglish] = useState(true)
  const [user, setUser] = useState({})
  const [client, setClient] = useState({})
  const [fund, setFund] = useState({})

  // useEffect(() => {
  //   if (user?._id) return;
  //   async function getUser() {
  //     const { data: user } = await UserApi.get('636d53f484561a000d62da67')
  //     setUser(user)
  //   }
  //   getUser()

  //   if (fund?._id) return;
  //   async function getFund() {
  //     const { data: fund } = await FundApi.get('636d54d2d629af0039d81a85')
  //     // console.log('fund', fund)
  //     setFund(fund)
  //   }
  //   getFund()
  // }, [])

  // function updateCurrentFund(f) {
  //   setCurrentFund(f)
  // }

  function updateLanguage(l) {
    setisEnglish(l)
  }

  return (

    <LanguageProvider>
      <AuthProvider>
        <ToastProvider>
          <BrowserRouter>
            <Suspense fallback={<div></div>}>
              <Switch>

                <Route exact path="/" render={() => (
                  <div>
                    <Nav
                      updateLanguage={updateLanguage}
                    />
                    <Dashboard />
                  </div>
                )} />

                {/* <Route exact path="/home" render={() => (
                  <div>
                    <Nav
                      updateLanguage={updateLanguage}
                      isEnglish={isEnglish}
                    />
                    <Home
                      isEnglish={isEnglish}
                    />
                  </div>
                )} /> */}

                <Route exact path="/list" render={() => (
                  <div>
                    <Nav
                      updateLanguage={updateLanguage}
                      isEnglish={isEnglish}
                    />
                    <ClientList
                      user={user}
                      fund={fund}
                      isEnglish={isEnglish}
                    />
                  </div>
                )} />

                <Route exact path="/forms/:formId" render={({ match }) => (
                  <div>
                    <SectionManager formId={match.params.formId} />
                  </div>
                )} />

                <Route exact path="/calls" render={({ match }) => (
                  <div>
                    <Nav
                      updateLanguage={updateLanguage}
                      isEnglish={isEnglish}
                    />
                    <Calls
                      fundId={match.params.fundId}
                      isEnglish={isEnglish}
                    />
                  </div>
                )} />

                <Route exact path="/forms" render={() => (
                  <div>
                    <Nav
                      updateLanguage={updateLanguage}
                      isEnglish={isEnglish}
                    />
                    <Forms
                      user={user}
                      fund={fund}
                      isEnglish={isEnglish}
                    />
                  </div>
                )} />

                <Route exact path="/reports" render={() => (
                  <div>
                    <Nav
                      updateLanguage={updateLanguage}
                      isEnglish={isEnglish}
                    />
                    <Reports
                      isEnglish={isEnglish}
                    />
                  </div>
                )} />

                <Route exact path="/docs" render={() => (
                  <div>
                    <Nav
                      updateLanguage={updateLanguage}
                      isEnglish={isEnglish}
                    />
                    <Docs
                      user={user}
                      fund={fund}
                      isEnglish={isEnglish}
                    />
                  </div>
                )} />

                <Route exact path="/dataroom" render={() => (
                  <div>
                    <Nav
                      updateLanguage={updateLanguage}
                      isEnglish={isEnglish}
                    />
                    <DataRoom
                      user={user}
                      fund={fund}
                      isEnglish={isEnglish}
                    />
                  </div>
                )} />

                <Route exact path="/compliance" render={() => (
                  <div>
                    <Nav
                      updateLanguage={updateLanguage}
                      isEnglish={isEnglish}
                    />
                    <Compliance
                      user={user}
                      fund={fund}
                      isEnglish={isEnglish}
                    />
                  </div>
                )} />

                <Route exact path="/invite-client" render={() => (
                  <div>
                    <Nav
                      updateLanguage={updateLanguage}
                      isEnglish={isEnglish}
                    />
                    <InviteClient
                      user={user}
                      fund={fund}
                      isEnglish={isEnglish}
                    />
                  </div>
                )} />

                <Route exact path="/preview/:clientId/:id/:type" render={({ match }) => (
                  <div>
                    <Nav
                      updateLanguage={updateLanguage}
                      isEnglish={isEnglish}
                    />
                    {/* <InviteClient
                  user={user}
                  fund={fund}
                  isEnglish={isEnglish}
                /> */}
                    {console.log(match)}
                    <Preview clientId={match.params.clientId} id={match.params.id} type={match.params.type} close={() => window.location = '/docs'} />
                  </div>
                )} />

                <Route exact path="/client/:clientId/calls" render={({ match }) => (
                  <div>
                    <Nav
                      updateLanguage={updateLanguage}
                      isEnglish={isEnglish}
                    />
                    <Calls
                      user={user}
                      fund={fund}
                      isEnglish={isEnglish}
                    />
                  </div>
                )} />

                <Route exact path="/distributions" render={({ match }) => (
                  <div>
                    <Nav
                      updateLanguage={updateLanguage}
                      isEnglish={isEnglish}
                    />
                    <Distributions
                      user={user}
                      fund={fund}
                      isEnglish={isEnglish}
                    />
                  </div>
                )} />

                <Route exact path="/profile" render={({ match }) => (
                  <div>
                    <Nav
                      updateLanguage={updateLanguage}
                      isEnglish={isEnglish}
                    />
                    <Profile
                      isEnglish={isEnglish}
                    />
                  </div>
                )} />

                <Route exact path="/add-fund" render={({ match }) => (
                  <div>
                    <Nav
                      updateLanguage={updateLanguage}
                      isEnglish={isEnglish}
                    />
                    <AddFund
                      isEdit={false}
                    />
                  </div>
                )} />

                <Route exact path="/edit-fund" render={({ match }) => (
                  <div>
                    <Nav
                      updateLanguage={updateLanguage}
                      isEnglish={isEnglish}
                    />
                    <AddFund
                      isEdit={true}
                    />
                  </div>
                )} />

                {/* <Route exact path="/allocations" render={() => (
                <div>
                  <Nav
                    updateLanguage={updateLanguage}
                    isEnglish={isEnglish}
                  />
                  <Allocations />
                </div>
              )} /> */}

                <Route exact path="/login" render={() => (
                  <Login />
                )} />

              </Switch>
            </Suspense>
          </BrowserRouter>
        </ToastProvider>
      </AuthProvider>
    </LanguageProvider>
  );
}

export default App;
