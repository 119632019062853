import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { css } from '@emotion/css'
import { black, grey1, blue } from "../data/styles"
import { Saudi, UK } from "../data/flags"
import Toggle from "../elements/Toggle"
import NotificationCard from "../components/NotificationCard"
import { useNotifications } from '../hooks';
import AuthContext from '../context/AuthContext';
import { AuthApi } from '../api';
import { Button } from '../elements/Button';
import LanguageContext from '../context/LanguageContext';
import { trans } from '../translations';

export default function Nav(props) {
	const { user, fund, funds, setFund } = useContext(AuthContext)
	const { language, setLanguage, isEnglish } = useContext(LanguageContext)

	const router = useHistory()

	const isAdmin = () => user?.is === 'admin'
	const isCompliance = () => user?.is === 'compliance'

	const [isVisible, setIsVisible] = useState(false)
	const [isProfileOpen, setIsProfileOpen] = useState(false)
	const [languageSelectIsVisible, setLanguageIsVisible] = useState(false)

	const [drawerIsVisible, setDrawerIsVisible] = useState(false)
	const [notificationsIndex, setNotificationsIndex] = useState(0)

	const { notifications } = useNotifications(user?.client_id)

	function selectFund(f) {
		setIsVisible(false)
		setFund(f._id)
		localStorage.setItem('active_fund', '0x' + f._id)
	}

	function toggleDrawer() {
		setDrawerIsVisible(!drawerIsVisible)
	}

	async function handleLogout() {
		await AuthApi.logout()
		window.location.reload()
	}

	async function handleProfile() {
		setDrawerIsVisible(false)
		router.push('/profile')
	}

	const initials = () => {
		if (!user) return '';
		const nameChunks = user.name.split(' ')
		if (nameChunks.length >= 2) {
			return nameChunks[0].substring(0, 1) + nameChunks[1].substring(0, 1)
		}
		return nameChunks[0].substring(0, 1)
	}

	// function handleOnHome() {
	// 	if (isAdmin() || isCompliance()) {
	// 		return router.push('/')
	// 	}
	// 	return router.push('/home')
	// }

	if(!user) return <></>

	return (
		<>
			<div style={{ height: "100vh", width: "100vw", top: 0, transition: "0.5s all", position: "fixed", visibility: (drawerIsVisible) ? "visible" : "hidden", zIndex: 2 }} >
				<div
					style={{ backgroundColor: "#f9f9f9", height: "100vh", width: 380, transition: "all .4s cubic-bezier(.645,.045,.355,1)", top: 0, right: (drawerIsVisible) ? 0 : -380, position: "absolute", backgroundColor: "#fff", zIndex: 2, boxShadow: "-4px 4px 7px rgb(0 0 0 / 10%)", border: "1px solid #DFDFDF" }}
				>
					<ul style={{ padding: 16, marginTop: 8, direction: 'rtl', }}>

						<li
							className={css`
							font-size: 14px;
							&:hover{
								cursor:pointer;
							}
							@media (min-width: 768px) {
								font-size: 16px;
							}
					        `}
							style={{
								color: (notificationsIndex === 0) ? blue : grey1,
								borderBottom: (notificationsIndex === 0) ? "2px solid " + blue : grey1,
								display: "inline-block",
								marginRight: 22,
								userSelect: "none"
							}}
							onClick={() => setNotificationsIndex(0)}

						>Notifications</li>
						<li
							className={css`
				  				font-size: 14px;
				  				&:hover{
				  					cursor:pointer;
				  				}
					            @media (min-width: 768px) {
							       font-size: 16px;
							    }
					        `}
							style={{
								color: (notificationsIndex === 1) ? blue : grey1,
								borderBottom: (notificationsIndex === 1) ? "2px solid " + blue : grey1,
								display: "inline-block",
								marginRight: 22,
								userSelect: "none"
							}}
							onClick={() => setNotificationsIndex(1)}

						>To dos</li>
					</ul>

					{(notificationsIndex === 0) ?
						<ul style={{ listStyle: "none", fontFamily: "Outfit", padding: 8 }}>
							{notifications.filter(n => n.type == 'log').map((d, i) => {
								return (
									<NotificationCard
										data={d}
									/>
								)
							})}
						</ul>
						: <ul style={{ listStyle: "none", fontFamily: "Outfit", padding: 8 }}>
							{notifications.filter(n => n.type == 'todo').map((d, i) => {
								return (
									<NotificationCard
										data={d}
									/>
								)
							})}
						</ul>}

				</div>
				<div
					className={css`
		               	&:hover {
					        cursor: pointer;
					    }
		            `}
					style={{ height: "100vh", transition: "all .4s cubic-bezier(.645,.045,.355,1)", width: "100vw", position: "fixed", backgroundColor: (drawerIsVisible) ? "rgba(0,0,0,0)" : "rgba(0,0,0,0)", zIndex: 1 }}
					onClick={() => setDrawerIsVisible(!drawerIsVisible)}
				>
				</div>
			</div>


			<div className={css`
				display: flex;
	              font-family: "Outfit", sans-serif;
	              background-color: ${black};
	              width: 100%;
				  flex-direction: ${isEnglish() ? 'row' : 'row-reverse'};
	              z-index: 1;
	              justify-content: space-between;
				  align-items: center;
	            `}
				style={{ position: "relative" }}>

				<div className={css`
							direction: ${isEnglish() ? 'ltr' : 'rtl'};
			               	&:hover {
						        cursor: pointer;
						    }
			            `} style={{ display: "flex", alignItems: "center" }} onClick={() => setIsVisible(!isVisible)}>
					<div style={{ padding: 12, borderRight: "1px solid #787878", rotate: isEnglish() ? '0deg' : '180deg' }}>
					<svg xmlns="http://www.w3.org/2000/svg"  width="22" height="22" viewBox="0 0 24 24" fill="none" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-menu"><line x1="3" y1="12" x2="21" y2="12"></line><line x1="3" y1="6" x2="21" y2="6"></line><line x1="3" y1="18" x2="21" y2="18"></line></svg>
					</div>
					<p style={{ margin: 0, marginLeft: 24, marginRight: isEnglish() ? 0 : 16, padding: 0, color: "#fff" }}>{fund?.alias}</p>

				</div>
				{/* <p style={{ color: 'white', alignSelf: 'center' }}>IMPACT46</p> */}
				<img src='/logorect.jpeg' height={32}/>


				<div style={{ display: "flex", alignItems: "center", paddingRight: 16, paddingLeft: isEnglish() ? 0 : 8, cursor: 'pointer', direction: isEnglish() ? 'ltr' : 'rtl' }}>
					<div style={{ display: 'flex', flexDirection: 'row', gap: 16, marginRight: 32 }}>

						<p style={{ color: 'white', alignSelf: 'center', cursor: 'pointer', color: blue }} onClick={() => router.push('/')}>{trans('Home', language)}</p>
						{(isAdmin() || isCompliance()) &&
							<p style={{ color: 'white', alignSelf: 'center', cursor: 'pointer', color: blue }} onClick={() => router.push('/dataroom')}>{trans('Data Room', language)}</p>
						} 
						{(isAdmin() || isCompliance()) &&
							<p style={{ color: 'white', alignSelf: 'center', cursor: 'pointer', color: blue }} onClick={() => router.push('/compliance')}>{trans('Compliance', language)}</p>
						}
					</div>
					{/* {isAdmin() &&
						<p onClick={() => window.location = '/invite-client'} style={{ cursor: 'pointer', color: blue, marginRight: 32 }}>Invite</p>
					} */}

					{/* Notifications */}
					{/* <div style={{ marginRight: 32, display: "block", position: "relative" }} onClick={toggleDrawer}>
						<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-bell"><path d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9"></path><path d="M13.73 21a2 2 0 0 1-3.46 0"></path></svg>
					</div> */}

					{/* Language */}
					<div style={{ marginRight: 32, marginLeft: isEnglish() ? 0 : 24, display: "block", position: "relative" }}>
						<div onClick={() => setLanguageIsVisible(!languageSelectIsVisible)}>
							<svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 24 24" fill="none" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-globe"><circle cx="12" cy="12" r="10"></circle><line x1="2" y1="12" x2="22" y2="12"></line><path d="M12 2a15.3 15.3 0 0 1 4 10 15.3 15.3 0 0 1-4 10 15.3 15.3 0 0 1-4-10 15.3 15.3 0 0 1 4-10z"></path></svg>
						</div>

						{(languageSelectIsVisible) ?
							<>
								<div style={{ position: "absolute", top: 32, height: "auto", width: "auto", backgroundColor: "#fff", right: isEnglish() ? 0 : null, left: isEnglish() ? null : 0, boxShadow: "2px 4px 7px rgb(0 0 0 / 10%)", border: "1px solid #DFDFDF", borderRadius: 8, zIndex: 2, cursor: 'pointer' }}>
									<div style={{ display: "flex", justifyContent: "space-between", padding: "8px 24px", alignItems: "center", direction: 'ltr' }}>
										<span>{Saudi}</span>
										<div style={{ padding: 8 }}>
											<div className="clearfix relative" style={{ width: 54, padding: 8, height: 24 }}>
												<Toggle
													update={(val) => setLanguage(val === true ? 'en' : 'ar')}
													defaultValue={language == 'en'}
												/>
											</div>
										</div>
										<span>{UK}</span>
									</div>
								</div>

								<div
									onClick={() => setLanguageIsVisible(!languageSelectIsVisible)}
									style={{ position: "fixed", height: "100vh", width: "100vw", backgroundColor: "rgba(0,0,0,0)", top: 0, left: 0 }}></div>
							</>
							: ""}


					</div>
					<div style={{ padding: 8, borderRadius: "50em", position: "relative", fontSize: 12, marginRight: isEnglish() ? 8 : 0, marginLeft: isEnglish() ? 0 : 8, cursor: 'pointer' }} onClick={() => setIsProfileOpen(!isProfileOpen)}>
						{initials()}

						<span style={{
							content: "",
							backgroundColor: "#fff",
							height: 32,
							width: 32,
							position: "absolute",
							top: -2,
							left: 0,
							borderRadius: "50em",
							zIndex: -1
						}}></span>
					</div>
					<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-down"><polyline points="6 9 12 15 18 9"></polyline></svg>
					<div className={css`
						position: fixed;
						display: ${isProfileOpen ? 'relative' : 'none'};
						top: 40px;
						// left: 0px;
						left: ${isEnglish() ? null : '0px'};
						right: ${isEnglish() ? '0px' : null};
						height: min-content; width: 200px;
						background-color: rgb(220,220,220);
					
					`}>
						<div className={css`
							display: flex;
							flex-direction: column;
							direction: ${isEnglish() ? 'ltr' : 'rtl'};
							span {
								padding: 16px;
								&:hover {
									background-color: ${blue};
									color: white;
								}
							}
						`}>
							<span onClick={handleProfile}>{trans('Profile', language)}</span>
							<span onClick={handleLogout}>{trans('Logout', language)}</span>

						</div>
					</div>
				</div>
			</div>


			<div style={{ height: "100vh", width: "100vw", top: 0, transition: "0.5s all", position: "fixed", visibility: (isVisible) ? "visible" : "hidden", zIndex: 2 }} >
				<div style={isEnglish()
					? { overflow: 'scroll', height: "100vh", width: 280, transition: "all .4s cubic-bezier(.645,.045,.355,1)", top: 0, left: (isVisible) ? 0 : -280, position: "absolute", backgroundColor: "#fff", zIndex: 2 }
					: { overflow: 'scroll', height: "100vh", width: 280, transition: "all .4s cubic-bezier(.645,.045,.355,1)", top: 0, right: (isVisible) ? 0 : -280, position: "absolute", backgroundColor: "#fff", zIndex: 2 }
				}>
					{/* <div style={{ overflow: 'scroll', height: "100vh", width: 280, transition: "all .4s cubic-bezier(.645,.045,.355,1)", top: 0, position: "absolute", backgroundColor: "#fff", zIndex: 2 }}> */}
					{/* <div style={{ overflow: 'scroll', height: "100vh", width: 280, transition: "all .4s cubic-bezier(.645,.045,.355,1)", top: 0, right: isEnglish() ? (isVisible) ? 0 : -280 : -280, position: "absolute", backgroundColor: "#fff", zIndex: 2 }}> */}
					<ul style={{ listStyle: "none", fontFamily: "Outfit", padding: 8, paddingBottom: 64 }}>
						{funds.map((d, i) => {
							return (
								<li className={css`
			    							padding: 16px;
			    							border-radius: 8px;
							               	&:hover {
										        cursor: pointer;
										        background-color: #ebebeb;
										        
										    }
							            `}
									onClick={() => selectFund(d)}>{d.alias}</li>
							)
						})}
					</ul>
					<div className={css`
						display: relative;
						position: fixed;
						bottom: 16px;
						// left: 32px; 
						width: 220px;
						align-items: center;
						justify-content: center;
						background-color: orange;
					`} style={isEnglish() ? { left: '32px' } : { 'right': '32px' }}>
						<Button text={'Add Fund'} onClick={() => router.push('/add-fund')} />
					</div>
				</div>

				<div
					className={css`
			               	&:hover {
						        cursor: pointer;
						    }
			            `}
					style={{ height: "100vh", transition: "all .4s cubic-bezier(.645,.045,.355,1)", width: "100vw", position: "fixed", backgroundColor: (isVisible) ? "rgba(0,0,0,0.5)" : "rgba(0,0,0,0)", zIndex: 1 }}
					onClick={() => setIsVisible(!isVisible)}
				>
				</div>
			</div>

		</>


	)
}