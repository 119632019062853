import React from 'react';
import { css } from '@emotion/css'

export default function Input(props) {
    return (  
    	<>
    		{(props.type === "search") ? 
	    		<div className={css`
		              width: 100%;
		              position: absolute;
		              border-radius: 50%;
		            `} 
		    		style={{position: "relative"}}>
		    		<div  className={css`
		              	position: absolute;
					    top: 0;
					    bottom: 0;
					    left: 16px;
					    display: -webkit-box;
					    display: -webkit-flex;
					    display: -ms-flexbox;
					    display: flex;
					    -webkit-align-items: center;
					    -webkit-box-align: center;
					    -ms-flex-align: center;
					    align-items: center;
		            `}>
		    			<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#5B5A5A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-search"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>
		    		</div>
		    		<input 
		    			placeholder="Search"
		    			className={css`
			              	width: 100%;
						    margin: 0;
						    padding: 16px;
						    padding-left: 56px;
						    font-size: 14px;
						    font-size: inherit;
						    line-height: 1;
						    font-family: inherit;
						    color: inherit;
						    background-color: rgba(0,0,0,0.06);
						    border: 0;
						    -webkit-appearance: none;
						    -moz-appearance: none;
						    appearance: none;
						    outline: 0;
						    border-radius: 6px;
			            `}

			            onChange={e => props.update(e.target.value)}
		    			
		    		 />
		    	</div>
	    	: 	
	    		<div style={{marginBottom: 16}}>
	    			<label className={css` 
		        				font-optical-sizing: auto;
							    font-family: "Outfit";
							    margin-bottom: 8px;
							    font-style: normal;
							    font-stretch: normal;
							    line-height: initial;
							    font-size: 16px;
							    position: relative;
							    display: block;
		        			`}>{props.label}</label>
	    			<input 
		    			className={css`
			              	width: 100%;
			              	position: relative;
						    margin: 0;
						    padding: 16px;
						    font-size: 14px;
						    font-size: inherit;
						    line-height: 1;
						    font-family: inherit;
						    color: inherit;
						    background-color: #fff;
						    border: 1px solid #969696;
						    font-optical-sizing: auto;
							    font-family: "Outfit";
							    margin-bottom: 8px;
							    font-style: normal;
							    font-stretch: normal;
							    line-height: initial;

						    box-sizing: border-box;
						    -webkit-appearance: none;
						    -moz-appearance: none;
						    appearance: none;
						    outline: 0;
						    border-radius: 6px;
			            `}
			            onChange={e => props.update(props.label, e.target.value)}
		    			
		    		 />
		    	</div>
		    }
    	</>
    )
}