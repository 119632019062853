import React, { useEffect, useState } from 'react';
import { CapitalCallApi } from '../api';

const useCapitalCallList = (fundId, rerender=0) => {
    const [status, setStatus] = useState('loading')
    const [capitalCalls, setCapitalCalls] = useState([])

    useEffect(() => {
        const getClients = async() => {
            if(!fundId) return;
            setStatus('loading')

            try {
                const { data: _capitalCalls } = await CapitalCallApi.getAll(fundId)
                setCapitalCalls(_capitalCalls.sort((a,b) => new Date(b.createdAt) - new Date(a.createdAt)))
                setStatus('success')
            } catch(e) {
                setStatus('error')
            }

        }
        getClients()
    }, [fundId, rerender])

    return { status, capitalCalls }
}

export { useCapitalCallList }