import React, { useEffect, useState } from 'react';
import { FundApi } from '../api';

const useClientList = (fundId, rerender=0) => {
    // const [status, setStatus] = useState('loading')
    const [loading, setLoading] = useState(false)
    const [clients, setClients] = useState([])

    useEffect(() => {

        const getClients = async() => {
            if(!fundId) return;
            setLoading(true)
            try {
                const { data: _clients } = await FundApi.getClients(fundId)
                setClients(_clients.sort((a,b) => Number(a.client_number) - Number(b.client_number)))
                setLoading(false)
            } catch(e) {
                // setStatus('error')
                console.log(e)
            } finally {
                setLoading(false)
            }

        }
        getClients()
    }, [fundId, rerender])

    return { loading, clients }
}

export { useClientList }