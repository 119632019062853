import { useEffect, useState } from 'react';
import { ClientApi, UserApi } from '../api';

/**
 * Gets all the users for a client
 * @param {*} userId 
 * @param {*} withUsers if this is true, it will put all of the users for that client on the client
 * @param {*} rerender 
 * @returns 
 */
const useClientsOfUser = (userId, withUsers = false, rerender = 0) => {
    const [status, setStatus] = useState('loading')
    const [clients, setClients] = useState([])

    useEffect(() => {
        const getClients = async () => {
            if (!userId) return;
            setStatus('loading')

            try {
                const { data: _clients } = await UserApi.getClients(userId)

                if (withUsers) {
                    for (let i = 0; i < _clients.length; i++) {
                        const { data: users } = await ClientApi.getUsers(_clients[i]._id)
                        _clients[i] = {
                            ..._clients[i],
                            users: users
                        }
                    }
                }

                setClients(_clients)
                setStatus('success')
            } catch (e) {
                setStatus('error')
            }

        }
        getClients()
    }, [userId, rerender])

    return { status, clients }
}

export { useClientsOfUser }