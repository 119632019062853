import { css } from "@emotion/css"
import { Input } from "baseui/input"
import { useState } from "react"
import { useEffect } from "react"
import { useContext } from "react"
import CurrencyInput from "react-currency-input-field"
import { useHistory } from "react-router-dom"
import { FundApi } from "../../api"
import SubNav from "../../components/SubNav"
import AuthContext from "../../context/AuthContext"
import { Button } from "../../elements/Button"
import RadioButton from '../../elements/inputs/RadioButton';

const AddFund = ({ isEdit = false }) => {
    const { user, authenticate: refreshFunds, fund: _fund } = useContext(AuthContext)
    const router = useHistory()

    const cleanFund = () => {
        _fund.meta.mgmt_fee = _fund?.meta?.mgmt_fee / 100
        _fund.meta.carried_interest = _fund?.meta?.carried_interest / 100
        _fund.meta.expected_drawdowns = _fund?.meta?.expected_drawdowns / 100
        return _fund
    }
    console.log('fundddd', _fund)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)
    const [fund, setFund] = useState({ type: 'multi', meta: { unit_price: 10 }})

    useEffect(() => {
        if (isEdit && _fund?._id) {
            setFund(cleanFund())
        }
    }, [_fund?._id])

    const isValid = () => {
        return fund?.name && fund?.value && fund?.description && fund?.meta?.vintage && fund?.meta?.unit_price && fund?.meta?.mgmt_fee && fund?.meta?.carried_interest && fund?.meta?.expected_drawdowns && fund?.meta?.harvesting_period_str && fund?.meta?.investment_period
    }

    async function handleCreateFund() {
        // Handle double click
        if (loading) return;

        setLoading(true)
        setError(null)

        if (!isValid()) {
            setLoading(false)
            return setError('Please enter all fields')
        }

        try {
            const _fundToCreate = {
                ...fund
            }
            console.log('NOW', fund.meta)
            _fundToCreate.meta.mgmt_fee *= 100
            _fundToCreate.meta.carried_interest *= 100
            _fundToCreate.meta.expected_drawdowns *= 100
            console.log('AFTER', fund.meta)

            if(isEdit) {
                await FundApi.update(_fundToCreate._id, _fundToCreate)
            } else {
                await FundApi.create({ ..._fundToCreate, company_id: user.company_id })
            }
            await refreshFunds()
            router.push('/')
        } catch (e) {
            console.log(e)
            setError('Cannot create fund', e)
        } finally {

        }
    }

    const renderInput = (key, label, placeholder) => {
        return (
            <div className={css`display: flex; flex: 1; flex-direction: column;`}>
                <label>{label}</label>
                <div style={{ height: 8 }} />
                <Input
                    placeholder={placeholder}
                    onChange={(e) => setFund({ ...fund, [key]: e.target.value })}
                    value={fund[key]}
                />
            </div>
        )
    }

    const renderInputMeta = (key, label, placeholder, endEnhancer = '') => {
        return (
            <div className={css`display: flex; flex: 1; flex-direction: column;`}>
                <label>{label}</label>
                <div style={{ height: 8 }} />
                <Input
                    placeholder={placeholder}
                    onChange={(e) => setFund({ ...fund, meta: { ...fund.meta, [key]: e.target.value } })}
                    value={fund.meta[key]}
                    endEnhancer={endEnhancer}
                />
            </div>
        )
    }

    const renderCurrencyInput = (key, label, placeholder) => {
        return (
            <div className={css`display: flex; flex: 1; flex-direction: column;`}>
                <label>{label}</label>
                <div style={{ height: 8 }} />
                <CurrencyInput
                    style={{ height: 48, width: 200, paddingLeft: 12, borderRadius: 8, fontSize: 16, boxShadow: "none", width: "100%", backgroundColor: "rgb(238,238,238)", border: 'none', fontFamily: "Outfit" }}
                    placeholder={placeholder}
                    prefix="SAR "
                    decimalsLimit={0}
                    value={fund[key]}
                    onValueChange={(value) => setFund({ ...fund, [key]: value })}
                />
            </div>
        )
    }

    const renderCurrencyInputMeta = (key, label, placeholder) => {
        return (
            <div className={css`display: flex; flex: 1; flex-direction: column;`}>
                <label>{label}</label>
                <div style={{ height: 8 }} />
                <CurrencyInput
                    style={{ height: 48, width: 200, paddingLeft: 12, borderRadius: 8, fontSize: 16, boxShadow: "none", width: "100%", backgroundColor: "rgb(238,238,238)", border: 'none', fontFamily: "Outfit" }}
                    placeholder={placeholder}
                    prefix="SAR "
                    decimalsLimit={0}
                    value={fund.meta[key]}
                    onValueChange={(value) => setFund({ ...fund, meta: { ...fund.meta, [key]: value } })}
                />
            </div>
        )
    }

    return (
        <div style={{ backgroundColor: "rgb(249, 249, 249)", minHeight: "100vh" }}>
            <div onClick={() => window.location.pathname = '/'} style={{ position: 'absolute', left: 16, top: 64, backgroundColor: 'rgb(234,234,234)', padding: 8, paddingLeft: 24, paddingRight: 24, borderRadius: 4, fontFamily: "Outfit", cursor: 'pointer' }}>
                <p>Back</p>
            </div>

            <div className={css`
                display: flex;
                flex: 1;
                flex-direction: column;
                // background-color: blue;
                min-height: 92vh;
                max-width: 1100px;
                margin-left: auto;
                margin-right: auto;
                padding-top: 32px;
                font-family: 'Outfit';
            `}>

                <h3 className={css`
                    font-size: 2rem;
                    font-weight: 700;
                `}>{isEdit ? 'Edit ' + fund.alias : 'Add Fund'}</h3>

                {/* Title, fund target, description */}
                <div className={css`
                        display: flex;
                        // flex: 1;
                        flex-direction: column;
                        justify-content: flex-start;
                        gap: 12px;
                        margin-top: 32px;
                    `}>

                    <div className={css`
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        gap: 16px;
                    `}>
                        {renderInput('name', 'Name', 'Venture Capital Fund in Electronic Services, Blockchain, and SaaS')}
                        {renderInput('alias', 'Alias', 'Tech Fund')}
                        {renderCurrencyInput('value', 'Target Value', 'SAR 500,000,000')}

                    </div>
                    {renderInput('description', 'Description', 'This fund is focused on high growth tech startups in the KSA...')}

                </div>
                <div className={css`margin-top: 32px;`}>
                    <div className={css`display: flex; flex: 1; flex-direction: column;`}>
                        <label>Fund Type</label>
                        <div style={{ height: 8 }} />
                        <RadioButton options={['single', 'multi']} onSelect={(value) => setFund({ ...fund, type: value })} value={fund?.type} />

                    </div>
                </div>
                <div className={css`
                        display: flex;
                        // flex: 1;
                        flex-direction: column;
                        justify-content: flex-start;
                        gap: 12px;
                        margin-top: 32px;
                    `}>
                    <div className={css`
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        gap: 32px;
                    `}>
                        {renderCurrencyInputMeta('unit_price', 'Unit Price', 'SAR 10')}
                        {renderInputMeta('vintage', 'Vintage Year', '2023')}
                    </div>
                </div>
                <div className={css`
                        display: flex;
                        // flex: 1;
                        flex-direction: column;
                        justify-content: flex-start;
                        gap: 12px;
                        margin-top: 32px;
                    `}>
                    <div className={css`
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        gap: 32px;
                    `}>
                        {renderInputMeta('mgmt_fee', 'Management Fee', '2', '%')}
                        {renderInputMeta('carried_interest', 'Carried Interest', '20', '%')}
                        {renderInputMeta('expected_drawdowns', 'Expected Drawdowns', '20', '%/Quarter')}
                    </div>
                </div>
                <div className={css`
                        display: flex;
                        // flex: 1;
                        flex-direction: column;
                        justify-content: flex-start;
                        gap: 12px;
                        margin-top: 32px;
                    `}>
                    <div className={css`
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        gap: 32px;
                    `}>
                        {renderInputMeta('harvesting_period_str', 'Harvest Period', '8 Years + 2 Years')}
                        {renderInputMeta('investment_period', 'Investment Period', '10', 'Years')}
                    </div>
                </div>
                <div style={{ marginTop: 64 }}>
                    {error && <p style={{ color: 'red', fontFamily: 'Outfit', marginBottom: 8 }}>{error}</p>}
                    <Button loading={loading} disabled={!isValid()} text={isEdit ? 'Edit Fund' : 'Create Fund'} onClick={handleCreateFund} />
                </div>
                {/* <p style={{color: 'black'}}>Details</p> */}
            </div>
        </div>
    )
}

export { AddFund }