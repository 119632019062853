import React, { useEffect } from 'react';
import { css, keyframes } from '@emotion/css'

const stroke = keyframes`
	  100% {
        stroke-dashoffset: 0;
    }`

const scale = keyframes`
	  0%, 100% {
        transform: none;
    }

    50% {
        transform: scale3d(1.1, 1.1, 1);
    }`

const fill = keyframes`
	100% {
        box-shadow: inset 0px 0px 0px 30px #4bb71b;
    }`

export default function Success({ text }) {
	useEffect(() => {
		init()

		return () => {
			function makeItStop() {

				const element = document.getElementById("confetti-canvas");
				element?.remove()
			}
			makeItStop()
		}
	}, [])

	function init() {
		var maxParticleCount = 150; //set max confetti count
		var particleSpeed = 2; //set the particle animation speed
		var startConfetti; //call to start confetti animation
		var stopConfetti; //call to stop adding confetti
		var toggleConfetti; //call to start or stop the confetti animation depending on whether it's already running
		var removeConfetti; //call to stop the confetti animation and remove all confetti immediately


		startConfetti = startConfettiInner;
		stopConfetti = stopConfettiInner;
		toggleConfetti = toggleConfettiInner;
		removeConfetti = removeConfettiInner;
		var colors = ["DodgerBlue", "OliveDrab", "Gold", "Pink", "SlateBlue", "LightBlue", "Violet", "PaleGreen", "SteelBlue", "SandyBrown", "Chocolate", "Crimson"]
		var streamingConfetti = false;
		var animationTimer = null;
		var particles = [];
		var waveAngle = 0;

		function resetParticle(particle, width, height) {
			particle.color = colors[(Math.random() * colors.length) | 0];
			particle.x = Math.random() * width;
			particle.y = Math.random() * height - height;
			particle.diameter = Math.random() * 10 + 5;
			particle.tilt = Math.random() * 10 - 10;
			particle.tiltAngleIncrement = Math.random() * 0.07 + 0.05;
			particle.tiltAngle = 0;
			return particle;
		}

		function startConfettiInner() {
			var width = window.innerWidth;
			var height = window.innerHeight;
			window.requestAnimationFrame = (function () {
				return window.requestAnimationFrame ||
					window.webkitRequestAnimationFrame ||
					window.mozRequestAnimationFrame ||
					window.oRequestAnimationFrame ||
					window.msRequestAnimationFrame ||
					function (callback) {
						return window.setTimeout(callback, 16.6666667);
					};
			})();
			var canvas = document.getElementById("confetti-canvas");
			if (canvas === null) {
				canvas = document.createElement("canvas");
				canvas.setAttribute("id", "confetti-canvas");
				canvas.setAttribute("style", "display:block;z-index:999999;pointer-events:none;position:fixed;top:0;");
				document.body.appendChild(canvas);
				canvas.width = width;
				canvas.height = height;
				window.addEventListener("resize", function () {
					canvas.width = window.innerWidth;
					canvas.height = window.innerHeight;
				}, true);
			}
			var context = canvas.getContext("2d");
			while (particles.length < maxParticleCount)
				particles.push(resetParticle({}, width, height));
			streamingConfetti = true;
			if (animationTimer === null) {
				(function runAnimation() {
					context.clearRect(0, 0, window.innerWidth, window.innerHeight);
					if (particles.length === 0)
						animationTimer = null;
					else {
						updateParticles();
						drawParticles(context);
						const animationTimer = requestAnimationFrame(runAnimation);
					}
				})();
			}
		}

		function stopConfettiInner() {
			streamingConfetti = false;
		}

		function removeConfettiInner() {
			stopConfetti();
			particles = [];
		}

		function toggleConfettiInner() {
			if (streamingConfetti)
				stopConfettiInner();
			else
				startConfettiInner();
		}

		function drawParticles(context) {
			var particle;
			var x;
			for (var i = 0; i < particles.length; i++) {
				particle = particles[i];
				context.beginPath();
				context.lineWidth = particle.diameter;
				context.strokeStyle = particle.color;
				x = particle.x + particle.tilt;
				context.moveTo(x + particle.diameter / 2, particle.y);
				context.lineTo(x, particle.y + particle.tilt + particle.diameter / 2);
				context.stroke();
			}
		}

		function updateParticles() {
			var width = window.innerWidth;
			var height = window.innerHeight;
			var particle;
			waveAngle += 0.01;
			for (var i = 0; i < particles.length; i++) {
				particle = particles[i];
				if (!streamingConfetti && particle.y < -15)
					particle.y = height + 100;
				else {
					particle.tiltAngle += particle.tiltAngleIncrement;
					particle.x += Math.sin(waveAngle);
					particle.y += (Math.cos(waveAngle) + particle.diameter + particleSpeed) * 0.5;
					particle.tilt = Math.sin(particle.tiltAngle) * 15;
				}
				if (particle.x > width + 20 || particle.x < -20 || particle.y > height) {
					if (streamingConfetti && particles.length <= maxParticleCount)
						resetParticle(particle, width, height);
					else {
						particles.splice(i, 1);
						i--;
					}
				}
			}
		}

		startConfetti()
	}


	function makeItStop() {

		const element = document.getElementById("confetti-canvas");
		element.remove()
	}

	function letsGo() {
		init()
	}

	return (
		<>
			{/*<button onClick={() => makeItStop()}>Make it stop</button>
    		<button onClick={() => letsGo()}>lets go</button>*/}
			<div className={css`
		            width: 100px;
				    height: 100px;
				    border-radius: 50%;
				    display: block;
				    stroke-width: 2;
				    stroke: #4bb71b;
				    stroke-miterlimit: 10;
				    box-shadow: inset 0px 0px 0px #4bb71b;
				    animation: ${fill} 1.4s ease-in-out 1.4s forwards, scale 1.3s ease-in-out 1.9s both;
				    position:relative;
				    top: 5px;
				    right: 5px;
				   	margin: 0 auto;
	            `}>
				<svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
					<circle className={css`
			            stroke-dasharray: 166;
					    stroke-dashoffset: 166;
					    stroke-width: 2;
					    stroke-miterlimit: 10;
					    stroke: #4bb71b;
					    fill: #fff;
					    animation: ${stroke} 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
	            	`}

						cx="26" cy="26" r="25" fill="none" />
					<path className={css`
			            transform-origin: 50% 50%;
					    stroke-dasharray: 48;
					    stroke-dashoffset: 48;
					    animation: ${stroke} 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
	            	`}
						fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
				</svg>
				{/* <div className={css`
					font-size: 1rem;
					margin-left: auto; margin-right: auto;
					width: 400px;
					background-color: blue;
					margin-top: 32px;
					padding-right: 200px;
					`
				}>
					<p className={css`
					font-size: 1rem;
					width: 400px;
					`}>{text}</p>
				</div> */}
			</div>
		</>
	)
}