import { http, serialize } from './api';

const DOC = '/doc';

export function create(data) {
    return http.post(DOC, data)
}

export function getAll(userId, fundId, query) {
    query = '?' + serialize(query)
    return http.get(DOC + '/' + userId + '/' + fundId + query)
}

export function getCorporationComplianceDocs(clientId) {
    return http.get(DOC + '/corporation/' + clientId)
}