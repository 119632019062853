import { http, serialize } from './api';

const BANK_ACCOUNT = '/bank-account'
const MY_BANK_ACCOUNT = 'bank-account/me'

export function create(account) {
    return http.post(BANK_ACCOUNT, account)
}

export function update(accountId, account) {
    return http.put(BANK_ACCOUNT + '/' + accountId, account)
}

export function list() {
    return http.get(BANK_ACCOUNT)
}

export function get(bankAccountId) {
    return http.get(BANK_ACCOUNT + '/' + bankAccountId)
}

export const me = {
    get: function(clientId) {
        return http.get(MY_BANK_ACCOUNT + '/' + clientId)
    }
}