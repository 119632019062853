const translations = {
    'Dashboard': 'لوحة القيادة',
    'Capital Calls': 'نداءات رأس المال',
    'Client List': "قائمة العملاء",
    'Documents': 'وثائق',
    'Distributions': 'التوزيعات',
    'Forms': 'نماذج',
    'Reports': 'التقارير',
    'Add Client': 'إضافة عميل',
    'Search': 'بحث',
    'Name': 'اسم',
    '$ Committed': 'وعد',
    '$ Received': 'تلقى',
    'Account Status': 'حالة',
    'Last Login': 'آخر تسجيل دخول',
    'Profile': '',
    'Fund Value': '',
    'Active Clients': '',
    'Fund Growth': '',
    'In Compliance': '',
    'Fund Name': '',
    'Fund Vintage': '',
    'Total Committed': '',
    'Total Called': '',
    'My Ownership': '',
    'My Funds': '',
    'Management Fee': '',
    'Carried Interest': '',
    'Harvesting Period': '',
    'Expected Drawdowns': '',
    'Active Capital Call': '',
    'Capital Call Status': '',
    'Signed': '',
    'Unsigned': '',
    'Approved': '',
    'Pending': '',
    'View Forms': '',
    'View Docs': '',
    'View Admins': '',
    'View': '',
    'Complete': '',
    'Incomplete': '',
    'Never': '',
    'Sort': '',
    'A-Z': '',
    'Z-A': '',
    // Context: sort by who is missing forms
    'Missing Forms': '',
    // Context: sort by who is missing capital calls
    'Missing Calls': '',
    // Context: sort by clients with highest commitments to lowest
    'High-Low Commitments': '',
    'Low-High Commitments': '',
    'No Capital Calls Yet': '',
    'Total Received': '',
    // Context: number of clients who completed this capital call
    'Num. of Completions': '',
    'Deadline': '',
    'View Clients': '',
    'View Emails': '',
    'Make Capital Call': '',
    'Title': '',
    // Context: What % of capital do you want to call
    'What % of Total Committed Funds': '',
    'Message': '',
    'Next': '',
    'Close': '',
    'Back': '',
    'Subscribe': '',
    'Submit': '',
    'No Docs Yet': '',
    'File name': '',
    'Date Created': '',
    'Actions': '',
    'Action Items': '',
    'Upload': 'bruh',
    'Upload File': '',
    'Ready to Upload': '',
    'Public': '',
    'Private': '',
    'Drop files here to upload': '',
    'Browse Files': '',
    'Create Distribution': '',
    'Date': '',
    'Amount Distributed': '',
    'Distribution': '',
    'Click Here to Complete Form': '',
    'Review': '',
    'Approval Pending': '',
    'Back to Dashboard': '',
    'Take me to Missing Field': '',
    'Upload a screenshot or pdf of your wire transfer to IMPACT46': '',
    'Upload Documents': '',
    'Amount to Pay': '',
    'Reupload': '',
    'Paid': '',
    'Pay Now': '',
    'Percent Called': '',
    'Home': '',
    'Download Account Statement': '',
    'Download Report': '',
    'Weight': '',
    'Number of Units': '',
    'Called to Date': '',
    'No reports have been created': '',
    'Please check your email for a one-time password. It might be in your junk/spam. If it has not arrived after 2 minutes, please resend code': '',
    'Sign In': '',
    'Powered by Jeffery': '',
    'Notifications': '',
    'To Dos': '',
    'Terms & Conditions': '',
    'Missing Documents': '',
    'Selection of documents required by the Capital Markets Authority (CMA) in order to conduct business with IMPACT46': '',
    'Commercial Registration': '',
    'Required': '',
    'Switch': '',
    'Articles of Association': '',
    'Board Decision to Open Account': '',
    'Optional': '',
    'Expiration Date': '',
    'Click here to upload': '',
    'Click here to accept our Terms & Conditions': '',
    'Awaiting Payment Confirmation': '',
    'Compounded Quarterly Growth': ''
}

function trans(defaultValue, language) {
    if (language === 'en') return defaultValue

    const translated = _translations.find(f => f.English.toLowerCase() == defaultValue.toLowerCase())
    if (translated) return translated.Arabic
    return defaultValue

    // if (translations[defaultValue]) return translations[defaultValue]
    // return defaultValue
}

// export default translations
export { trans }

const _translations = [
    {
        "English": "Dashboard",
        "Arabic": "لوحة التحكم"
    },
    {
        "English": "Capital Calls",
        "Arabic": "مطالبات رأس المال"
    },
    {
        "English": "Client List",
        "Arabic": "قائمة العملاء"
    },
    {
        "English": "Documents",
        "Arabic": "وثائق"
    },
    {
        "English": "Distributions",
        "Arabic": "التوزيعات"
    },
    {
        "English": "Forms",
        "Arabic": "نماذج واستمارات"
    },
    {
        "English": "Reports",
        "Arabic": "تقارير"
    },
    {
        "English": "Add Client",
        "Arabic": "إضافة عميل"
    },
    {
        "English": "Search",
        "Arabic": "بحث"
    },
    {
        "English": "Name",
        "Arabic": "اسم"
    },
    {
        "English": "Commitment",
        "Arabic": "الملتزم به"
    },
    {
        "English": "$ Received",
        "Arabic": "المبالغ المستلمة"
    },
    {
        "English": "Account Status",
        "Arabic": "حالة الحساب"
    },
    {
        "English": "Last Login",
        "Arabic": "آخر تسجيل دخول"
    },
    {
        "English": "Profile",
        "Arabic": "الملف الشخصي"
    },
    {
        "English": "Fund Value",
        "Arabic": "قيمة الصندوق"
    },
    {
        "English": "Fund Growth",
        "Arabic": "نمو الصندوق"
    },
    {
        "English": "Compounded Quarterly Growth",
        "Arabic": "النمو الفصلي المركب"
    },
    {
        "English": "Active Clients",
        "Arabic": "عملاء نشطين"
    },
    {
        "English": "Clients",
        "Arabic": "عملاء"
    },
    {
        "English": "Fund NAV (per unit)",
        "Arabic": "القيمة الصافية للاصول (للوحدة)"
    },
    {
        "English": "In Compliance",
        "Arabic": "متوافق"
    },
    {
        "English": "Compliance",
        "Arabic": "ادارة الالتزام"
    },
    {
        "English": "Data Room",
        "Arabic": "غرفة البيانات"
    },
    {
        "English": "Fund Name",
        "Arabic": "اسم الصندوق"
    },
    {
        "English": "Fund Alias",
        "Arabic": "الاسم المستعار للصندوق"
    },
    {
        "English": "Fund Vintage",
        "Arabic": "تاريخ انشاء الصندوق"
    },
    {
        "English": "Total Committed",
        "Arabic": "اجمالي الاتزامات"
    },
    {
        "English": "Committed",
        "Arabic": "اجمالي الاتزامات"
    },
    {
        "English": "$ Committed",
        "Arabic": "اجمالي الاتزامات"
    },
    {
        "English": "My Commitment",
        "Arabic": "التزامي"
    },
    {
        "English": "Total Called",
        "Arabic": "اجمالي المطالبات"
    },
    {
        "English": "My Ownership",
        "Arabic": "تسبة ملكيتي"
    },
    {
        "English": "My Funds",
        "Arabic": "صناديقي"
    },
    {
        "English": "Management Fee",
        "Arabic": "رسوم الإدارة"
    },
    {
        "English": "Carried Interest",
        "Arabic": "نسبة مشاركة الارباح"
    },
    {
        "English": "Harvesting Period",
        "Arabic": "فترة التخارج"
    },
    {
        "English": "Expected Drawdowns",
        "Arabic": "المطالبات المتوقعة"
    },
    {
        "English": "Active Capital Call",
        "Arabic": "طلب التزام راس مال جاري"
    },
    {
        "English": "Active",
        "Arabic": "دائر"
    },
    {
        "English": "Capital Call",
        "Arabic": "دعوة رأس المال"
    },
    {
        "English": "Capital Call Status",
        "Arabic": "حالة طلب التزام رأس المال"
    },
    {
        "English": "Signed",
        "Arabic": "تم التوقيع"
    },
    {
        "English": "Unsigned",
        "Arabic": "غير موقع"
    },
    {
        "English": "Approved",
        "Arabic": "مقبول"
    },
    {
        "English": "Overall Progress",
        "Arabic": "التقدم العام"
    },
    {
        "English": "Pending",
        "Arabic": "تحت المراجعة"
    },
    {
        "English": "View Forms",
        "Arabic": "عرض الاستمارات"
    },
    {
        "English": "View Docs",
        "Arabic": "عرض الوثائق"
    },
    {
        "English": "View Admins",
        "Arabic": "عرض المستخدمين"
    },
    {
        "English": "View",
        "Arabic": "عرض"
    },
    {
        "English": "Complete",
        "Arabic": "مكتمل"
    },
    {
        "English": "Incomplete",
        "Arabic": "غير مكتمل"
    },
    {
        "English": "N/A",
        "Arabic": "لا يوجد"
    },
    {
        "English": "Sort",
        "Arabic": "الترتيب"
    },
    {
        "English": "A-Z",
        "Arabic": "أ-ي"
    },
    {
        "English": "Z-A",
        "Arabic": "ي-أ"
    },
    {
        "English": "Incomplete Forms",
        "Arabic": "استمارات غير مكتملة"
    },
    {
        "English": "Incomplete Capital Calls",
        "Arabic": "مطالبات التزامية غير منفذة"
    },
    {
        "English": "High-Low",
        "Arabic": "اعلى-ادنى"
    },
    {
        "English": "Low-High",
        "Arabic": "ادنى-اعلى"
    },
    {
        "English": "No Capital Calls Yet",
        "Arabic": "لا يوجد مطالبات التزامية"
    },
    {
        "English": "No Distributions Yet",
        "Arabic": "لا يوجد توزيعات"
    },
    {
        "English": "Make Capital Call",
        "Arabic": "إنشاء مكالمة رأس المال"
    },
    {
        "English": "Total Received",
        "Arabic": "الإجمالي المستلم"
    },
    {
        "English": "Total Capital Received",
        "Arabic": "إجمالي رأس المال المستلم"
    },
    {
        "English": "Total Capital Called",
        "Arabic": "إجمالي رأس المال يسمى"
    },
    {
        "English": "Num. of Completions",
        "Arabic": "عدد الإكمالات"
    },
    {
        "English": "Deadline",
        "Arabic": "الموعد النهائي"
    },
    {
        "English": "Delete",
        "Arabic": "حذف"
    },
    {
        "English": "View Clients",
        "Arabic": "عرض العملاء"
    },
    {
        "English": "View Emails",
        "Arabic": "عرض رسائل البريد الإلكتروني"
    },
    {
        "English": "Issue Capital Call",
        "Arabic": "انشاء مطالبة التزام"
    },
    {
        "English": "Title",
        "Arabic": "عنوان"
    },
    {
        "English": "What % of Total Committed Funds",
        "Arabic": "النسبة المئوية من اجمالي الالتزامات"
    },
    {
        "English": "Message",
        "Arabic": "رسالة"
    },
    {
        "English": "Next",
        "Arabic": "التالي"
    },
    {
        "English": "Close",
        "Arabic": "إغلاق"
    },
    {
        "English": "Back",
        "Arabic": "الرجوع"
    },
    {
        "English": "Subscribe Now",
        "Arabic": "اشترك الان"
    },
    {
        "English": "Subscribe to Fund",
        "Arabic": "الاشتراك في الصندوق"
    },
    {
        "English": "Subscribe",
        "Arabic": "اشترك"
    },
    {
        "English": "Submit",
        "Arabic": "إرسال"
    },
    {
        "English": "No Docs Yet",
        "Arabic": "لا يوجد وثائق"
    },
    {
        "English": "File name",
        "Arabic": "اسم الملف"
    },
    {
        "English": "Date Created",
        "Arabic": "تاريخ الإنشاء"
    },
    {
        "English": "Actions",
        "Arabic": "الإجراءات"
    },
    {
        "English": "Action Items",
        "Arabic": "بنود الإجراء"
    },
    {
        "English": "Upload",
        "Arabic": "ارفق"
    },
    {
        "English": "Upload File",
        "Arabic": "ارفاق ملف"
    },
    {
        "English": "Ready to Upload",
        "Arabic": "جاهز للتحميل"
    },
    {
        "English": "Publish",
        "Arabic": "انشر"
    },
    {
        "English": "Public",
        "Arabic": "عام"
    },
    {
        "English": "Private",
        "Arabic": "خاص"
    },
    {
        "English": "Drop files here to upload",
        "Arabic": "قم بإسقاط الملفات هنا لارفاقها"
    },
    {
        "English": "Browse Files",
        "Arabic": "تصفح الملفات"
    },
    {
        "English": "Create Distribution",
        "Arabic": "إنشاء توزيع"
    },
    {
        "English": "Date",
        "Arabic": "تاريخ"
    },
    {
        "English": "Amount Distributed",
        "Arabic": "المبلغ الموزع"
    },
    {
        "English": "Distribution",
        "Arabic": "توزيع"
    },
    {
        "English": "Click Here to Complete Form",
        "Arabic": "انقر هنا لإكمال الاستمارة"
    },
    {
        "English": "Review",
        "Arabic": "مراجعة"
    },
    {
        "English": "Approval Pending",
        "Arabic": "قيد الموافقة"
    },
    {
        "English": "Back to Dashboard",
        "Arabic": "العودة إلى لوحة التحكم"
    },
    {
        "English": "Take me to Missing Field",
        "Arabic": "وجهني إلى الحقل المفقود"
    },
    {
        "English": "Upload a screenshot or pdf of your wire transfer to IMPACT46",
        "Arabic": "ارفق صورة من الحوالة البنكية"
    },
    {
        "English": "Upload Document",
        "Arabic": "ارفاق  وثيقة"
    },
    {
        "English": "Upload Documents",
        "Arabic": "تحميل المستندات"
    },
    {
        "English": "Amount to Pay",
        "Arabic": "المبلغ المطلوب تحويله"
    },
    {
        "English": "Please Upload Payment",
        "Arabic": "يرجى تحميل الدفع"
    },
    {
        "English": "Successfully sent payment",
        "Arabic": "تم إرسال الدفعة بنجاح"
    },
    {
        "English": "Please wait for IMPACT46 to approve your payment",
        "Arabic": "يرجى الانتظار حتى IMPACT46 للموافقة على الدفع الخاص بك"
    },
    {
        "English": "Reupload",
        "Arabic": "إعادة تحميل"
    },
    {
        "English": "Paid",
        "Arabic": "مدفوع"
    },
    {
        "English": "Amount Paid",
        "Arabic": "المبلغ المدفوع"
    },
    {
        "English": "Paid On",
        "Arabic": "تاريخ الدفع"
    },
    {
        "English": "Pay Now",
        "Arabic": "سدد الآن"
    },
    {
        "English": "Percent Called",
        "Arabic": "نسبة المطالبة"
    },
    {
        "English": "Home",
        "Arabic": "الصفحة الرئيسية"
    },
    {
        "English": "Download Account Statement",
        "Arabic": "تحميل كشف الحساب"
    },
    {
        "English": "Download Report",
        "Arabic": "تحميل التقرير"
    },
    {
        "English": "Downloading report",
        "Arabic": "تحميل التقرير"
    },
    {
        "English": "Quarterly Report",
        "Arabic": "تقرير فصلي"
    },
    {
        "English": "Weight",
        "Arabic": "وزن"
    },
    {
        "English": "Number of Units",
        "Arabic": "عدد الوحدات"
    },
    {
        "English": "Called to Date",
        "Arabic": "مطلوب حتى الآن"
    },
    {
        "English": "No reports have been created",
        "Arabic": "لا يوجد تقارير"
    },
    {
        "English": "Please check your email for a one-time password. It might be in your junk/spam. If it has not arrived after 2 minutes,",
        "Arabic": "يرجى التحقق من بريدك الإلكتروني للحصول على كلمة مرور لمرة واحدة. إذا لم يصل بعد دقيقتين،"
    },
    {
        "English": "Please resend code",
        "Arabic": "إعادة إرسال الرمزيرجى"
    },
    {
        "English": "Sign In",
        "Arabic": "تسجيل الدخول"
    },
    {
        "English": "Powered by Jeffery",
        "Arabic": "Powered by Jeffery"
    },
    {
        "English": "Notifications",
        "Arabic": "الإشعارات"
    },
    {
        "English": "To Dos",
        "Arabic": "قائمة المهام"
    },
    {
        "English": "Terms & Conditions",
        "Arabic": "الشروط والأحكام"
    },
    {
        "English": "Missing Documents",
        "Arabic": "وثائق غير مكتملة"
    },
    {
        "English": "Selection of documents required by the Capital Markets Authority (CMA) in order to conduct business with IMPACT46",
        "Arabic": "اختيار الوثائق المطلوبة من قبل هيئة السوق المالية (CMA) للقيام بالأعمال مع IMPACT46"
    },
    {
        "English": "Commercial Registration",
        "Arabic": "السجل التجاري"
    },
    {
        "English": "Required",
        "Arabic": "الزامي"
    },
    {
        "English": "Switch",
        "Arabic": "تغير"
    },
    {
        "English": "Articles of Association",
        "Arabic": "نظام أساس الشركة"
    },
    {
        "English": "Board Decision to Open Account",
        "Arabic": "قرار مجلس الإدارة لفتح حساب"
    },
    {
        "English": "Optional",
        "Arabic": "اختياري"
    },
    {
        "English": "Expiration Date",
        "Arabic": "تاريخ الانتهاء"
    },
    {
        "English": "Click here to upload",
        "Arabic": "انقر هنا لارفاق ملف"
    },
    {
        "English": "Click here to accept our Terms & Conditions",
        "Arabic": "انقر هنا لقبول الشروط والأحكام"
    },
    {
        "English": "Awaiting Payment Confirmation",
        "Arabic": "قيد تأكيد السداد"
    },
    {
        "English": "Payment",
        "Arabic": "دفع مال"
    },
    {
        "English": "Enter your password",
        "Arabic": "أدخل كلمة المرور الخاصة بك"
    },
    {
        "English": "Enter your password that's made up of 6 digits",
        "Arabic": "أدخل كلمة المرور المكونة من 6 أرقام"
    },
    {
        "English": "User or Username",
        "Arabic": "مستخدم"
    },
    {
        "English": "Password",
        "Arabic": "كلمة المرور"
    },
    {
        "English": "Saudi ID",
        "Arabic": "هوية وطنية"
    },
    {
        "English": "National Address",
        "Arabic": "العنوان الوطني"
    },
    {
        "English": "Passport",
        "Arabic": "جواز السفر"
    },
    {
        "English": "Number",
        "Arabic": "رقم"
    },
    {
        "English": "Phone",
        "Arabic": "هاتف"
    },
    {
        "English": "Contact Us",
        "Arabic": "تواصل معنا"
    },
    {
        "English": "Relationship Manager",
        "Arabic": "مدير العلاقة"
    },
    {
        "English": "Compliance Officer",
        "Arabic": "منسوب ادارة الالتزام"
    },
    {
        "English": "Signature",
        "Arabic": "توقيع"
    },
    {
        "English": "Successfully downloaded report",
        "Arabic": "تم تنزيل التقرير بنجاح"
    },
    {
        "English": "Check your downloads folder",
        "Arabic": "تحقق من مجلد التنزيلات الخاص بك"
    },
    {
        "English": "Personal Information",
        "Arabic": "معلومات شخصية"
    },
    {
        "English": "Education & Employment",
        "Arabic": "التعليم والعمل"
    },
    {
        "English": "General Information",
        "Arabic": "معلومات عامة"
    },
    {
        "English": "Financial Information",
        "Arabic": "معلومات مالية"
    },
    {
        "English": "Customer Information",
        "Arabic": "معلومات العميل"
    },
    {
        "English": "Add Admin",
        "Arabic": "إضافة مشرف"
    },
    {
        "English": "Generating account statement",
        "Arabic": "إضافة مشرف"
    },
    {
        "English": "Successfully generated account statement",
        "Arabic": "إضافة مشرف"
    },
    {
        "English": "Email",
        "Arabic": "عنوان البريد الإلكتروني"
    },
    {
        "English": "Switch to English",
        "Arabic": "غيِّر اللغة إلى العربية"
    },
]