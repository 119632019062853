import React, { useEffect, useState } from 'react';
import { ClientApi } from '../api';

const useClients = (key, value, rerender=0) => {
    const [status, setStatus] = useState('loading')
    const [clients, setClients] = useState([])

    useEffect(() => {
        const getClients = async () => {
            if (!key || !value) return;
            setStatus('loading')

            try {
                const { data: _clients } = await ClientApi.find({ [key]: value, withProgress: true })
                setClients(_clients)
                setStatus('success')
            } catch (e) {
                setStatus('error')
            }

        }
        getClients()
    }, [key, value, rerender])

    return { status, clients }
}

export { useClients }