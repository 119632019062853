import { useEffect, useState } from "react"
import { useClient } from "./useClient"
import { useForms } from "./useForms"

const useDashboardIncomplete = (clientId, clientType, rerender=0) => {
    const { forms } = useForms({ client_id: clientId, include_reviews: true })
    // 'complete,completebutnotapproved,incomplete'
    const [kycStatus, setKycStatus] = useState('complete')
    const [kycId, setKycId] = useState('complete')
    const [kycProgress, setKycProgress] = useState(0)
    const [fatcaStatus, setFatcaStatus] = useState('complete')
    const [fatcaId, setFatcaId] = useState('complete')
    const [fatcaProgress, setFatcaProgress] = useState(0)
    const [amlStatus, setAmlStatus] = useState('complete')
    const [amlProgress, setAmlProgress] = useState(0)

    useEffect(() => {
        if (!clientId) return;
        if (forms.length === 0) return;

        const kyc = forms.find(f => f.type === 'kyc_' + clientType)
        const fatca = forms.find(f => f.type === 'fatca_' + clientType)
        let aml;
        if (clientType === 'corporation') aml = forms.find(f => f.type === 'aml')

        if (kyc.progress < 100) setKycStatus('incomplete')
        else if (kyc.progress >= 100 && !kyc?.review?.kyc_approved) setKycStatus('pending')
        else if (kyc.progress >= 100 && kyc?.review?.kyc_approved) setKycStatus('complete')

        if (fatca.progress < 100) setFatcaStatus('incomplete')
        else if (fatca.progress >= 100 && !fatca?.review?.fatca_approved) setFatcaStatus('pending')
        else if (fatca.progress >= 100 && fatca?.review?.fatca_approved) setFatcaStatus('complete')

        if (!aml || !aml.progress < 100) setAmlStatus(true)

        setKycProgress(kyc.progress)
        setFatcaProgress(fatca.progress)
        setAmlProgress(aml?.progress)

        setKycId(kyc._id)
        setFatcaId(fatca._id)

    }, [clientId, forms.length, rerender])

    return { kycStatus, fatcaStatus, amlStatus, kycId, fatcaId, kycProgress, fatcaProgress, amlProgress }
}

export { useDashboardIncomplete }