import React, { useEffect, useState } from 'react';
import { ClientApi } from '../api';

const useClient = (clientId) => {
    const [status, setStatus] = useState('loading')
    const [client, setClient] = useState([])

    useEffect(() => {
        const getClient = async() => {
            if(!clientId) return;
            setStatus('loading')

            try {
                const { data: _client } = await ClientApi.get(clientId)
                setClient(_client)
                setStatus('success')
            } catch(e) {
                setStatus('error')
            }

        }
        getClient()
    }, [clientId])

    return { status, client }
}

export { useClient }