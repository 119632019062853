import React, { useContext, useState } from "react";
import { FileUploader } from "baseui/file-uploader";
import { Button } from "../Button";
import { blue } from "../../data/styles";
import { css } from "@emotion/css";
import LanguageContext from "../../context/LanguageContext";
import { trans } from "../../translations";

const FileInput = ({ onSelect, value, showFilePreview = true, error }) => {
  const [progressAmount, startFakeProgress, stopFakeProgress] = useFakeProgress();
  const [showReupload, setShowReupload] = useState(value != null)
  const { language, isEnglish } = useContext(LanguageContext)

  return (
    <div className={css`
      position: relative;
      display: flex;
      flex-direction: column;
    `} style={error ? { border: "4px solid red" } : {}}>
      {/* {value == '' || !value && <></>} */}
      {(!value || showReupload) &&
        <FileUploader
          multiple={false}
          onCancel={stopFakeProgress}
          onDrop={(acceptedFiles, rejectedFiles) => {
            // handle file upload...
            startFakeProgress();
            onSelect(acceptedFiles[0]);
            setShowReupload(false)
          }}
          // progressAmount is a number from 0 - 100 which indicates the percent of file transfer completed
          progressAmount={progressAmount}
          progressMessage={progressAmount ? `Uploading... ${progressAmount}% of 100%` : ""}
        />
      }
      {(value != null || showReupload) &&
        <div 
        style={{position: 'absolute', fontFamily: 'Outfit', cursor: 'pointer', right: 4, bottom: 4, padding: 8, fontSize: '0.7rem', paddingLeft: 12, paddingRight: 12, color: 'white',  borderRadius: 16, backgroundColor: blue}}
        onClick={() => setShowReupload(true)}
        >
          {/* <Button text={'Reupload'} /> */}
          {trans('Reupload', language)}
        </div>
      }
      {value && showFilePreview && <img src={value} width='200px' alt={value?.originalname} />}
      {!showFilePreview && <div style={{ padding: 12, backgroundColor: 'rgb(240,240,240)', borderRadius: 8, marginTop: 16 }}><p style={{ color: 'black' }}>{trans((progressAmount != 0 && progressAmount != 100 ? 'Preparing ' : 'Ready ') + 'to Upload', language)} {value?.name}</p></div>}
    </div>
  );
};

function useFakeProgress() {
  const [fakeProgress, setFakeProgress] = React.useState(0);
  const [isActive, setIsActive] = React.useState(false);
  function stopFakeProgress() {
    setIsActive(false);
    setFakeProgress(0);
  }
  function startFakeProgress() {
    setIsActive(true);
  }
  useInterval(
    () => {
      if (fakeProgress >= 100) {
        stopFakeProgress();
      } else {
        setFakeProgress(fakeProgress + 10);
      }
    },
    isActive ? 200 : null
  );
  return [fakeProgress, startFakeProgress, stopFakeProgress];
}

function useInterval(callback, delay) {
  const savedCallback = React.useRef(() => { });
  // Remember the latest callback.
  React.useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);
  // Set up the interval.
  React.useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

export default FileInput;
