import React, { useEffect, useState } from 'react';
import { DistributionApi } from '../api';

const useDistributions = (fundId, query={}, rerender=0) => {
    const [status, setStatus] = useState('loading')
    const [distributions, setDistributions] = useState([])

    useEffect(() => {
        const getDistributions = async() => {
            if(!fundId) return;
            setStatus('loading')

            try {
                const { data: _distributions } = await DistributionApi.find(fundId, query)
                setDistributions(_distributions)
                setStatus('success')
            } catch(e) {
                setStatus('error')
            }

        }
        getDistributions()
    }, [fundId, rerender])

    return { status, distributions }
}

export { useDistributions }