import { css } from "@emotion/css"
import { LoadingSpinner } from "baseui/button/styled-components"
import { blue } from "../data/styles"
import { Success } from "../svg"

const Button = ({ text, onClick, loading, disabled = false, type='primary', ...rest }) => {
	return (
		<button
			className={css` 
								width: 100%;
			        				font-optical-sizing: auto;
								    font-family: "Outfit";
								    font-style: normal;
								    font-stretch: normal;
								    line-height: initial;
								    font-size: 15px;
									display: flex;
									flex-direction: row;
								    &:hover{
					                  cursor: pointer;
					                }
					                &:disabled{
					                	pointer-events: none;
					                	opacity: 0.5;
					                }
									&:active {
										opacity: 0.7;
									}
			        			`}
			style={{ backgroundColor: type === 'primary' ? blue : 'rgb(250,250,250)', border: type === 'primary' ? "none" : `2px solid ${blue}`, color: type === 'primary' ? "#fff" : blue , padding: "12px 36px", borderRadius: 4, ...rest }}
			onClick={onClick}
			disabled={disabled || loading}
		>
			{text}
			{rest?.element}
			{/* {!loading && text} */}
			{loading && <div style={{paddingLeft: 16, display: 'inline'}}><LoadingSpinner /></div>}
		</button>
	)
}

export { Button }