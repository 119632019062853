import React, { Component } from "react"
import PropTypes from "prop-types"
import { css } from '@emotion/css'
import { label, inputOverrides, selectOverrides, dateOverrides, phoneOverrides } from "../../data/styles"

// import json from "../fields/inv_acc_ope_for.json";
// import "tailwindcss/tailwind.css"
import { Input, MaskedInput } from "baseui/input"
import { PhoneInput, COUNTRIES } from "baseui/phone-input"
// import Select from "../../elements/inputs/Select"
import Select from "../../elements/Select"
import DatePicker from "../../elements/inputs/DatePicker"
import RadioButton from "../../elements/inputs/RadioButton"
import FileInput from "../../elements/inputs/FileInput"
import List from "../../elements/inputs/List"
import { CountryActivityTable, PoliticalPersonsTable, ManagerExecutiveTable, OwnershipStructureTable, UltimateOwnerTable, AuthorizedSignatoriesTable, DirectorsTable, AmericanShareholderTable, TaxResidenceTable, FinancialDealingTable, ConsignmentCertificates, ExpectedFinancialTransactions, IdentificationCriteria } from "../../elements/Tables"
import { upload } from "../../api/aws"
import CurrencyInput from "react-currency-input-field"
import { isNullOrEmpty } from "../../util"
import OldSelect from "../../elements/OldSelect"
import { trigger } from "../../events/events"
import { withLanguageContext } from "../../context/LanguageProvider"
import { Checkbox } from "baseui/checkbox"
import { IndividualAcknowledgement } from "../../elements/IndividualAcknowledgement"
import { RiskScoreAcknowledgement } from "../../elements/RiskScoreAcknowledgement"
import { DocApi } from "../../api"

const isMobile = window.innerWidth < 960

class SubSection extends Component {
  static propTypes = {
    subsection: PropTypes.objectOf({
      title: PropTypes.string,
      fields: PropTypes.array,
    }),
  }

  constructor(props) {
    super(props)

    this.state = {
      //   index: 0,
      isIncomplete: false,
    }
  }

  componentDidMount() { }

  componentDidUpdate(prevProps, prevState) { }

  isEnglish = () => {
    return this.props.languageContext.isEnglish()
  }

  onUploadFile = async (fieldId, file, type = 'id') => {
    const { client } = this.props

    try {
      const formData = new FormData()
      formData.append("file", file)
      formData.append("type", type)
      formData.append("clientId", client._id)

      // COMBAK removed bc we didnt have AWS in at the time
      debugger
      const { data: doc } = await DocApi.create(formData)
      // const data = await upload(formData)

      this.props.onInputField(fieldId, doc?.url)
    } catch (e) {
      console.log(e)
    }
  }

  getInputType = (field) => {
    if (!field) return <></>
    const { onInputField, hasIncompleteFields } = this.props

    const fieldTitle = () => {
      if (!field) return '';

      if (this.isEnglish()) return field.title;
      if (field.title_arabic) return field.title_arabic

      return field.title
    }

    const fieldOptions = () => {
      if (!field || !field?.options) return '';

      if (this.isEnglish()) return field.options;
      if (field.options_arabic) return field.options_arabic
    }

    const inputOverride = {
      InputContainer: {
        style: ({ $theme }) => ({
          direction: this.isEnglish() ? 'ltr' : 'rtl'
        })
      }
    }

    switch (field.type) {
      case "text":
        return (
          <Input
            key={field.id}
            placeholder={fieldTitle()}
            value={field.value}
            onChange={(e) => onInputField(field.id, e.target.value)}
            error={hasIncompleteFields && isNullOrEmpty(field.value)}
            onBlur={() => trigger('form:save')}
            overrides={inputOverride}
          // overrides={inputOverrides}
          />
        )
      case "email":
        return (
          <Input
            key={field.id}
            placeholder={fieldTitle()}
            value={field.value}
            onChange={(e) => onInputField(field.id, e.target.value)}
            error={hasIncompleteFields && isNullOrEmpty(field.value)}
            onBlur={() => trigger('form:save')}
            overrides={inputOverride}
          // overrides={inputOverrides}
          />
        )

      case "percentage":
        return (
          <Input
            key={field.id}
            placeholder={fieldTitle()}
            value={field.value}
            type="number"
            maxLength={3}
            onChange={(e) => onInputField(field.id, e.target.value)}
            endEnhancer="%"
            error={hasIncompleteFields && isNullOrEmpty(field.value)}
            onBlur={() => trigger('form:save')}
            overrides={inputOverride}
          // overrides={inputOverrides}
          />
        )

      case "number":
        return (
          <Input
            type="number"
            key={field.id}
            placeholder={fieldTitle()}
            value={field.value}
            onChange={(e) => onInputField(field.id, e.target.value)}
            error={hasIncompleteFields && isNullOrEmpty(field.value)}
            onBlur={() => trigger('form:save')}
            overrides={inputOverride}
          // overrides={inputOverrides}

          />
        )

      case "zip":
        return (
          <Input
            type="number"
            key={field.id}
            placeholder={fieldTitle()}
            value={field.value}
            maxLength={5}
            onChange={(e) => onInputField(field.id, e.target.value)}
            error={hasIncompleteFields && isNullOrEmpty(field.value)}
            onBlur={() => trigger('form:save')}
            overrides={inputOverride}
          // overrides={inputOverrides}
          />
        )

      case "phone":
        return (
          <PhoneInput
            country={COUNTRIES[field.country]}
            key={field.id}
            text={field.value}
            onTextChange={(e) => onInputField(field.id, e.target.value)}
            onCountryChange={({ option }) => onInputField(field.id, option.id, "phone")}
            error={hasIncompleteFields && isNullOrEmpty(field.value)}
            onBlur={() => trigger('form:save')}
            overrides={inputOverride}
          // overrides={phoneOverrides}
          />
        )

      case "iban":
        return (
          <MaskedInput
            type="text"
            key={field.id}
            placeholder={fieldTitle()}
            value={field.value}
            onChange={(e) => onInputField(field.id, e.target.value)}
            mask="SA99 9999 9999 9999 9999 9999"
            error={hasIncompleteFields && isNullOrEmpty(field.value)}
            onBlur={() => trigger('form:save')}
            overrides={inputOverride}
          // overrides={inputOverrides}
          />
        )

      case "money":
        return (
          <div className={css`padding-right: 12px; display: flex;`}>
            <CurrencyInput
              style={{ height: 48, paddingLeft: 12, fontSize: 16, boxShadow: "none", width: "100%", backgroundColor: "rgb(238,238,238)", borderColor: hasIncompleteFields && isNullOrEmpty(field.value) ? 'red' : 'none' }}
              placeholder="SAR 1,000,000"
              prefix="SAR "
              decimalsLimit={0}
              value={field.value}
              onValueChange={(value) => onInputField(field.id, value)}
              onBlur={() => trigger('form:save')}
            // overrides={inputOverrides}
            />
          </div>
        )

      case "dropdown":
        return (
          <OldSelect
            data={field}
            key={field.id + field.value}
            multi={field.multi}
            value={field.value}
            placeholder={fieldTitle()}
            options={fieldOptions()}
            onSelect={(value) => onInputField(field.id, value)}
            update={onInputField}
            error={hasIncompleteFields && isNullOrEmpty(field.value)}
            onBlur={() => trigger('form:save')}
          />

        )
      // return <Select key={field.id} multi={field.multi} value={field.value} placeholder={field.id} options={field.options} onSelect={(value) => (field.value = value)} />;
      case "radio":
        return <RadioButton key={field.id + field.value} value={field.value} options={field.options} optionsArabic={field.options_arabic || field.options} onSelect={(value) => onInputField(field.id, value)} isEnglish={this.isEnglish()} />
      case "date":
        let date = new Date()
        let maxDate
        let minDate
        if (field.cantBe == "after") {
          maxDate = new Date()
        }
        if (field.cantBe == "before") {
          minDate = new Date()
        }

        if (Date.parse(field.value)) {
          date = new Date(field.value)
        }

        return <DatePicker
          key={field.id}
          value={date}
          error={hasIncompleteFields && isNullOrEmpty(field.value)}
          onSelect={(value) => onInputField(field.id, value)} minDate={minDate} maxDate={maxDate}
          onBlur={() => trigger('form:save')}
        />
      case "signature":
        return (
          <input
            key={field.id}
            value={field.value}
            onChange={(e) => onInputField(field.id, e.target.value)}
            placeholder="Signature"
            style={{ fontWeight: "bold", width: "80%", height: 64, backgroundColor: "rgb(240,240,240)", fontFamily: "cursive", fontSize: "1.5rem", paddingLeft: 12, boxShadow: "none" }}
            error={hasIncompleteFields && isNullOrEmpty(field.value)}
          />
        ) //<Input $style={{fontFamily: 'cursive', color:'blue'}} placeholder={field.id} value={field.value} onChange={(e) => onInputField(field.id, e.target.value)} />;

      case "check":
        return (
          <Checkbox
            key={field.id}
            checked={field.value}
            onChange={e => onInputField(field.id, e.target.checked)}
          />
        )

      case "uploadimage":
        return <FileInput error={hasIncompleteFields && isNullOrEmpty(field.value)} key={field.id} onSelect={(file) => this.onUploadFile(field.id, file)} value={field.value} />

      case "uploadfile":
        return <FileInput error={hasIncompleteFields && isNullOrEmpty(field.value)} key={field.id} onSelect={(file) => this.onUploadFile(field.id, file, 'qfi_proof')} value={field.value} showFilePreview={false} />

      case "list":
        return <List key={field.id} value={field.value} onChange={(value) => onInputField(field.id, value)} />

      case "table_countryactivity":
        // GOOD
        return <CountryActivityTable key={field.id} value={field.value} onChange={(value) => onInputField(field.id, value)} />

      case "table_politicalpersons":
        // GOOD
        return <PoliticalPersonsTable key={field.id} value={field.value} onChange={(value) => onInputField(field.id, value)} />

      case "table_infoManagersExecs":
        // GOOD
        return <ManagerExecutiveTable key={field.id} value={field.value} onChange={(value) => {
          console.log('VALUE HERE', field.id, value)
          onInputField(field.id, value)
        }} />

      case "table_ownershipstruct":
        // GOOD
        return <OwnershipStructureTable key={field.id} value={field.value} onChange={(value) => onInputField(field.id, value)} />

      case "table_ultowner":
        // GOOD
        return <UltimateOwnerTable key={field.id} value={field.value} onChange={(value) => onInputField(field.id, value)} />

      case "table_authsigs":
        // GOOD
        return <AuthorizedSignatoriesTable key={field.id} value={field.value} onChange={(value) => onInputField(field.id, value)} />

      case "table_directors":
        // GOOD
        return <DirectorsTable key={field.id} value={field.value} clientId={this.props.client._id} onChange={(value) => onInputField(field.id, value)} onBlur={() => trigger('form:save')} />

      case "table_taxresidence":
        return <TaxResidenceTable key={field.id} value={field.value} clientId={this.props.client._id} onChange={(value) => onInputField(field.id, value)} />

      case "table_shareholders":
        return <AmericanShareholderTable key={field.id} value={field.value} clientId={this.props.client._id} onChange={(value) => onInputField(field.id, value)} />

      case "table_findealings":
        return <FinancialDealingTable key={field.id} value={field.value} clientId={this.props.client._id} onChange={(value) => onInputField(field.id, value)} />

      case "table_consigncerts":
        return <ConsignmentCertificates key={field.id} value={field.value} clientId={this.props.client._id} onChange={(value) => onInputField(field.id, value)} />

      case "table_expfintxs":
        return <ExpectedFinancialTransactions key={field.id} value={field.value} clientId={this.props.client._id} onChange={(value) => onInputField(field.id, value)} />

      case "table_idcrit":
        return <IdentificationCriteria key={field.id} value={field.value} clientId={this.props.client._id} onChange={(value) => onInputField(field.id, value)} />

      case "indAcknowledgeDeclaration":
        return <IndividualAcknowledgement />

      case "riskscore":
        return <RiskScoreAcknowledgement field={field} onChange={(value) => onInputField(field.id, value)} form={this.props.__form} />

      default:
        return
        <div>yo</div>
    }
  }

  render() {
    const { subsection, client, canFieldBeRendered } = this.props
    return (
      <div className={css`
        display: flex;
        flex-direction: column;
        justify-content: stretch;
        align-items: stretch;
        // direction: ${this.isEnglish() ? 'ltr' : 'rtl'};
      `}>
        <h1 style={{ fontFamily: "Outfit" }}>{subsection?.title}</h1>

        {subsection.fields.map((field, i) => {
          if (client?.type === "individual" && field.only === "corp") return <></>
          if (field.conditionRef) {
            const canBeRendered = canFieldBeRendered(field.conditionRef, field.condition)
            if (!canBeRendered) return <></>
          }

          if (!field.group) {
            return (
              <div style={{ marginBottom: 16, clear: "both" }} key={i}>
                <p className={label} key={field.id + 'label-' + this.isEnglish()}>
                  {this.isEnglish() ? field.title : field.title_arabic || field.title}
                </p>
                {/* <Input placeholder={field.title} /> */}
                {this.getInputType(field)}
              </div>
            )
          }

          // catch the second one in the group
          if (subsection.fields[i - 1]?.group == field.group) return
          // This line works if the else if is on
          // if (field.group && field.group == subsection.fields[i + 1].group) {
          if (field.group) {
            return (
              <div className={css`
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: stretch;
                margin-bottom: 4px;
                padding: 2px;
                flex: 1;
              `} >
                <div className={css`
                  display: flex;
                  flex-direction: column;
                  flex: 1;
                  margin-right: 8px;
                `} style={{ marginBottom: 16, clear: "both" }}>
                  <p className={label}>
                    {/* {field.title} */}
                    {this.isEnglish() ? field.title : field.title_arabic || field.title}
                  </p>
                  {this.getInputType(field)}
                </div>

                {subsection.fields[i + 1]?.group == field.group && canFieldBeRendered(subsection.fields[i + 1]?.conditionRef, subsection.fields[i + 1]?.condition) &&
                  <div className={css`
                  display: flex;
                  flex-direction: column;
                  flex: 1;
                  margin-left: 4px;
                  margin-right: 4px;
                `} style={{ marginBottom: 16, clear: "both" }}>
                    <p className={label}>
                      {/* {subsection.fields[i + 1]?.title} */}
                      {this.isEnglish() ? subsection.fields[i + 1]?.title : subsection.fields[i + 1]?.title_arabic || subsection.fields[i + 1]?.title}
                    </p>
                    {this.getInputType(subsection.fields[i + 1])}
                  </div>
                }
              </div>
            )
          }
          // } else if (field.group && subsection.fields[i + 1].group != field.group) {
          //   return (
          //     <div style={{ marginBottom: 16, clear: "both" }} key={i}>
          //       <p className={label} key={field.id + 'label-' + this.isEnglish()}>
          //         {this.isEnglish() ? field.title : field.title_arabic || field.title}
          //       </p>
          //       {/* <Input placeholder={field.title} /> */}
          //       {this.getInputType(field)}
          //     </div>
          //   )
          // }
          return <></>
        })}
      </div>
    )
  }
}

export default withLanguageContext(SubSection)
