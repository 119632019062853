import React, { useEffect, useState } from 'react';
import { NotificationApi } from '../api';

const useNotifications = (clientId) => {
    const [status, setStatus] = useState('loading')
    const [notifications, setNotifications] = useState([])

    useEffect(() => {
        const getNotifications = async() => {
            if(!clientId) return;
            setStatus('loading')

            try {
                const { data: _notifications } = await NotificationApi.getAll(clientId)
                console.log(_notifications)
                setNotifications(_notifications)
                setStatus('success')
            } catch(e) {
                setStatus('error')
            }

        }
        getNotifications()
    }, [clientId])

    return { status, notifications }
}

export { useNotifications }