import React, { Component } from "react"
import { withRouter } from "react-router-dom"
import { css } from '@emotion/css'
import { withContext } from "../../context/AuthProvider"
// import { find, update } from "../api/form"
// import { get } from "../api/client"
import Section from "./Section"
import SideNavForm from "./SideNavForm"
import { on } from "../../events/events"
import { Saudi, UK } from "../../data/flags"
// import Loader from "react-loader-spinner"
// import * as Theme from "../util/theme"
import { getFieldIndexes, getNextMissingField } from "../../util"
import { grey1, blue, button } from "../../data/styles"

import FORM from './kyc';
import { ClientApi, FormApi } from "../../api"
import Success from "../../snowflakes/Success"
import { Spinner } from "baseui/icon"
import { withLanguageContext } from "../../context/LanguageProvider"
import Toggle from "../../elements/Toggle"
// import { withContext } from "../context/Auth"

class SectionManager extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      isFinished: false,
      hotFormId: "",
      sectionIndex: 0,
      subsectionIndex: 0,
      forms: [],
      form: null,
      client: null,
      isLanguageOpen: false
    }
  }

  async componentDidMount() {
    console.log(this.props.context)
    await this.getForm()
    // await this.getClient()


    on("form:save", async () => await this.saveForm())
    on("form:onNextSubsection", async () => this.onNextSubsection())
    on("form:onPrevSubsection", async () => this.onPrevSubsection())
    // on('form:nextSection', () => this.set)
  }

  async getClient(clientId) {
    if (!clientId) return;

    try {
      const { data: client } = await ClientApi.get(clientId)
      this.setState({ client })
    } catch (e) {
      console.log('couldnt get client', e)
    }
  }

  getHotForm = () => {
    const { hotFormId, forms, form } = this.state
    // const form = forms.find((f) => f._id == hotFormId)

    return form
  }

  async getForm() {
    const { formId } = this.props;

    try {
      const { data: form } = await FormApi.get(formId)

      await this.getClient(form.client_id)

      await this.setState({ forms: [form], form, hotFormId: form?._id, isFinished: false })
    } catch (e) {
      alert('GET FORM' + e)
    }
  }

  onPrevSection = async () => {
    const { sectionIndex } = this.state

    await this.saveForm()

    if (sectionIndex === 0) return

    this.setState({ sectionIndex: sectionIndex - 1 })
  }

  onNextSection = async () => {
    const { sectionIndex } = this.state
    const form = this.getHotForm()
    this.setState({ loading: true })
    await this.saveForm()

    if (sectionIndex === form.data.sections.length - 1) {
      return this.setState({ isFinished: true, loading: false }, () => {
        this.props.history.push('/forms')
      })
    }

    this.setState({ sectionIndex: sectionIndex + 1, subsectionIndex: 0, isFinished: false, loading: false })
  }

  onPrevSubsection = async () => {
    if (this.state.subsectionIndex === 0) {
      this.onPrevSection()
      return this.setState({ subsectionIndex: 0 })
    }

    // trigger('form:save')
    this.setState({ subsectionIndex: this.state.subsectionIndex - 1 })
  }

  onNextSubsection = async () => {
    const { sectionIndex } = this.state
    const form = this.getHotForm()
    this.setState({ loading: true })
    await this.saveForm()

    if (this.state.subsectionIndex >= form.data.sections[sectionIndex].subsections.length - 1) {
      return await this.onNextSection()
      // return this.setState({ subsectionIndex: 0 })
    }

    // trigger('form:save');
    this.setState({ subsectionIndex: this.state.subsectionIndex + 1, loading: false })
  }

  onSelectNavItem = (index) => {
    this.setState({ sectionIndex: index, subsectionIndex: 0 })
  }

  onInputField = async (section) => {
    const { hotFormId, forms, sectionIndex } = this.state
    if (forms.length === 0) return;
    const formIndex = forms.findIndex((f) => f._id == hotFormId)

    if (formIndex === -1) return alert("No form index")

    forms[formIndex].data.sections[sectionIndex] = section

    await this.setState({ forms: forms, form: forms[formIndex], wasChangeMade: true })
  }

  saveForm = async () => {
    const { form } = this.state
    if (this.props.context.user.email.includes("compliance")) {
      return
    }

    try {
      await FormApi.update(form._id, form)
      await this.setState({ loading: false })
    } catch (e) {
      console.log(e)
    } finally {
      await this.setState({ loading: false })
    }
  }

  canFieldBeRendered = (id, valueToMatch) => {
    const form = this.getHotForm()
    const indexes = getFieldIndexes(id, form)
    if (!indexes) return true
    if (!valueToMatch) return false

    const valueToCheck = form.data.sections[indexes[0]].subsections[indexes[1]].fields[indexes[2]].value

    // if fields match, it CANNOT be rendered
    return valueToCheck == valueToMatch
  }

  onNextMissingField = async () => {
    const { sectionIndex, subsectionIndex, form } = this.state;
    const missingIndex = getNextMissingField(sectionIndex, subsectionIndex, form)
    await this.saveForm()

    if (!missingIndex) return this.setState({ isFinished: true })

    this.setState({ sectionIndex: missingIndex[0], subsectionIndex: missingIndex[1] })
  }

  handleToDashboard = async () => {
    await this.saveForm()
    this.props.history.push('/forms')
  }

  render() {
    const { hotFormId, sectionIndex, subsectionIndex, forms, client, loading, isFinished, isLanguageOpen } = this.state
    // const { client } = this.props.context;
    let form = forms.find((f) => f._id == hotFormId)

    const isEnglish = this.props.languageContext?.isEnglish
    const language = this.props.languageContext.language
    
    return (
      <div className={css`
        direction: ${isEnglish() ? 'ltr' : 'rtl'};
      `}>
        <div className={css`
              position: absolute;
              top: 32px;
              right: ${isEnglish() ? '32px' : null};
              left: ${isEnglish() ? null : '32px'};
            `} onClick={() => this.setState({ isLanguageOpen: !isLanguageOpen})}>
          {/* <div onClick={() => setLanguageIsVisible(!languageSelectIsVisible)}> */}
          <div style={{ cursor: 'pointer' }}>
            <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 24 24" fill="none" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-globe"><circle cx="12" cy="12" r="10"></circle><line x1="2" y1="12" x2="22" y2="12"></line><path d="M12 2a15.3 15.3 0 0 1 4 10 15.3 15.3 0 0 1-4 10 15.3 15.3 0 0 1-4-10 15.3 15.3 0 0 1 4-10z"></path></svg>
          </div>
        </div>
        {(this.state.isLanguageOpen) ?
          <>
            <div style={{ position: "absolute", top: 32, height: "auto", width: "auto", backgroundColor: "#fff", right: isEnglish() ? 0 : null, left: isEnglish() ? null : 0, boxShadow: "2px 4px 7px rgb(0 0 0 / 10%)", border: "1px solid #DFDFDF", borderRadius: 8, zIndex: 2, cursor: 'pointer' }}>
              <div style={{ display: "flex", justifyContent: "space-between", padding: "8px 24px", alignItems: "center", direction: 'ltr' }}>
                <span>{Saudi}</span>
                <div style={{ padding: 8 }}>
                  <div className="clearfix relative" style={{ width: 54, padding: 8, height: 24 }}>
                    <Toggle
                      update={(val) => this.props.languageContext.setLanguage(val === true ? 'en' : 'ar')}
                      defaultValue={language == 'en'}
                    />
                  </div>
                </div>
                <span>{UK}</span>
              </div>
            </div>

            <div
              onClick={() => this.setState({ isLanguageOpen: !isLanguageOpen })}
              style={{ position: "fixed", height: "100vh", width: "100vw", backgroundColor: "rgba(0,0,0,0)", top: 0, left: 0 }}></div>
          </>
          : ""}
        {form && !isFinished &&
          <SideNavForm
            onBack={this.handleToDashboard}
            backTo={"/forms"}
            title={"Individual KYC"}
            subtitle={client?.name}
            formId={form?._id}
            activeIndex={sectionIndex}
            sections={form?.data?.sections}
            onSelect={this.onSelectNavItem}
          />
        }
        {/* {form && <SideNavForm onBack={() => (window.location.pathname = "/dashboard")} backTo={'/dashboard/' + clientId} title={"Individual KYC"} subtitle={client?.name} formId={form?._id} activeIndex={sectionIndex} sections={form?.data?.sections} onSelect={this.onSelectNavItem} />} */}

        <div class="flex flex-1 flex-col">
          {/* <FormProgressBar forms={forms} hotFormId={hotFormId} onSelect={(id) => this.setState({ hotFormId: id })} /> */}

          <div class="container pt-32 xs:p-0 md:pl-12 min-h-screen" >
            {form && !isFinished && (
              <Section
                client={client}
                form={form}
                section={form?.data?.sections[sectionIndex]}
                subsectionIndex={subsectionIndex}
                onInputField={this.onInputField}
                onNextSection={this.onNextSection}
                onPrevSection={this.onPrevSection}
                canFieldBeRendered={this.canFieldBeRendered}
                onNextMissingField={this.onNextMissingField}
              />
            )}
            {form && isFinished &&
              <div className={css`
                display: flex;
                margin-top: 20vh;
                flex-direction: column;
                align-items: center;
                flex: 1;
                line-height: 2em;
                height: 40vh;
              `}>
                <Success />
                <p className={css`
                margin: 64px;
                  margin-top: 64px;
                  font-size: 1.2rem;
                  font-family: "Outfit";
                  font-weight: 800;
                  margin-bottom: 32px;
                `}>{`You forms are complete! Sit back and we'll take care of the rest. We will be in touch soon. Thank you, ${client.name}!`}</p>
                <button className={button} onClick={this.handleToDashboard}>Back to Dashboard</button>
              </div>
            }

            {/* {isFinished && (
              <div className="flex flex-initial flex-row p-16 justify-start">
                <div className="flex card p-8">
                  <p className="text-2xl font-bold">KYC complete</p>
                </div>

                <div
                  onClick={() => {
                    window.location = "/dashboard/" + clientId
                  }}
                  class="card flex flex-1 ml-4"
                  style={{ backgroundColor: 'green', cursor: "pointer" }}
                >
                  <button
                    onClick={() => {
                      window.location = "/dashboard/" + clientId
                    }}
                    class="center center-vertical font-bold text-2xl"
                    style={{ color: "white" }}
                  >
                    To Dashboard
                  </button>
                </div>
              </div>
            )} */}
          </div>
        </div>

        {loading && (
          // <div className="flex items-center justify-center" style={{ backgroundColor: "rgba(255,255,255,0.5)", position: "fixed", width: "100%", height: "100%" }}>
          <div className={css`
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
          `} style={{ backgroundColor: "rgba(255,255,255,0.5)", position: "fixed", top: 0, bottom: 0, left: 0, right: 0 }}>
            {/* <Loader color="black" /> */}
            <Spinner color='blue' size={100} />
            <p>This could take a few seconds to save</p>
          </div>
        )}
      </div>
    )
  }
}

// export default withContext(withRouter(SectionManager))
export default withRouter(withContext(withLanguageContext(SectionManager)))
