import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import { Provider as StyletronProvider } from 'styletron-react';
import { LightTheme, BaseProvider, styled } from 'baseui';
import { Client as Styletron } from 'styletron-engine-atomic';
import './css/index.css';
import './prototypes';

const engine = new Styletron();
ReactDOM.render(
  <React.StrictMode>

    <StyletronProvider value={engine}>

      <BaseProvider theme={LightTheme}>

        <App />
      </BaseProvider>
    </StyletronProvider>

  </React.StrictMode>,
  document.getElementById('root')
);

