import React, { useState, useEffect, useContext } from "react"
import { Modal, ModalHeader, ModalBody, ModalFooter, ModalButton, SIZE, ROLE } from "baseui/modal"
import { KIND as ButtonKind, KIND } from "baseui/button"
import { Input } from 'baseui/input';
import { useClient, useUsersOfClient, useClientsOfUser } from "../../hooks";
import { blue, grey1, selectOverrides } from "../../data/styles";
import { css } from "@emotion/css";
import { AddUserToClientModal } from "./AddUserToClientModal";
import { Trash } from "../../svg/Trash";
import { ClientApi, UserApi } from "../../api";
import { Edit } from "../../svg";
import AuthContext from "../../context/AuthContext";
import LanguageContext from "../../context/LanguageContext";
import { trans } from "../../translations";

const UserClientModal = ({ user, isOpen, onClose, isUser = true }) => {
    const { setActiveClient, client: clientOnContext } = useContext(AuthContext)
    const { language, setLanguage, isEnglish } = useContext(LanguageContext)

    const [rerender, setRerender] = useState(0)
    const [loading, setLoading] = useState(false)
    const [activeClientIndex, setActiveClientIndex] = useState(null)
    const [shouldDeleteUserIndex, setShouldDeleteUserIndex] = useState(null)
    const [shouldDeleteUserClientIndex, setShouldDeleteUserClientIndex] = useState(null)
    const [userToEditIndex, setUserToEditIndex] = useState(null)


    // This is used on Client's Dashboard && Admin's Client List
    // So, is isUser is true, then pass in the user's client_id
    // If isUser is false, then the "user" must be a client, so pass the _id
    const { client: _client } = useClient(isUser ? user?.client_id : user?._id)
    const { clients: _clients } = useClientsOfUser(isUser ? user?._id : user?.client_id, true, rerender)

    // This is only for if compliance is looking at this from the ClientList
    const { users } = useUsersOfClient(isUser ? null : user?._id, rerender)

    function handleAddAdmin(index) {
        setActiveClientIndex(index)
    }

    async function handleDeleteAdmin(clientIndex, userIndex) {
        if (users.length === 1) return alert('Cannot delete the only admin on an account');

        if (shouldDeleteUserIndex != userIndex && shouldDeleteUserClientIndex != clientIndex) {
            setShouldDeleteUserIndex(userIndex)
            setShouldDeleteUserClientIndex(clientIndex)
            return;
        }
        await ClientApi.removeUser(clients()[clientIndex]._id, clients()[clientIndex].users[userIndex]._id)
        setRerender(rerender + 1)
        setShouldDeleteUserIndex(null)
        setShouldDeleteUserClientIndex(null)
    }

    function handleEditAdmin(clientIndex, userIndex) {
        console.log(clientIndex, userIndex)
        setUserToEditIndex(userIndex)
        setActiveClientIndex(clientIndex)
    }

    async function _setActiveClient(index) {
        setLoading(true)
        const clientToSet = clients()[index]

        try {
            await setActiveClient(clientToSet._id)
        } catch (e) {
            console.log(e)
        } finally {
            setLoading(false)
        }
    }

    /**
     * Its an arrya in anticipation of making this handle switching between multiple clients
     * but it doesnt support that yet, so for now its always just gonna be 1 client
     */
    const clients = () => isUser ? [..._clients] : [{ ..._client, users }]
    const activeClient = () => activeClientIndex >= 0 ? clients()[activeClientIndex] : null
    const activeUserToEdit = () => userToEditIndex >= 0 ? users[userToEditIndex] : null
    console.log('ACTIVE', activeUserToEdit(), users)
    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalHeader>
                {isUser ? 'My LP\'s' : 'Users for ' + user?.name}
            </ModalHeader>
            <ModalBody>
                <div className={css`
                    font-family: 'Outfit';
                    padding-bottom: 32px;
                    direction: ${isEnglish() ? 'ltr' : 'rtl'};
                `} >
                    <div>
                        {/* <p>Clients</p> */}

                        {clients()?.map((c, i) => (
                            <div style={{ marginBottom: 32 }}>
                                <div key={i} className={css`
                                    display: flex;
                                    flex-direction: row;
                                    justify-content: space-between;
                                    align-items: center;
                                `}>
                                    <div>
                                        <p style={{ fontSize: '1rem', fontWeight: 700 }}>{c?.name}</p>
                                        <p>{c?.email}</p>

                                    </div>

                                    <p className={css`color: ${blue}; font-size: 1rem; cursor: pointer; font-weight: 700;`} onClick={() => handleAddAdmin(i)}>+ {trans('Add Admin', language)}</p>
                                    {isUser &&
                                        <p className={css`
                                            padding: 12px;
                                            background-color: ${clientOnContext?._id == c._id ? grey1 : blue};
                                            color: white;
                                            font-size: 1rem;
                                            border-radius: 0.5rem;
                                            cursor: ${clientOnContext?._id == c._id ? 'inherit' : 'pointer'};
                                            &:hover {
                                                opacity: 0.8;
                                            }
                                        `} onClick={() => _setActiveClient(i)}>{clientOnContext?._id == c._id ? trans('Active', language) : trans('View', language)}</p>
                                    }
                                </div>
                                <div className={css`
                                    display: flex;
                                    flex-direction: column;
                                    justify-content: space-between;
                                    margin-top: 24px;
                                `}>
                                    <p style={{ fontSize: '0.9rem', opacity: 0.8, fontWeight: 500, marginBottom: 12 }}>Admins for {c?.name}</p>
                                    {c?.users?.map((u, ii) => (
                                        <div className={css`
                                            display: flex;
                                            flex-direction: row; 
                                            justify-content: space-between;
                                            align-items: center;
                                            margin-bottom: 12px;
                                            border-bottom: 1px solid rgb(234,234,234);
                                            `}>
                                            <span style={{ opacity: 0.8, fontSize: '0.8rem' }}>{u?.name}</span>
                                            <span style={{ opacity: 0.8, fontSize: '0.8rem' }}>{u?.email}</span>

                                            <div style={{ flexDirection: 'row', display: 'flex', gap: 16 }}>
                                                {!isUser &&
                                                    <div style={{ cursor: 'pointer' }} onClick={() => handleEditAdmin(i, ii)}>
                                                        <Edit height={16} width={16} color={blue} />
                                                    </div>
                                                }
                                                <div style={{ cursor: 'pointer' }} onClick={() => handleDeleteAdmin(i, ii)}>
                                                    {shouldDeleteUserIndex == ii && shouldDeleteUserClientIndex == i
                                                        ? <p style={{ color: 'red' }}>{trans('Delete', language)}</p>
                                                        : <Trash height={16} width={16} />
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ))}

                    </div>

                </div>
            </ModalBody>
            <ModalFooter>
                <ModalButton onClick={onClose} kind={KIND.secondary}>
                    Close
                </ModalButton>
                {/* <ModalButton onClick={() => undefined} overrides={{
                    BaseButton: {
                        style: ({ $theme }) => ({
                            backgroundColor: blue
                        })
                    }
                }} >Done</ModalButton> */}
            </ModalFooter>
            <AddUserToClientModal client={activeClient()} isOpen={activeClient() != null} onClose={() => {
                setActiveClientIndex(null)
                setUserToEditIndex(null)
                setRerender(rerender + 1)
            }} isEdit={activeUserToEdit() != null} userToEdit={activeUserToEdit()} />
        </Modal >
    )
}

export { UserClientModal }

