import { http } from './api';

const NOTE = '/notification';

const GET_ALL = (clientId) => `${NOTE}/client/${clientId}`

export function view(noteId) {
    return http.put(NOTE + '/' + noteId)
}

export function getAll(clientId) {
    return http.get(GET_ALL(clientId))
}

