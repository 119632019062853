import { http } from './api';

const DISTRIBUTION = (fundId) => `/fund/${fundId}/distribution`

/**
 * @param {*} fundId 
 * @param {*} distribution has @amount and @notes
 */
export function create(fundId, distribution) {
    return http.post(DISTRIBUTION(fundId), distribution)
}

export function find(fundId, query={}) {
    return http.get(DISTRIBUTION(fundId), query)
}