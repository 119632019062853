import * as React from "react";
import { Button } from "baseui/button";
import { ButtonGroup } from "baseui/button-group";
import { blue } from "../../data/styles"
import { css } from '@emotion/css'
import { isDateString } from "../../util";

const check = {
  height: 12,
  width: 12,
  backgroundColor: "#fff",
  border: "8px solid" + blue,
  display: "block",
  marginRight: 8,
  borderRadius: "50em"
}

const unchecked = {
  height: 14,
  width: 14,
  backgroundColor: "#f6f6f6",
  border: "7px solid #f6f6f6",
  display: "block",
  marginRight: 8,
  borderRadius: "50em"
}

export default function RadioButton({ value = "", options = [], optionsArabic=[], onSelect, isEnglish=true }) {
  console.log('VALUE', value)
  console.log('isenglish', isEnglish)
  // if(isDateString(value)) {
  //   value = 'paid';
  // } else {
  //   value = 'unpaid';
  // }

  const firstUpdate = React.useRef(true);
  let initialValue = options.findIndex((o) => o == value)
  
  // Let's just check if their select is in the Arabic options
  if(initialValue < 0) {
    initialValue = optionsArabic.findIndex((o) => o == value)
  }
  if (initialValue >= 0) initialValue = [initialValue];

  const [selected, setSelected] = React.useState(initialValue);

  React.useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }

    onSelect(options[selected[0]]);
  }, [selected]);

  return (
    <ButtonGroup
      mode='checkbox'
      selected={selected}
      onClick={(event, index) => {
        setSelected([index]);
        // if (!selected.includes(index)) {
        //   setSelected([...selected, index]);
        // } else {
        //   setSelected(selected.filter((value) => value !== index));
        // }
      }}
      overrides={{
        Root: {
          style: {
            flexDirection: "column"
          }
        }
      }}
    >


      {(isEnglish ? options : optionsArabic).map((o, i) => (
        <div style={{ marginBottom: 8, display: "flex", width: "100%", alignItems: "center", flexDirection: "row" }} className={css`
                      &:hover{
                        cursor:pointer;
                      }
                  `}>
          {(i === selected[0]) ? <span style={check}></span> : <span style={unchecked}></span>}
          <span style={{textTransform: 'capitalize'}}>{o}</span>
        </div>
      ))}

    </ButtonGroup>
  );
}
