import { useEffect, useState } from 'react';
import { DocApi } from '../api';

const useCorpDocCompliance = (clientId, fundId, rerender = 0) => {
    const [status, setStatus] = useState('loading')
    const [isComplete, setIsComplete] = useState(false)
    const [docStatuses, setDocStatuses] = useState({ commercialRegistration: null, articlesOfAssociation: null, boardDecision: null })

    useEffect(() => {
        const getActive = async () => {
            if (!clientId) return;
            setStatus('loading')

            try {
                const { data: docs } = await DocApi.getCorporationComplianceDocs(clientId)
                const _docStatuses = JSON.parse(JSON.stringify(docStatuses))

                for (let i = 0; i < docs.length; i++) {
                    if (docs[i].type == 'commercial-registration') {
                        _docStatuses.commercialRegistration = docs[i]
                        continue;
                    }
                    if (docs[i].type == 'board-decision') {
                        _docStatuses.boardDecision = docs[i]
                        continue;
                    }
                    if (docs[i].type == 'articles-of-association') {
                        _docStatuses.articlesOfAssociation = docs[i]
                        continue;
                    }
                }

                setDocStatuses(_docStatuses)

                if (_docStatuses.articlesOfAssociation && _docStatuses.commercialRegistration) {
                    setIsComplete(true)
                }
                setStatus('success')

            } catch (e) {
                setStatus('error')
                console.log(e)
            }

        }
        getActive()
    }, [clientId, fundId, rerender])

    return { status, docStatuses, isComplete }
}

export { useCorpDocCompliance }