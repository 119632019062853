import React from 'react';
import { css } from '@emotion/css'
import { blue } from "../data/styles"
import { formatPrettyDate, formatPrettyDateMonth } from '../util/time';
import { NotificationApi } from '../api';

export default function NotificationCard(props) {
	const d = props.data

	async function handleView() {
		try {
			await NotificationApi.view(d._id)
			d.viewed = true;
		} catch (e) {
			console.log(e)
		}
	}

	if (!d) return <></>
	return (
		<div className={css`
              width: 100%;
              margin-bottom: 16px;
              box-shadow: 2px 4px 7px rgb(0 0 0 / 10%);
    			padding: 16px;
    			display: block;
    			border: 1px solid #DFDFDF;
    			box-sizing: border-box;
    			background-color: #fff;
    			border-radius: 4px;
    			overflow: hidden;
            `}
			style={{ position: "relative" }}>

			{(props.data.viewed) ? "" : <div style={{ position: "absolute", left: 0, height: "100%", width: 5, backgroundColor: blue, top: 0 }}></div>}

			<div style={{ display: "flex", justifyContent: "space-between", width: "100%", paddingBottom: 4 }}>
				<p className={css`
				  				font-optical-sizing: auto;
							    font-family: "Outfit";
							    font-weight: 500;
							    font-style: normal;
							    font-stretch: normal;
							    line-height: initial;
							    font-size: 15px;
							    font-weight: 300;
							    
									    
					        `}>
					{/*<div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>         
						{(props.data.seen) ? "" : <div style={{backgroundColor: blue, height: 10, width: 10, display: "block", borderRadius: "50em", marginRight: 8}}></div>}    
						
					</div>*/}

					<span>{d.title}</span>
				</p>

				<p className={css`
				  				font-optical-sizing: auto;
									    font-family: "Outfit";
									    font-weight: 500;
									    font-style: normal;
									    font-stretch: normal;
									    line-height: initial;
									    font-size: 15px;
									    font-weight: 300;
									    color: rgb(150, 150, 150);
									    
					        `}>
					{formatPrettyDate(d.createdAt)}
				</p>
			</div>
			<div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
				<p className={css`
				  				font-optical-sizing: auto;
							    font-family: "Outfit";
							    font-weight: 500;
							    font-style: normal;
							    font-stretch: normal;
							    line-height: initial;
							    font-size: 14px;
							    font-weight: 300;
							    color: rgb(150, 150, 150);
									    
					        `}>
					{d.description}
				</p>
				{d.link_text &&
					<div className={css`
				font-optical-sizing: auto;
				font-family: "Outfit";
				font-weight: 500;
				font-style: normal;
				font-stretch: normal;
				line-height: initial;
				font-size: 14px;
				font-weight: 300;
				color: ${blue};
				text-decoration: underline;
				cursor: pointer;
				
				`} onClick={() => window.open(d.link, '_blank')}>{d.link_text}</div>
				}
				{!d.link_text && !d.viewed &&
					<div onClick={handleView} className={css`
				font-optical-sizing: auto;
				font-family: "Outfit";
				font-weight: 500;
				font-style: normal;
				font-stretch: normal;
				line-height: initial;
				font-size: 14px;
				font-weight: 300;
				color: ${blue};
				cursor: pointer;
				// text-decoration: underline;
				
				`}>
						Seen
					</div>
				}

			</div>
		</div>
	)
}