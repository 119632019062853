import * as React from "react";
import { DatePicker } from "baseui/datepicker";
import { dateOverrides } from "../../data/styles"
// eslint-disable-next-line import/no-anonymous-default-export
export default ({ value = '', onSelect, error }) => {
  if (Array.isArray(value)) {
    value = [new Date(value[0])]
  } else if (value != null && value != '') {
    value = [value]
  } else {
    value = [new Date(1980, 1, 1)]
  }
  const [_value, setValue] = React.useState(value);

  React.useEffect(() => {
    console.log(_value)
    onSelect(_value);
  }, [_value]);

  return <DatePicker
    minDate={new Date(1940, 1, 1)}
    value={_value}
    onChange={({ date }) => setValue(Array.isArray(date) ? date : [date])}
    maxDate={new Date(2050, 1, 1)}
    error={error}
    // overrides={dateOverrides}
  />;
};
