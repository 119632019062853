import { useState, useEffect } from 'react';
import LanguageContext from './LanguageContext'

export const LanguageProvider = ({ children }) => {
    const [language, setLanguage] = useState('en');


    const _setLanguage = (l) => {
        return setLanguage(l)
    }

    const _toggleLanguage = () => {
        if(language === 'en') return _setLanguage('ar')
        if(language === 'ar') return _setLanguage('en')
    }

    const isEnglish = () => language === 'en'
    const isArabic = () => language === 'ar'

    const offLanguage = () => language === 'en' ? 'ar' : 'en'


    return (
        <LanguageContext.Provider
            value={{
                language,
                setLanguage: (l) => _setLanguage(l),
                toggleLanguage: () => _toggleLanguage(),
                isEnglish,
                isArabic,
                offLanguage
            }}>
            {children}
        </LanguageContext.Provider>
    );
};

export const Consumer = LanguageContext.Consumer;


export function withLanguageContext(Component) {
    return function LanguageManager(props) {
        return (
            <LanguageContext.Consumer >
                {context => <Component {...props} languageContext={context} />}
            </LanguageContext.Consumer>
        )
    }
}