import { http, serialize } from './api';

const FUND = '/fund';
const STATUS = (fundId, clientId) => `${FUND}/${fundId}/status/${clientId}`
const APPROVE_SUBSCRIPTION = (fundId, clientId) => `${FUND}/${fundId}/status/${clientId}/approve`
const CLIENTS = (fundId) => `${FUND}/${fundId}/client`
const ACCEPT_TC = (fundId, clientId) => `${FUND}/${fundId}/client/${clientId}/tc`

export function create(fund) {
    return http.post(FUND, { fund })
}

export function get(fundId) {
    return http.get(FUND + '/' + fundId)
}

export function update(fundId, fund) {
    return http.put(FUND + '/' + fundId, fund)
}

export function find(query) {
    const _query = serialize(query)
    return http.get(FUND + '?' + _query)
}

export function getClients(fundId) {
    return http.get(CLIENTS(fundId))
}

export function getStatus(fundId, clientId) {
    return http.get(STATUS(fundId, clientId))
}

export function updateStatus(fundId, clientId, committed) {
    const data = { committed }
    return http.put(STATUS(fundId, clientId), data)
}

export function approveSubscription(fundId, clientId) {
    return http.post(APPROVE_SUBSCRIPTION(fundId, clientId))
}

export function addClientsBulk(fundId, clientIds, shouldNotify) {
    return http.post(CLIENTS(fundId) + '/bulk', { clientIds, shouldNotify })
}

export function removeClient(fundId, clientId) {
    return http.delete(CLIENTS(fundId) + '/' + clientId)
}

export function acceptTC(fundId, clientId, tc_signed_as) {
    return http.post(ACCEPT_TC(fundId, clientId), { tc_signed_as })
}