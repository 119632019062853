import { http } from './api';

const AWS = '/aws'
const PREVIEW = (clientId) => `${AWS}/${clientId}/preview`
const DELETE_PREVIEW = (clientId) => `${AWS}/${clientId}/preview/delete`

export function upload(data) {
    return http.post(AWS, data)
}

export function getPreview(clientId, id, type) {
    return http.post(PREVIEW(clientId), { id, type })
}

export function deletePreview(clientId, filename) {
    return http.post(DELETE_PREVIEW(clientId), { filename })
}