import React from 'react';
import { css } from '@emotion/css'

export default function Card(props) {
    return (  
    	<div className={css`
              max-width: 100%;
              width: 100%;
              font-family: "Outline", sans-serif;
              background-color: #fff;
              margin-bottom: 16px;
              padding: 16px;
              box-shadow: 2px 4px 7px rgba(0,0,0,0.1);
              position: relative;
              display: block;
              border: 1px solid #DFDFDF;
              border-radius: 8px;
              box-sizing: border-box;
            `}>
            	<div style={{display: "flex", alignItems: "flex-start", flexDirection: "column"}}>
            		{props.children}
	           	</div>

    	</div>
    )
}