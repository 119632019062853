import { css } from '@emotion/css';
import { Checkbox } from 'baseui/checkbox';
import { getRiskScore } from '../util';
import { label } from '../data/styles';

const RiskScoreAcknowledgement = ({ field, form, onChange }) => {

    const riskScore = () => {
        let { total, unanswered } = getRiskScore(form)
        if (total < 25) return { total, color: 'grey', unanswered, text: 'Incomplete' }
		if (total >= 25 && total < 50) return { total, color: 'red', unanswered, text: 'Not Suitable' }
		if (total >= 50 && total < 100) return { total, color: 'orange', unanswered, text: 'Moderately Suitable Risk' }
		if (total >= 100) return { total, color: 'green', unanswered, text: 'Suitable Risk' }
		return { total, color: 'grey', unanswered, text: 'Incomplete' }
    }

    const isSuitable = () => {
        let { total } = getRiskScore(form)
        return total >= 50
    }

    const suitabilityText = () => {
        if(isSuitable()) return 'Suitable for Investment'
        return ' NOT Suitable for Investment'
    }

    // const suitability = () => {
    //     if (isSuitable()) {
    //         return {
    //             result: <p>Based on your answers, you are <strong style={{ fontWeight: 'bold' }}>{suitabilityText()}</strong> with IMPACT46</p>
    //         }
    //     }
    //     // if (total < 16) return {
    //     //     suitability: `Your score indicates a low appetite for risk and therefore we recommend ${<span style={{ fontWeight: 'bold' }}>NOT</span>} investing with IMPACT46`,
    //     //     recommendation: 'If you want to disregar'
    //     // }
    // }

    const suitabilityCheckboxLabel = () => {
        if(isSuitable()) return (<p>Please acknowledge that you accept your risk score</p>)
        return (<p>You are not suitable for investment at IMPACT46. However, if you choose to ignore your risk score and proceed anyways, please acknowledge</p>)
    }

    return (
        <div className={css`
            display: flex;
            flex-direction: column;
        `}>

            <div className={css`
            display: flex;
            flex-direction: row;

        `}>
                <div>
                    <div title='Risk Score' className={css`
                    padding: 16px;
                    // padding-left: 8px; padding-right: 8px;
                    font-size: 1.5rem;
                    font-weight: 500;
                    background-color: ${riskScore()?.color};
                    border-radius: 64px;
                    color:  white;
                    margin-left: 16px;
                    font-family: 'Outfit';
                `}>{riskScore().total}</div>
                </div>

                <div className={css`
                display: flex;
                flex-direction: column;
                padding: 12px;
            `}>
                    <p className={css`
                    font-size: 1rem;
                    font-weight: bold;
                    margin-bottom: 4px;
                `}>{riskScore()?.text}</p>
                    {/* <p>{suitability()?.result}</p> */}
                    <p>Based on your answers, you are <strong style={{ fontWeight: 'bold' }}>{suitabilityText()}</strong> with IMPACT46</p>
                </div>

            </div>
            <div className={css`
                margin-top: 16px;
            `}>
                <p className={label}>{suitabilityCheckboxLabel()}</p>
                <Checkbox
                    key={field.id}
                    checked={field.value}
                    onChange={e => onChange(e.target.checked)}
                />
            </div>
        </div>

    )
}

export { RiskScoreAcknowledgement }