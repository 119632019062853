import React, { useContext, useEffect, useState } from 'react';
import { DocApi } from '../api';

const useDocs = (userId, fundId, query={}, rerender=0) => {
    const [status, setStatus] = useState('loading')
    const [docs, setDocs] = useState([])


    useEffect(() => {
        const getDocs = async() => {
            if(!userId || !fundId) return;
            setStatus('loading')
            try {
                const { data: _docs } = await DocApi.getAll(userId, fundId, query)
                console.log('DOCS HERE', _docs)
                setDocs(_docs)
                setStatus('success')
            } catch(e) {
                setStatus('error')
            }

        }
        getDocs()
    }, [userId, fundId, query?.type, rerender])

    return { status, docs }
}

export { useDocs }