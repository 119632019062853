const IndividualAcknowledgement = () => {
    console.log('doin this brah')
    return (

        <div>
            <ul style={{ fontFamily: 'Outfit' }}>
                <li>I hereby certify the information that I have provided in this
                    form is true, correct and complete.
                </li>
                <li>
                    I acknowledge that company will rely on the information
                    provided in this form. Any change, I will update my data
                    and information. If I do not update the data and do not
                    submit an updated copy of my ID and documents within six
                    months of its termination, the company will freeze my
                    account for a period of twelve months and then close the
                    account without notice
                </li>
                <li>
                    I herby acknowledge that all information and data
                    contained in the Investment Account Opening
                    agreement (this agreement) is true and accurate, and
                    my responsibility and company can depend on them,
                    and thus authorize company to execute my
                    operational and financial investment orders upon my
                    request, if any, on the Investment Account described
                    in the beginning of of this agreement.
                </li>
                <li>
                    Client further agrees that, it will provide company
                    Capital with his/her and the Authorized
                    Representative’s renewed IDs and Commercial
                    Registration the expiration of its validity, And a
                    maximum of 3 years from the date of account opening
                    or last update, and agrees that company has the right
                    to freeze the account upon expiry of these documents
                    and suspend all operations until the certified copies of
                    renewed documents are received.
                </li>
                <li>
                    I confirm that under no circumstances shall the company,
                    its employees or its contractors be liable for any direct,
                    indirect, incidental, special, punitive or consequential
                    damages that may result in any way from their reliance on
                    the information that I have provided.
                </li>
                <li>
                    I confirm that I have completed and provided this form
                    willingly without advice or help from the company . I
                    understand that providing false information, withholding
                    relevant information or responding in a misleading way
                    may result in rejection of my application and/or other
                    appropriate action taken against me.
                </li>
                <li>
                    I acknowledge company will rely on the information
                    provided in this form until notice in writing satisfactory to
                    the company of its revocation and by submission of an
                    update self-certification and declaration form is received by
                    the company , immediate were any change in
                    circumstances occurs.
                </li>
                <li>
                    I acknowledge that my suitability and risk tolerance report
                    has been received and understood and the company's
                    products to be invested in. I also acknowledge my desire to
                    work with it and bear any responsibility and any risks
                    arising from this report. I also acknowledge receiving full
                    advice for my investment situation and suitability of the
                    products offered.
                </li>
                <li>
                    I/We waive the company of its duty of confidentiality and
                    grant the company the liberty to make available, as and
                    when required by law/ authorities, to the Courts,
                    regulatory/or other authorities of Saudi Arabia or my/our
                    country of residence or the country in whose currency this
                    account will be maintained or routed, or to any other
                    regulator/tax authority or other authorities in domestic and
                    other jurisdictions, any information relating to my/our
                    account, without any prior consent from me/us.
                </li>
            </ul>
        </div>
    )
}

export { IndividualAcknowledgement }