import React, { useEffect, useState } from 'react';
import { ReportApi } from '../api';

const useQueryReports = (query, rerender=0) => {
    const [status, setStatus] = useState('success')
    const [reports, setReports] = useState([])

    useEffect(() => {
        const getReports = async() => {
            if(!query || Object.keys(query).length == 0) return;

            try {
                setStatus('loading')
                const { data: _reports } = await ReportApi.find(query)
                setReports(_reports)
                setStatus('success')
              
                } catch (e) {
                setStatus('error')
                console.log(e)
            }

        }
        getReports()
    }, [Object.keys(query).length, query?.fund_id, query?.type, query?.year, rerender])

    return { status, reports }
}

export { useQueryReports }