import React from "react";
import { Button } from "baseui/button";
import { Input } from "baseui/input";
import { css } from "@emotion/css";

const List = ({ value, onChange, placeholder = "Item", onBlur=()=>{} }) => {
  if(typeof(value) == 'string') {
    onChange([value])
    setTimeout(() => onBlur(), 1000)
    return <></>
  }
  
  return (
    <div>
      {value.map((v, i) => (
        <div className={css`
          margin-bottom: 4px;
        `}>
          <Input
            value={v}
            placeholder={placeholder}
            onBlur={onBlur}
            onChange={(e) => {
              const arr = [...value];
              arr[i] = e.target.value;
              onChange(arr);
            }}
          />
        </div>
      ))}
      <div  className={css`
          margin-top: 4px;
        `}>
        <Button onClick={() => onChange([...value, ""])}>Add</Button>
      </div>
    </div>
  );
};

export default List;
