import React, { useEffect, useState } from 'react';
import { CapitalCallApi } from '../api';

const useCapitalCallTotals = (fundId, rerender=0) => {
    const [status, setStatus] = useState('loading')
    const [totals, setTotals] = useState({ called: 0, received: 0 })

    useEffect(() => {
        const getTotals = async() => {
            if(!fundId) return;
            setStatus('loading')

            try {
                const { data: _totals } = await CapitalCallApi.getTotalCalled(fundId)
                setTotals(_totals)
                setStatus('success')
            } catch(e) {
                setStatus('error')
            }

        }
        getTotals()
    }, [fundId, rerender])

    return { status, called: totals.called, received: totals.received }
}

export { useCapitalCallTotals }