import React, { useState, useEffect } from "react"
import { Modal, ModalHeader, ModalBody, ModalFooter, ModalButton, SIZE, ROLE } from "baseui/modal"
import { KIND as ButtonKind, KIND } from "baseui/button"
import { Input } from 'baseui/input';
import { blue } from "../../data/styles";
import { ClientApi, UserApi } from "../../api";

const AddUserToClientModal = ({ client, isOpen, isEdit=false, userToEdit, onSend, onClose }) => {
  const [loading, setLoading] = useState(false)
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [error, setError] = useState(null)

  useEffect(() => {
    if(isEdit == true && userToEdit != null) {
      setName(userToEdit?.name)
      setEmail(userToEdit?.email)
    }
  }, [isEdit, userToEdit?._id])

  async function submit() {

    if (name.length === 0) return setError("Must provide a name");
    const isValid = isValidEmail()
    if (!isValid) return setError("Invalid email");

    setLoading(true)

    const user = {
      name,
      email: email.toLowerCase(),
      company_id: client.company_id,
      // client_id: client._id,
      // client_ids: [client._id]
    }

    try {
      if(isEdit) {
        await UserApi.update(userToEdit._id, {
          name,
          email
        })
      } else { 
        // await UserApi.create(user)
        await ClientApi.addUser(client._id, user)
      }
      setName("")
      setEmail("")
      setError(null)
      setLoading(false)
      onClose()
    } catch(e) {
      console.log(e)
      setError('Could not create this user, please try again')
      setLoading(false)
    }
    // await onSend({ name, email: email.trim(), client_id: client._id, company_id: client.company_id })
  }

  function isValidEmail() {
    const isValid = email.includes('@') && !email.includes(',')
    // if (!isValid) setError('Invalid email')
    // if(isValid) setError('')
    return isValid;
  }

  const canSubmit = () => {
    return isValidEmail() && name?.length > 0
  }

  function handleClose() {
    setName("")
    setEmail("")
    setError("")
    setLoading(false)
    onClose()
  }

  return (
    <Modal isOpen={isOpen} onClose={handleClose}>
      <ModalHeader>{isEdit ? 'Edit ' + userToEdit?.name : 'Add Admin to ' + client?.name}</ModalHeader>
      <ModalBody>
        <div class="flex flex-col">
          <div style={{ marginTop: 16 }}>
            <p style={{ marginTop: 16 }}>{isEdit ? 'Edit the admin for ' : 'Add an admin to have access to '}{client?.name}</p>

            <div style={{ marginTop: 12 }}>
              <label>Admin's Name</label>
              <Input placeholder="Name" onChange={e => setName(e.target.value)} value={name} />
            </div>
            <div style={{ marginTop: 12 }}>
              <label>Admin's Email</label>
              <Input placeholder="Email" onChange={e => setEmail(e.target.value)} value={email} />
            </div>
          </div>
          <p style={{ color: 'red' }}>{error}</p>
        </div>
      </ModalBody>
      <ModalFooter>
        <ModalButton onClick={handleClose} kind={KIND.secondary}>
          Close
        </ModalButton>
        <ModalButton isLoading={loading} onClick={submit} disabled={!canSubmit()} overrides={{
          BaseButton: {
            style: ({ $theme }) => ({
              backgroundColor: blue
            })
          }
        }} >{isEdit ? 'Edit' : 'Add'} Admin</ModalButton>
      </ModalFooter>
    </Modal>
  )
}

export { AddUserToClientModal }
