import React from 'react';
import { css } from '@emotion/css'


export default function Label({ text, color }) {
    return (  
    	<label 
		// font-weight: 500;
    		className={css`
              	font-optical-sizing: auto;
			    font-family: "Outfit";
			    font-style: normal;
			    font-stretch: normal;
			    line-height: initial;
            `}
    		style={{position: "relative", fontSize: 16, color: color, userSelect: "none"}}>
    		{text}
    	</label>
    )
}