import React, { useEffect, useState } from 'react';
import { BankApi } from '../api';

const useBankAccount = (clientId, rerender=0) => {
    const [status, setStatus] = useState('loading')
    const [bankAccount, setBankAccount] = useState(null)

    useEffect(() => {
        const getBankAccount = async() => {
            if(!clientId) return;
            setStatus('loading')

            try {
                const { data: bank } = await BankApi.me.get(clientId)
                if(bank && bank.length > 0) {
                    setBankAccount(bank[0])
                }
                setStatus('success')
              
                } catch (e) {
                setStatus('error')
                console.log(e)
            }

        }
        getBankAccount()
    }, [clientId, rerender])

    return { status, bankAccount }
}

export { useBankAccount }