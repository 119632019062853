import React, { useEffect, useState } from 'react';
import { CapitalCallApi } from '../api';

const useActiveCapitalCall = (fundId, rerender=0) => {
    const [status, setStatus] = useState('loading')
    const [capitalCall, setCapitalCall] = useState({ })

    useEffect(() => {
        const getActive = async() => {
            if(!fundId) return;
            setStatus('loading')

            try {
                const { data: cc } = await CapitalCallApi.getActive(fundId)

                setCapitalCall(cc)
                setStatus('success')
              
                } catch (e) {
                setStatus('error')
                console.log(e)
            }

        }
        getActive()
    }, [fundId, rerender])

    return { status, capitalCall }
}

export { useActiveCapitalCall }