import { css } from '@emotion/css';
import { useState, useEffect } from 'react';
import { AuthApi, ClientApi, FundApi, UserApi } from '../api';
import AuthContext from './AuthContext'

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [client, setClient] = useState(null);
    const [funds, setFunds] = useState([])
    const [fund, setFund] = useState([])
    const [role, setRole] = useState('client')

    function handleSetFund(fundId) {
        const _fund = funds.find(f => f._id.toString() == fundId.toString())
        if (_fund) setFund(_fund)
    }

    useEffect(() => {
        authenticate()
    }, []);

    async function authenticate() {
        try {
            const { data: _user } = await AuthApi.authenticate()

            setUser(_user)

            if (_user.is == 'admin') {
                const { data: _funds } = await FundApi.find({ company_id: _user.company_id })
                setFunds(_funds)
                if (_funds.length > 0) {
                    const savedFund = localStorage.getItem('active_fund')
                    if (savedFund) {
                        const fund = _funds.find(f => f._id === savedFund.substring(2))
                        if (!fund) return setFund(_funds[0])

                        setFund(fund)
                    } else {
                        setFund(_funds[0])
                    }
                }
            }

            // If authenticated, get client as well
            if (_user.is == 'user') {
                const { data: _client } = await ClientApi.get(_user.client_id)
                setClient(_client)

                const { data: _funds } = await ClientApi.getFunds(_user.client_id)
                setFunds(_funds)
                if (_funds.length > 0) {
                    const savedFund = localStorage.getItem('active_fund')
                    if (savedFund) {
                        const fund = _funds.find(f => f._id === savedFund.substring(2))
                        if (!fund) return setFund(_funds[0])
                        setFund(fund)
                    } else {
                        setFund(_funds[0])
                    }
                }
            }
        } catch (e) {
            console.log(e)
            if (!window.location.pathname.includes('login')) {
                window.location = '/login'
            }
        }
    }

    /**
     * Refetches client on context
     */
    async function updateClient(callback = () => undefined) {
        try {
            const { data } = await ClientApi.get(client._id)
            setClient(data)
            callback()
        } catch (e) {
            console.log(e)
        }
    }

    async function setActiveClient(clientId) {
        try {
            await UserApi.setActiveClient(clientId)
            await authenticate()
        } catch (e) {
            console.log(e)
        }
    }

    /**
     * This is mainly just for the refresh on the dashboard
     */
    function handleSetClient(_client) {
        setClient(_client)
    }

    return (
        <AuthContext.Provider
            value={{
                user,
                client,
                funds,
                fund,
                role,
                setFund: (_fund) => handleSetFund(_fund),
                authenticate: () => authenticate(),
                setClient: (_client) => handleSetClient(_client),
                updateClient: (callback) => updateClient(callback),
                setActiveClient: (clientId) => setActiveClient(clientId)
            }}>
            {children}
            
        </AuthContext.Provider>
    );
};

export const Consumer = AuthContext.Consumer;


export function withContext(Component) {
    return function UserManager(props) {
        return (
            <AuthContext.Consumer >
                {context => <Component {...props} context={context} />}
            </AuthContext.Consumer>
        )
    }
}