import { useContext, useState } from "react";
import { AwsApi } from "../api";
import AuthContext from "../context/AuthContext";
import { Button } from "../elements/Button";
import { usePreviewUrl } from "../hooks";

const Preview = ({ id, clientId, type, close }) => {

    const [error, setError] = useState(null)
    // const { client, user } = useContext(AuthContext)
    const { url } = usePreviewUrl(clientId, id, type)

    const width = document.documentElement.clientWidth;
    const height = document.documentElement.clientHeight - 84;

    const isDocxOrXlsx = () => {
        if(!url) return false
        return url.includes('.docx') || url.includes('.doc') || url.includes('.xlsx')
    }

    const isPdf = () => {
        if(!url) return false
        return url.includes('.pdf')
    }

    const isImage = () => {
        if(!url) return false
        return url.includes('.jpg') || url.includes('.jpeg') || url.includes('.png')
    }

    const getUrl = () => {
        if(!url) return null;
        if (url.includes('.docx') || url.includes('.doc') || url.includes('.xlsx')) {
            return "https://view.officeapps.live.com/op/embed.aspx?src=" + decodeURIComponent(url);
        }
        if (url.includes('.pdf')) {
            const g = decodeURIComponent(url)
            return g
        }
        if (url.includes('.jpg') || url.includes('.jpeg') || url.includes('.png')) {
            const g = decodeURIComponent(url)
            return g
        }
        setError('Cannot load this file, please try downloading it instead.')
    }

    function handleDownload() {
        window.open(decodeURIComponent(url), '_blank')
    }

    async function handleClose() {
        if(!url) return window.close()
        const filename = url.substring(url.lastIndexOf('/'), url.length)
        await AwsApi.deletePreview(clientId, filename)
        window.close()
    }

    return (
        <div style={{ position: "fixed", width: "100vw", height: "100vh", top: 0, left: 0, top: 0, zIndex: 9999999, backgroundColor: "#fff" }}>
            <header style={{ height: '84px', border: 'none', backgroundColor: '#fff', alignItems: 'center', display: 'flex' }}>
                <button style={{ height: 64, width: 64, border: 'none', cursor: 'pointer', padding: 8, marginLeft: 8 }} onClick={handleClose}>
                    <svg viewBox="0 0 16 16" >
                        <g strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10">
                            <line x1="13.5" y1="2.5" x2="2.5" y2="13.5"></line><line x1="2.5" y1="2.5" x2="13.5" y2="13.5"></line>
                        </g>
                    </svg>
                </button>
            </header>
            {!error && isDocxOrXlsx() &&
                <iframe src={getUrl()} width={width} height={height} frameBorder='0'></iframe>
            }
            {!error && isPdf() &&
                <div style={{ height: '90vh', width: '100%' }}>

                    {/* <iframe src={'https://jeffery-saudi.s3.eu-central-1.amazonaws.com/doc-Payment.pdf'} height={800} width={800} /> */}
                    <object data={getUrl()} type="application/pdf" width="100%" height="100%" />
                </div>
            }

            {!error && isImage() &&
                <div style={{ height: '90vh', width: 'auto', overflow: 'scroll' }}>
                    <img src={getUrl() } />
                </div>
            }

            <p>If the document does not display, it might have downloaded to your computer instead. Please check your downloads folder.</p>

            {error && <div style={{ height: 400, width: 400, marginTop: 40 }}>
                <p>We are sorry but this file type cannot be previewed. If you would like to download it, click here</p>
                <Button text='Download' onClick={handleDownload} />
            </div>
            }
        </div>
    )
}

export { Preview }