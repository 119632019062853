import React, { useState, useEffect, useContext } from 'react';

import { css } from '@emotion/css'
import SubNav from "../components/SubNav"
import CardWrapper from "../elements/cards/CardWrapper"
import Label from "../elements/Label"
import { grey1, blue } from "../data/styles"
import Modal from '../elements/Modal';
import FileInput from '../elements/inputs/FileInput';

import { DocApi } from '../api';
import { useDistributions, useDocs } from '../hooks';
import AuthContext from '../context/AuthContext';
import { formatPrettyDateMonth } from '../util/time';
import RadioButton from '../elements/inputs/RadioButton';
import { ToggleOptions } from '../elements/ToggleOptions';
import { Download } from '../svg';
import { View } from '../svg/View';
import { useHistory } from 'react-router-dom';
import { DocUtil } from '../util';
import { CreateDistribution, DistributionItemClient } from '../components/Distributions';
import { DistributionItemAdmin } from '../components/Distributions/DistributionItemAdmin';
import LanguageContext from '../context/LanguageContext';
import { trans } from '../translations';

const englishHeaders = ["File Name", "", "Date Created", "Actions"]
const arabicHeaders = ["اسم", "", "ملتزم", ""]

function Distributions() {
    const history = useHistory()
    const { user, client, fund } = useContext(AuthContext)
    const { language, isEnglish } = useContext(LanguageContext)
    const { distributions } = useDistributions(fund?._id)

    const [isCreateDistributionOpen, setIsCreateDistributionOpen] = useState(false)
    const [isUploadOpen, setIsUploadOpen] = useState(false)
    const [fileToUpload, setFileToUpload] = useState(null)
    const [isPublic, setIsPublic] = useState('Private')
    const [rerender, setRerender] = useState(0)
    const [loading, setLoading] = useState(false)

    const isAdminUploadingForClient = () => user.is === 'admin' && client

    const isAdmin = () => user?.is === 'admin'

    return (
        <div style={{ backgroundColor: "#F9F9F9", height: '100vh', direction: isEnglish() ? 'ltr' : 'rtl' }}>
            <SubNav
                user={user}
                fund={fund}
                currentTab={3}
                isEnglish={isEnglish}
            />
            <div
                className={css`
					width: 100%;
					max-width: 1100px;
					display: block;
		            @media (max-width: 1100px) {
				       padding-left: 16px;
				       padding-right: 16px;
				    }
				    @media (min-width: 1100px) {
		            	display: flex;
				        padding-left: 16px;
				        padding-right: 16px;
				    }
		        `}
                style={{ flexDirection: "row", justifyContent: "space-between", paddingTop: 32, paddingBottom: 16, margin: "0 auto", alignItems: "center" }}>
                <div>

                </div>

                {isAdmin() &&
                    <button
                        className={css` 
                font-optical-sizing: auto;
                font-family: "Outfit";
                font-weight: 500;
                font-style: normal;
                font-stretch: normal;
                line-height: initial;
                font-size: 1rem;
                &:hover{
                    cursor: pointer;
                }
                `}
                        style={{ backgroundColor: blue, border: "none", color: "#fff", padding: "12px 24px", borderRadius: 4 }}
                        onClick={() => setIsCreateDistributionOpen(true)}
                    >

                        {(isEnglish) ? "Create Distribution" : "نشاء توزيع"}
                    </button>
                }
            </div>



            <div
                className={css`
	  				width: 100%;
	  				position: relative;
                    font-family: 'Outfit';

		            @media (min-width: 768px) {
				       display: flex; 
				       align-items: flex-start
				    }
		        `}
                style={{ paddingTop: 16, width: 1100, maxWidth: "80%", margin: "0 auto" }}
            >

                {/* {docs.length === 0 &&
					<div className={css`
					display: flex;
					justify-content: center;
					align-items: center;
				`}>
						<EmptyState illustration='document' butt/>
					</div>
				} */}

                {/* {docs.length > 0 && */}
                <div className={css`display: flex; flex-direction: column; flex: 1;`}>
                    {distributions?.map((d, i) => (
                        isAdmin()
                            ? <DistributionItemAdmin isEnglish={isEnglish()} language={language} fund={fund} client={client} distribution={d} index={i} />
                            : <DistributionItemClient isEnglish={isEnglish()} language={language} fund={fund} client={client} distribution={d} index={i} />
                    ))}
                    {distributions.length === 0 &&
                        <div style={{ backgroundColor: 'rgb(234,234,234)' }} className={css`
                        display: flex; 
                        flex: 1; 
                        align-items: center; 
                        justify-content: center;
                        font-size: 1.2rem;
                        `}>
                            <p style={{textAlign: 'center', padding: 48}}>{trans('No Distributions yet', language)}</p>
                        </div>
                    }
                </div>

            </div>

            {
                isUploadOpen &&
                <Modal close={() => setIsUploadOpen(!isUploadOpen)}>
                    <div style={{ width: 480 }}>

                        <h1 className={css`font-weight: 500; font-size: 22px; margin-bottom: 16px;`}>{isAdminUploadingForClient() ? 'Upload File for ' + client?.name : 'Upload File'}</h1>
                        <FileInput onSelect={(file) => setFileToUpload(file)} value={fileToUpload} showFilePreview={false} />

                        {user.is == 'admin' && !isAdminUploadingForClient() &&
                            <div style={{ marginTop: 16 }}>
                                <RadioButton options={['Public', 'Private']} value={isPublic} onSelect={(v) => setIsPublic(v)} />
                            </div>
                        }
                        <div style={{ width: "100%", textAlign: "center", margin: "0 auto", paddingTop: 32 }}>
                            <button
                                disabled={fileToUpload == null || loading}
                                className={css` 
			        				font-optical-sizing: auto;
								    font-family: "Outfit";
								    font-style: normal;
								    font-stretch: normal;
								    line-height: initial;
								    font-size: 15px;
								    &:hover{
					                  cursor: pointer;
					                }
					                &:disabled{
					                	pointer-events: none;
					                	opacity: 0.5;
					                }
			        			`}
                                style={{ backgroundColor: blue, border: "none", color: "#fff", padding: "12px 36px", borderRadius: 4 }}
                                onClick={() => undefined}

                            >

                                {trans('Submit', language)}
                            </button>
                        </div>
                    </div>
                </Modal>
            }
            <CreateDistribution isOpen={isCreateDistributionOpen} handleClose={() => setIsCreateDistributionOpen(false)} fund={fund} />
        </div >
    );
}

export default Distributions;
