export function getFieldIndexes(id, form) {
  for (let i = 0; i < form.data.sections.length; i++) {
    for (let j = 0; j < form.data.sections[i].subsections.length; j++) {
      for (let k = 0; k < form.data.sections[i].subsections[j].fields.length; k++) {
        if (form.data.sections[i].subsections[j].fields[k].id == id) {
          return [i, j, k];
        }
      }
    }
  }
  return null;
}

export function getFieldIndexesByTitle(title, form) {
  for (let i = 0; i < form.data.sections.length; i++) {
    for (let j = 0; j < form.data.sections[i].subsections.length; j++) {
      for (let k = 0; k < form.data.sections[i].subsections[j].fields.length; k++) {
        if (form.data.sections[i].subsections[j].fields[k].title == title) {
          return [i, j, k];
        }
      }
    }
  }
  return null;
}

export function getValueById(id, form) {
  const indexes = getFieldIndexes(id, form)
  return form?.data?.sections[indexes[0]]?.subsections[indexes[1]]?.fields[indexes[2]]?.value || ''
}

export function getProgress(form, sectionIndex) {
  // const { form: kyc } = this.state;
  // const kyc = form;

  if (!form || form._id == null) return;

  let total = 0;
  let completed = 0;
  for (let j = 0; j < form.data.sections[sectionIndex].subsections.length; j++) {
    for (let k = 0; k < form.data.sections[sectionIndex].subsections[j].fields.length; k++) {
      if (form.data.sections[sectionIndex].subsections[j].fields[k].conditionRef != null && form.data.sections[sectionIndex].subsections[j].fields[k].conditionRef != "") {
        const indexes = getFieldIndexes(form.data.sections[sectionIndex].subsections[j].fields[k].conditionRef, form);
        const conditionalField = form.data.sections[indexes[0]].subsections[indexes[1]].fields[indexes[2]];

        // if it has a condition, see if that condition is met
        if (conditionalField.value == form.data.sections[sectionIndex].subsections[j].fields[k].condition) {
          total++;
          if (form.data.sections[sectionIndex].subsections[j].fields[k].value != null && form.data.sections[sectionIndex].subsections[j].fields[k].value != "") {
            completed++;
            continue;
          }
        }

        // if the conditional field has a value then count it
        // if(conditionalField.value != null && conditionalField.value != "") {
        //   completed++;
        // }
        continue;
      }

      total++;
      if (form.data.sections[sectionIndex].subsections[j].fields[k].value != null && form.data.sections[sectionIndex].subsections[j].fields[k].value != "") {
        completed++;
      }
    }
  }
  return ((completed / total) * 100).toFixed(0);
}

/**
 * Get overall progress of an entire form for the overall progress bar
 */
export function getOverallProgress(form) {
  if (!form || form._id == null) return;

  let total = 0;
  let completed = 0;
  for (let i = 0; i < form.data.sections.length; i++) {
    for (let j = 0; j < form.data.sections[i]?.subsections?.length; j++) {
      for (let k = 0; k < form.data.sections[i]?.subsections[j]?.fields?.length; k++) {
        if (form.data.sections[i].subsections[j].fields[k].conditionRef != null && form.data.sections[i].subsections[j].fields[k].conditionRef != "") {
          const indexes = getFieldIndexes(form.data.sections[i].subsections[j].fields[k].conditionRef, form);
          const conditionalField = form.data.sections[indexes[0]].subsections[indexes[1]].fields[indexes[2]];

          // if it has a condition, see if that condition is met
          if (conditionalField.value == form.data.sections[i].subsections[j].fields[k].condition) {
            total++;
            if (form.data.sections[i].subsections[j].fields[k].value != null && form.data.sections[i].subsections[j].fields[k].value != "") {
              completed++;
              continue;
            }
          }

          // if the conditional field has a value then count it
          // if(conditionalField.value != null && conditionalField.value != "") {
          //   completed++;
          // }
          continue;
        }
        total++;
        if (form.data.sections[i]?.subsections[j]?.fields[k]?.value != null && form.data.sections[i]?.subsections[j]?.fields[k]?.value != "") {
          completed++;
        }
      }
    }
  }
  console.log('TOTAL: ', total)
  console.log('COMPLETED: ', completed)
  return ((completed / total) * 100).toFixed(0);
}

// This is the same as getOverallProgress except it returns the values before they are calculated
export function getTotalCompleteAndIncomplete(form) {
  if (!form || form._id == null) return;

  let total = 0;
  let completed = 0;
  for (let i = 0; i < form.data.sections.length; i++) {
    for (let j = 0; j < form.data.sections[i]?.subsections?.length; j++) {
      for (let k = 0; k < form.data.sections[i]?.subsections[j]?.fields?.length; k++) {
        if (form.data.sections[i].subsections[j].fields[k].conditionRef != null && form.data.sections[i].subsections[j].fields[k].conditionRef != "") {
          const indexes = getFieldIndexes(form.data.sections[i].subsections[j].fields[k].conditionRef, form);
          const conditionalField = form.data.sections[indexes[0]].subsections[indexes[1]].fields[indexes[2]];

          // if it has a condition, see if that condition is met
          if (conditionalField.value == form.data.sections[i].subsections[j].fields[k].condition) {
            total++;
            if (form.data.sections[i].subsections[j].fields[k].value != null && form.data.sections[i].subsections[j].fields[k].value != "") {
              completed++;
              continue;
            }
          }

          // if the conditional field has a value then count it
          // if(conditionalField.value != null && conditionalField.value != "") {
          //   completed++;
          // }
          continue;
        }
        total++;
        if (form.data.sections[i]?.subsections[j]?.fields[k]?.value != null && form.data.sections[i]?.subsections[j]?.fields[k]?.value != "") {
          completed++;
        }
      }
    }
  }
  console.log('TOTAL: ', total)
  console.log('COMPLETED: ', completed)
  return { total, completed }
}

export function subsectionHasIncompleteFields(subsection, form) {
  for (let i = 0; i < subsection.fields.length; i++) {
    if (subsection.fields[i].conditionRef != null && subsection.fields[i].conditionRef != "") {
      const indexes = getFieldIndexes(subsection.fields[i].conditionRef, form);
      const conditionalField = subsection.fields[indexes[2]];

      // if it has a condition, see if that condition is met
      if (conditionalField.value == subsection.fields[i].condition) {
        if (subsection.fields[i].value == null || subsection.fields[i].value == "") {
          console.log('its trueee', subsection.fields[i])
          return true;
        }
      }
      continue;
    }
    if (subsection.fields[i]?.value == null || subsection.fields[i]?.value == "") {
      return true;
    }
  }
  return false;
}

export function getNextMissingField(sectionIndex, subsectionIndex, form) {
  if (!form || form._id == null) return;

  for (let i = sectionIndex; i < form.data.sections.length; i++) {
    for (let j = subsectionIndex; j < form.data.sections[i]?.subsections?.length; j++) {
      for (let k = 0; k < form.data.sections[i]?.subsections[j]?.fields?.length; k++) {
        if (form.data.sections[i].subsections[j].fields[k].conditionRef != null && form.data.sections[i].subsections[j].fields[k].conditionRef != "") {
          const indexes = getFieldIndexes(form.data.sections[i].subsections[j].fields[k].conditionRef, form);
          const conditionalField = form.data.sections[indexes[0]].subsections[indexes[1]].fields[indexes[2]];

          if (conditionalField.value == form.data.sections[i].subsections[j].fields[k].condition) {
            if (form.data.sections[i].subsections[j].fields[k].value == null || form.data.sections[i].subsections[j].fields[k].value == "") {
              return [i, j, k]
            }
          }

          // if the conditional field has a value then count it
          // if(conditionalField.value != null && conditionalField.value != "") {
          //   completed++;
          // }
          continue;
        }
        if (form.data.sections[i]?.subsections[j]?.fields[k]?.value == null || form.data.sections[i]?.subsections[j]?.fields[k]?.value == "") {
          return [i, j, k]
        }
      }
    }
  }
  return null
}

export function getRiskScore(form) {
  if (!form) return { total: 0, unanswered: 0 }

  function getPointsForMoneyField(field) {
    const value = Number(field.value)
    if (isNaN(value)) return 0

    if (value < 200000) return 1
    if (value >= 200000 && value < 400000) return 2
    if (value >= 500000 && value < 1000000) return 4
    if (value >= 1000000 && value < 5000000) return 6
    if (value >= 5000000) return 8
    return 0

  }

  let total = 0, unanswered = 0;

  for (let i = 0; i < form.data.sections.length; i++) {
    for (let j = 0; j < form.data.sections[i]?.subsections?.length; j++) {
      for (let k = 0; k < form.data.sections[i]?.subsections[j]?.fields?.length; k++) {

        /** 
         * Handle misc money fields that have points
         */
        const id = form.data.sections[i].subsections[j].fields[k].id
        if (id == 'billsbonds4' ||
          id == 'perps4' ||
          id == 'invfunds4' ||
          id == 'restate4' ||
          id == 'dercon4' ||
          id == 'altinv4' ||
          id == 'depmura4') {
          total += getPointsForMoneyField(form.data.sections[i].subsections[j].fields[k])
          continue;
        }

        // This field has points
        if (form.data.sections[i].subsections[j].fields[k].points?.length > 0) {

          // The field is unanswered, so dont count it
          if (isNullOrEmpty(form.data.sections[i].subsections[j].fields[k].value)) {
            unanswered++
            continue;
          }

          // Get index of the answer
          let index = form.data.sections[i].subsections[j].fields[k].options.findIndex(o => o?.toLowerCase() == form.data.sections[i].subsections[j].fields[k].value?.toLowerCase())

          if (index < 0) {
            debugger
            index = form.data.sections[i].subsections[j].fields[k].options_arabic.findIndex(o => o == form.data.sections[i].subsections[j].fields[k].value)
          }

          // This should never be called, so more just for debugging
          if (index < 0) {
            debugger
            console.log('WTFFFFF', form.data.sections[i].subsections[j].fields[k])
            continue
          }

          total += form.data.sections[i].subsections[j].fields[k].points[index]
        }
      }
    }
  }
  return { total, unanswered }
}

export const isNullOrEmpty = (value) => {
  return value == '' || value == null;
}

export const isQFI = (form) => {
  if (!form) return false
  const value = getValueById('g_class', form)
  return value == 'Qualified Client'
}