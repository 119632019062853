let months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

export const formatTimestamp = (date) => {
  let d = new Date(date)
  let hours = d.getHours()
  let ampm = 'AM';
  if (hours >= 12) {
    hours -= 12;
    ampm = 'PM';
  }

  if (hours === 0) {
    hours = 12
  }

  let f = (d.getMonth() + 1) + "/" + d.getDate().toString().padStart(2, '0') + "/" + d.getFullYear() + " " + hours + ":" + d.getMinutes().toString().padStart(2, '0') + " " + ampm
  return f
}

export const formatPrettyDate = (date) => {
  let d = new Date(date)
  let hours = d.getHours()
  let ampm = 'AM';
  if (hours >= 12) {
    hours -= 12;
    ampm = 'PM';
  }

  if (hours === 0) {
    hours = 12
  }

  let f = (d.getMonth() + 1) + "/" + d.getDate().toString().padStart(2, '0') + "/" + d.getFullYear()
  return f
}

export const formatPrettyDateMonth = (date) => {
  if(!date) return '';
  let d = new Date(date)
  if(Date.parse(d) == NaN) return ''

  let f = d.getDate().toString().padStart(2, '0') + ' ' + months[(d.getMonth())].substring(0, 3) + " " + d.getFullYear()
  return f
}


export const formatByTimezone = (tz, time) => {
  if (tz !== null) {
    let j = new Date(time)
    let d = new Date(j.getFullYear(), j.getMonth(), j.getDate(), j.getHours(), j.getMinutes())
    return d.toLocaleString('en-US', { timeZone: tz, day: '2-digit', month: '2-digit', year: '2-digit', hour: 'numeric', minute: '2-digit', timeZoneName: 'short' })
  } else {
    return formatTimestamp(time)
  }
}

export const isDateString = (date) => {
  if(isNaN(Date.parse(date))) return false;
  return true
}