import { http } from './api';

const AUTH = '/auth';
const LOGIN = AUTH + '/login'
const LOGOUT = AUTH + '/logout'

export function authenticate() {
    return http.post(AUTH)
}

export function login(email, password) {
    return http.post(LOGIN, { email, password })
}

export function logout() {
    return http.post(LOGOUT)
}