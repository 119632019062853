import { http, serialize } from './api';

const FORM = '/form';
const REVIEW = FORM + '/review';
const REVIEW_SIGNATORY = FORM + '/review/signatory';
const EXPORT_BULK = FORM + '/export-bulk'

export function find(query) {
    const q = serialize(query)

    return http.get(FORM + '?' + q)
}

export function get(formId) {
    return http.get(FORM + '/' + formId)
}

export function update(formId, form) {
    const data = {
        form
    }
    return http.put(FORM + '/' + formId, data)
}

export function getReview(clientId, formType) {
    return http.get(REVIEW + '/' + clientId + '/' + formType)
}

export function review(client_id, form_id, form_type, status, signature) {
    const data = {
        client_id,
        form_id,
        form_type,
        status,
        signature
    }
    return http.post(REVIEW, data)
}

export function reviewSignatory(client_id, signature, is_pep) {
    const data = {
        client_id,
        signature,
        is_pep
    }
    return http.post(REVIEW_SIGNATORY, data)
}

export function exportBulk(clientIds, type) {
    const data = {
        clientIds, 
        type
    }
    return http.post(EXPORT_BULK, data)
}