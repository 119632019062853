import React, { useContext, useEffect, useState } from "react";
import { Input } from "baseui/input";
import { Button } from "baseui/button";
import FileInput from "./inputs/FileInput";
// import { upload } from "../api/aws";
import { label, inputOverrides, selectOverrides, dateOverrides, phoneOverrides, grey1, blue } from "../data/styles"
import { css } from "@emotion/css";
import { Datepicker } from "baseui/datepicker";
import { upload } from "../api/aws";
import LanguageContext from "../context/LanguageContext";
import { Spinner } from "baseui/spinner";
import Label from "./Label";
import CurrencyInput from "react-currency-input-field";
import { Select } from "baseui/select";

export const CountryActivityTable = ({ value, onChange }) => {
  const { language, isEnglish } = useContext(LanguageContext)

  return (
    <div className={styles.table}>
      {value.map((v, i) => (
        <div key={i} style={{ backgroundColor: "rgb(240,240,240)", padding: 12, borderRadius: 2, fontFamily: 'Outfit' }}>
          <div style={{ paddingBottom: 16, display: 'flex', justifyContent: 'space-around', fontFamily: 'Outfit' }}>
            {i === 0 && <p class='label'>Countries you deal with</p>}
            {i === 0 && <p class='label'>Nature of activity</p>}
          </div>
          <div style={{ display: "flex", flexDirection: 'row', marginBottom: 16 }}>
            <p style={{ fontSize: 20, marginRight: 16, padding: 8 }}>{i + 1}.</p>
            <div style={{ flex: 1, display: 'flex', gap: 8 }}>
              <div className={css`
                display: flex;
                flex: 1;
              `}>
                <Input
                  type='text'
                  key={i}
                  placeholder={"Country"}
                  value={v.country}
                  onChange={(e) => {
                    const arr = value;
                    arr[i].country = e.target.value;
                    onChange(arr);
                  }}
                  overrides={inputOverrides}
                />
              </div>
              <div className={css`
                display: flex;
                flex: 1;
              `}>
                <Input
                  type='text'
                  key={i}
                  placeholder={"Activity"}
                  value={v.activity}
                  onChange={(e) => {
                    const arr = value;
                    arr[i].activity = e.target.value;
                    onChange(arr);
                  }}
                  overrides={inputOverrides}
                />
              </div>
            </div>
          </div>
        </div>
      ))}
      <div style={{ marginTop: 16, display: "block" }}>
        <Button
          text='Add'
          onClick={() => {
            onChange([...value, { country: "", activity: "" }]);
          }}
        >
          Add
        </Button>
      </div>
    </div>
  );
};

export const PoliticalPersonsTable = ({ value, onChange }) => {
  return (
    <div class={styles.table}>
      {value.map((v, i) => (
        <div class={styles.row} style={{ backgroundColor: "rgb(240,240,240)", padding: 12, borderRadius: 2 }}>
          <p >{i + 1}.</p>
          <div className={styles.column}>
            <Input
              type='text'
              key={i}
              placeholder={"Name"}
              value={v.name}
              onChange={(e) => {
                const arr = value
                arr[i].name = e.target.value;
                onChange(arr)
              }}
              overrides={inputOverrides}
            />
          </div>
          <div className={styles.column}>
            <Input
              type='text'
              key={i}
              placeholder={"Relative Relation"}
              value={v.relation}
              onChange={(e) => {
                const arr = value
                arr[i].relation = e.target.value;
                onChange(arr)
              }}
              overrides={inputOverrides}
            />
          </div>
          <div className={styles.column}>
            <Input
              type='text'
              key={i}
              placeholder={"Job / Gov. Degree"}
              value={v.degree}
              onChange={(e) => {
                const arr = value
                arr[i].degree = e.target.value;
                onChange(arr)
              }}
              overrides={inputOverrides}
            />
          </div>
          <div className={styles.column}>
            <Input
              type='text'
              key={i}
              placeholder={"Employer's Name"}
              value={v.employer}
              onChange={(e) => {
                const arr = value
                arr[i].employer = e.target.value;
                onChange(arr)
              }}
              overrides={inputOverrides}
            />
          </div>
          <div class='flex flex-1'>
            <Input
              type='text'
              key={i}
              placeholder={"ID No."}
              value={v.id_no}
              onChange={(e) => {
                const arr = value
                arr[i].id_no = e.target.value;
                onChange(arr)
              }}
              overrides={inputOverrides}
            />
          </div>
        </div>
      ))}
      <div className={styles.addButton}>
        <Button
          text='Add'
          onClick={() => {
            onChange([...value, { country: "", activity: "" }]);
          }}
        >
          Add
        </Button>
      </div>
    </div>
  );
};

export const TaxResidenceTable = ({ value, onChange }) => {
  return (
    <div class={styles.table}>
      {value.map((v, i) => (
        <div class={styles.row} style={{ backgroundColor: "rgb(240,240,240)", padding: 12, borderRadius: 2 }}>
          <p >{i + 1}.</p>
          <div className={styles.column}>
            <Input
              type='text'
              key={i}
              placeholder={"Country/Jurisdiction"}
              value={v.country}
              onChange={(e) => {
                const arr = value;
                arr[i].country = e.target.value;
                onChange(arr);
              }}
              overrides={inputOverrides}
            />
          </div>
          <div className={styles.column}>
            <Input
              type='text'
              key={i}
              placeholder={"Taxpayer ID Number (TIN) or Equivalent"}
              value={v.tin}
              onChange={(e) => {
                const arr = value;
                arr[i].tin = e.target.value;
                onChange(arr);
              }}
              overrides={inputOverrides}
            />
          </div>
          <div className={styles.column}>
            <Input
              type='text'
              key={i}
              placeholder={"If no TIN, enter reason A, B, or C"}
              value={v.no_tin}
              onChange={(e) => {
                const arr = value;
                arr[i].no_tin = e.target.value;
                onChange(arr);
              }}
              overrides={inputOverrides}
            />
          </div>
        </div>
      ))}
      <div className={styles.addButton}>
        <Button
          text='Add'
          onClick={() => {
            onChange([...value, { country: "", tin: "", no_tin: "" }]);
          }}
        >
          Add
        </Button>
      </div>
    </div>
  );
};

export const ManagerExecutiveTable = ({ value, onChange, onBlur = () => { } }) => {

  return (
    <div class={styles.table}>

      {value.map((v, i) => (
        <div class={styles.row} style={{ backgroundColor: "rgb(240,240,240)", padding: 12, borderRadius: 2 }}>
          <p>{i + 1}.</p>
          <div className={styles.column}>
            <Input
              type='text'
              key={i}
              placeholder={"Name of Manager"}
              value={v.name}
              onChange={(e) => {
                const arr = value
                arr[i].name = e.target.value;

                onChange(arr)
              }}
              overrides={inputOverrides}
            />
          </div>
          <div className={styles.column}>
            <Datepicker
              value={Date.parse(v.expiry) ? new Date(v.expiry) : ''}
              key={i}
              onChange={({ date }) => {
                if (!date) return;
                console.log(date)
                const arr = value
                arr[i].expiry = date;
                onChange(arr)
              }}
            />
          </div>
          <div className={styles.column}>
            <Input
              type='text'
              key={i}
              placeholder={"ID Number"}
              value={v.id}
              onChange={(e) => {
                const arr = value
                arr[i].id = e.target.value;
                onChange(arr);
              }}
              overrides={inputOverrides}
            />
          </div>
        </div>
      ))}
      <div className={styles.addButton}>
        <Button
          text='Add'
          onClick={() => {
            onChange([...value, { name: "", expiry: "", id: "" }]);
          }}
        >
          Add
        </Button>
      </div>
    </div>
  );
};

export const OwnershipStructureTable = ({ value, onChange, onBlur = () => { } }) => {
  return (
    <div class={styles.table}>
      {value.map((v, i) => (
        <div className={styles.row} style={{ backgroundColor: "rgb(240,240,240)", padding: 12, borderRadius: 2 }}>
          <p >{i + 1}.</p>
          <div className={styles.column}>
            <Input
              onBlur={onBlur}
              type='text'
              key={i}
              placeholder={"Name of Owner"}
              value={v.name}
              onChange={(e) => {
                const arr = value;
                arr[i].name = e.target.value;
                onChange(arr);
              }}
              overrides={inputOverrides}
            />
          </div>
          <div className={styles.column}>
            <Input
              onBlur={onBlur}
              type='number'
              key={i}
              placeholder={"Ownership Percentage"}
              value={v.perc}
              onChange={(e) => {
                const arr = value;
                arr[i].perc = e.target.value;
                onChange(arr);
              }}
              overrides={inputOverrides}
            />
          </div>
          <div className={styles.column}>
            <Datepicker
              value={Date.parse(v.expiry) ? new Date(v.expiry) : ''}
              key={i}
              onChange={({ date }) => {
                if (!date) return;
                console.log(date)
                const arr = value
                arr[i].expiry = date;
                onChange(arr)
              }}
            />
          </div>
          <div className={styles.column}>
            <Input
              onBlur={onBlur}
              type='text'
              key={i}
              placeholder={"ID Number"}
              value={v.id}
              onChange={(e) => {
                const arr = value;
                arr[i].id = e.target.value;
                onChange(arr);
              }}
              overrides={inputOverrides}
            />
          </div>
        </div>
      ))}
      <div className={styles.addButton}>
        <Button
          text='Add'
          onClick={() => {
            onChange([...value, { name: "", perc: "", expiry: "", id: "" }]);
          }}
        >
          Add
        </Button>
      </div>
    </div>
  );
};

export const AmericanShareholderTable = ({ value, onChange, onBlur = () => { } }) => {
  return (
    <div className={styles.table}>
      {value.map((v, i) => (
        <div className={styles.row} style={{ backgroundColor: "rgb(240,240,240)", padding: 12, borderRadius: 2 }}>
          <p >{i + 1}.</p>
          <div className={styles.column}>
            <Input
              onBlur={onBlur}
              type='text'
              key={i}
              placeholder={"Name of Owner"}
              value={v.name}
              onChange={(e) => {
                const arr = value;
                arr[i].name = e.target.value;
                onChange(arr);
              }}
              overrides={inputOverrides}
            />
          </div>
          <div className={styles.column}>
            <Input
              onBlur={onBlur}
              type='text'
              key={i}
              placeholder={"TIN Number"}
              value={v.tin}
              startEnhancer='#'
              onChange={(e) => {
                const arr = value;
                arr[i].tin = e.target.value;
                onChange(arr);
              }}
              overrides={inputOverrides}
            />
          </div>
          <div className={styles.column}>
            <Input
              onBlur={onBlur}
              type='number'
              key={i}
              placeholder={"Percentage"}
              value={v.perc}
              endEnhancer='%'
              onChange={(e) => {
                const arr = value;
                arr[i].perc = e.target.value;
                onChange(arr);
              }}
              overrides={inputOverrides}
            />

          </div>
        </div>
      ))}
      <div className={styles.addButton}>
        <Button
          text='Add'
          onClick={() => {
            onChange([...value, { name: "", tin: "", perc: "" }]);
          }}
        >
          Add
        </Button>
      </div>
    </div>
  );
};

export const UltimateOwnerTable = ({ value, onChange, onBlur = () => { } }) => {
  return (
    <div className={styles.table}>
      {value.map((v, i) => (
        <div className={styles.row} style={{ backgroundColor: "rgb(240,240,240)", padding: 12, borderRadius: 2 }}>
          <p>{i + 1}.</p>
          <div className={styles.column}>
            <Input
              onBlur={onBlur}
              type='text'
              key={i}
              placeholder={"Name of Owner"}
              value={v.name}
              onChange={(e) => {
                const arr = value;
                arr[i].name = e.target.value;
                onChange(arr);
              }}
              overrides={inputOverrides}
            />
          </div>
          <div className={styles.column}>
            <Datepicker
              value={Date.parse(v.expiry) ? new Date(v.expiry) : ''}
              key={i}
              onChange={({ date }) => {
                if (!date) return;
                console.log(date)
                const arr = value
                arr[i].expiry = date;
                onChange(arr)
              }}
            />
          </div>
          <div className={styles.column}>
            <Input
              onBlur={onBlur}
              type='text'
              key={i}
              placeholder={"ID Number"}
              value={v.id}
              onChange={(e) => {
                const arr = value;
                arr[i].id = e.target.value;
                onChange(arr);
              }}
              overrides={inputOverrides}
            />
          </div>
        </div>
      ))}
      <div className={styles.addButton}>
        <Button
          text='Add'
          onClick={() => {
            onChange([...value, { name: "", expiry: "", id: "" }]);
          }}
        >
          Add
        </Button>
      </div>
    </div>
  );
};

export const AuthorizedSignatoriesTable = ({ value, onChange, onBlur = () => { } }) => {
  return (
    <div className={styles.table}>
      {value.map((v, i) => (
        <div className={styles.row} style={{ backgroundColor: "rgb(240,240,240)", padding: 12, borderRadius: 2 }}>
          <p >{i + 1}.</p>
          <div className={styles.column}>
            <Input
              onBlur={onBlur}
              type='text'
              key={i}
              placeholder={"Name of Owner"}
              value={v.name}
              onChange={(e) => {
                const arr = value;
                arr[i].name = e.target.value;
                onChange(arr);
              }}
              overrides={inputOverrides}
            />
          </div>
          <div className={styles.column}>
            <Input
              onBlur={onBlur}
              type='text'
              key={i}
              placeholder={"Title"}
              value={v.title}
              onChange={(e) => {
                const arr = value;
                arr[i].title = e.target.value;
                onChange(arr);
              }}
              overrides={inputOverrides}
            />
          </div>
          <div className={styles.column}>
            <Datepicker
              value={Date.parse(v.expiry) ? new Date(v.expiry) : ''}
              key={i}
              onChange={({ date }) => {
                if (!date) return;
                console.log(date)
                const arr = value
                arr[i].expiry = date;
                onChange(arr)
              }}
            />
          </div>
          <div className={styles.column}>
            <Input
              onBlur={onBlur}
              type='text'
              key={i}
              placeholder={"ID Number"}
              value={v.id}
              onChange={(e) => {
                const arr = value;
                arr[i].id = e.target.value;
                onChange(arr);
              }}
              overrides={inputOverrides}
            />
          </div>
        </div>
      ))}
      <div className={styles.addButton}>
        <Button
          text='Add'
          onClick={() => {
            onChange([...value, { name: "", title: "", expiry: "", id: "" }]);
          }}
        >
          Add
        </Button>
      </div>
    </div>
  );
};

export const DirectorsTable = ({ value, onChange, clientId, onBlur = () => { } }) => {
  const [loadingIndex, setLoadingIndex] = useState(-1)

  async function onUploadFile(index, file) {
    setLoadingIndex(index)
    console.log(file, clientId)
    try {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("client_id", clientId);
      console.log('FORM DATA MADE', formData)
      // COMBAK removed bc we didnt have AWS in at the time
      const data = await upload(formData);
      console.log('ID', data);

      const arr = value;
      arr[index].id_url = data.data
      onChange(arr)
      onBlur()
      setLoadingIndex(-1)
    } catch (e) {
      console.log(e);
      setLoadingIndex(-1)
    }
  };
  return (
    <div className={styles.table}>
      <div className={css`
        display: flex;
        flex-direction: row;
        justify-content: space-around;
      `}>
        <p className="label">First Name</p>
        <p className="label">Father Name</p>
        <p className="label">Grandfather Name</p>
        <p className="label">Last Name</p>
        <p className="label">Picture of ID</p>
      </div>
      {value.map((v, i) => (
        <div className={styles.row} style={{ backgroundColor: "rgb(240,240,240)", padding: 12, borderRadius: 2 }} key={i}>
          <p >{i + 1}.</p>
          <div className={styles.column}>
            <Input
              onBlur={onBlur}
              type='text'
              key={i}
              placeholder={"First Name"}
              value={v.first_name}
              onChange={(e) => {
                const arr = value;
                arr[i].first_name = e.target.value;
                onChange(arr);
              }}
              overrides={inputOverrides}
            />
          </div>
          <div className={styles.column}>
            <Input
              onBlur={onBlur}
              type='text'
              key={i}
              placeholder={"Father Name"}
              value={v.father_name}
              onChange={(e) => {
                const arr = value;
                arr[i].father_name = e.target.value;
                onChange(arr);
              }}
            />
          </div>
          <div className={styles.column}>
            <Input
              onBlur={onBlur}
              type='text'
              key={i}
              placeholder={"Grandfather Name"}
              value={v.grandfather_name}
              onChange={(e) => {
                const arr = value;
                arr[i].grandfather_name = e.target.value;
                onChange(arr);
              }}
              overrides={inputOverrides}
            />
          </div>
          <div className={styles.column}>
            <Input
              onBlur={onBlur}
              type='text'
              key={i}
              placeholder={"Last Name"}
              value={v.last_name}
              onChange={(e) => {
                const arr = value;
                arr[i].last_name = e.target.value;
                onChange(arr);
              }}
              overrides={inputOverrides}
            />
          </div>
          <div className={styles.column}>
            {loadingIndex === i ?
              <Spinner color={blue} />
              : <FileInput key={i} onSelect={(file) => onUploadFile(i, file)} value={v.id_url} showFilePreview={true} />
            }
          </div>
        </div>
      ))}
      <div className={styles.addButton}>
        <Button
          text='Add'
          onClick={() => {
            onChange([...value, { first_name: "", father_name: "", grandfather_name: "", last_name: "", id_url: "" }]);
          }}
        >
          Add
        </Button>
      </div>
    </div>
  );
};

export const FinancialDealingTable = ({ value, onChange }) => {
  const { language, isEnglish } = useContext(LanguageContext)

  return (
    <div className={styles.table}>
      {value.map((v, i) => (
        <div key={i} style={{ backgroundColor: "rgb(240,240,240)", padding: 12, borderRadius: 2, fontFamily: 'Outfit' }}>
          <div style={{ paddingBottom: 16, display: 'flex', justifyContent: 'space-around', fontFamily: 'Outfit' }}>
            {i === 0 && <p class='label'>Type of Dealings</p>}
            {i === 0 && <p class='label'>Investment House Name</p>}
          </div>
          <div style={{ display: "flex", flexDirection: 'row', marginBottom: 16 }}>
            <p style={{ fontSize: 20, marginRight: 16, padding: 8 }}>{i + 1}.</p>
            <div style={{ flex: 1, display: 'flex', gap: 8 }}>
              <div className={css`
                display: flex;
                flex: 1;
              `}>
                <Input
                  type='text'
                  key={i}
                  placeholder={"Type of Dealing"}
                  value={v.type}
                  onChange={(e) => {
                    const arr = value;
                    arr[i].type = e.target.value;
                    onChange(arr);
                  }}
                  overrides={inputOverrides}
                />
              </div>
              <div className={css`
                display: flex;
                flex: 1;
              `}>
                <Input
                  type='text'
                  key={i}
                  placeholder={"Investment House Name"}
                  value={v.name}
                  onChange={(e) => {
                    const arr = value;
                    arr[i].name = e.target.value;
                    onChange(arr);
                  }}
                  overrides={inputOverrides}
                />
              </div>
            </div>
          </div>
        </div>
      ))}
      <div style={{ marginTop: 16, display: "block" }}>
        <Button
          text='Add'
          onClick={() => {
            onChange([...value, { type: "", name: "" }]);
          }}
        >
          Add
        </Button>
      </div>
    </div>
  );
};

export const ConsignmentCertificates = ({ value, onChange }) => {
  const { language, isEnglish } = useContext(LanguageContext)

  return (
    <div className={styles.table}>
      {value.map((v, i) => (
        <div key={i} style={{ backgroundColor: "rgb(240,240,240)", padding: 12, borderRadius: 2, fontFamily: 'Outfit' }}>
          <div style={{ paddingBottom: 16, display: 'flex', justifyContent: 'space-around', fontFamily: 'Outfit' }}>
            {i === 0 && <p class='label'>Where do you want to send?</p>}
            {i === 0 && <p class='label'>Custodian</p>}
            {i === 0 && <p class='label'>Customer</p>}
            {i === 0 && <p class='label'>Other: Customer Instructions</p>}
          </div>
          <div style={{ display: "flex", flexDirection: 'row', marginBottom: 16 }}>
            <p style={{ fontSize: 20, marginRight: 16, padding: 8 }}>{i + 1}.</p>
            <div style={{ flex: 1, display: 'flex', gap: 8 }}>
              <div className={css`
                display: flex;
                flex: 1;
              `}>
                <Input
                  type='text'
                  key={i}
                  placeholder={''}
                  value={v.type}
                  disabled={true}

                  overrides={inputOverrides}
                />
              </div>

              <div className={css`
                display: flex;
                flex: 1;
              `}>
                <Input
                  type='text'
                  key={i}
                  placeholder={"Custodian"}
                  value={v.custodian}
                  onChange={(e) => {
                    const arr = value;
                    arr[i].custodian = e.target.value;
                    onChange(arr);
                  }}
                  overrides={inputOverrides}
                />
              </div>
              <div className={css`
                display: flex;
                flex: 1;
              `}>
                <Input
                  type='text'
                  key={i}
                  placeholder={"Customer"}
                  value={v.customer}
                  onChange={(e) => {
                    const arr = value;
                    arr[i].customer = e.target.value;
                    onChange(arr);
                  }}
                  overrides={inputOverrides}
                />
              </div>
              <div className={css`
                display: flex;
                flex: 1;
              `}>
                <Input
                  type='text'
                  key={i}
                  placeholder={"Other"}
                  value={v.other}
                  onChange={(e) => {
                    const arr = value;
                    arr[i].other = e.target.value;
                    onChange(arr);
                  }}
                  overrides={inputOverrides}
                />
              </div>

            </div>
          </div>
        </div>
      ))}
      {/* <div style={{ marginTop: 16, display: "block" }}>
        <Button
          text='Add'
          onClick={() => {
            onChange([...value, { type: "", name: "" }]);
          }}
        >
          Add
        </Button>
      </div> */}
    </div>
  );
};

export const ExpectedFinancialTransactions = ({ value, onChange }) => {
  const { language, isEnglish } = useContext(LanguageContext)
  console.log('in here------')
  return (
    <div className={styles.table}>
      {value.map((v, i) => (
        <div key={i} style={{ backgroundColor: "rgb(240,240,240)", padding: 12, borderRadius: 2, fontFamily: 'Outfit' }}>
          <div style={{ paddingBottom: 16, display: 'flex', justifyContent: 'space-around', fontFamily: 'Outfit' }}>
            {i === 0 && <p class='label'>Expected Number of Tx's</p>}
            {i === 0 && <p class='label'>Amount of Monthly Tx's (SAR)</p>}
          </div>
          {i != value.length - 1 &&
            <div style={{ display: "flex", flexDirection: 'row', marginBottom: 16 }}>
              <p style={{ fontSize: 20, marginRight: 16, padding: 8 }}>{i + 1}.</p>
              <div style={{ flex: 1, display: 'flex', gap: 8 }}>
                <div className={css`
                display: flex;
                flex: 1;
              `}>
                  <Input
                    type='text'
                    key={i}
                    placeholder={'Type'}
                    value={v.type}
                    disabled={true}

                    overrides={inputOverrides}
                  />
                </div>
                <div className={css`
                display: flex;
                flex: 1;
              `}>
                  <Input
                    type='text'
                    key={i}
                    placeholder={"Num # Sells"}
                    value={v.sell_num}
                    onChange={(e) => {
                      const arr = value;
                      arr[i].sell_num = e.target.value;
                      onChange(arr);
                    }}
                    overrides={inputOverrides}
                  />
                </div>

                <div className={css`
                display: flex;
                flex: 1;
              `}>
                  <Input
                    type='text'
                    key={i}
                    placeholder={"Num # Buys"}
                    value={v.buy_num}
                    onChange={(e) => {
                      const arr = value;
                      arr[i].buy_num = e.target.value;
                      onChange(arr);
                    }}
                    overrides={inputOverrides}
                  />
                </div>
                <div className={css`
                display: flex;
                flex: 1;
              `}>
                  <CurrencyInput
                    style={{ height: 48, paddingLeft: 12, fontSize: 16, boxShadow: "none", width: "100%", backgroundColor: "rgb(238,238,238)", borderColor: 'none' }}
                    placeholder="Amount Sells SAR"
                    prefix="SAR "
                    decimalsLimit={0}
                    value={v.sell_amt}
                    onValueChange={(curr) => {
                      const arr = value;
                      arr[i].sell_amt = curr;
                      onChange(arr);
                    }}
                  />
                </div>
                <div className={css`
                display: flex;
                flex: 1;
              `}>
                  <CurrencyInput
                    style={{ height: 48, paddingLeft: 12, fontSize: 16, boxShadow: "none", width: "100%", backgroundColor: "rgb(238,238,238)", borderColor: 'none' }}
                    placeholder="Amount Buys SAR"
                    prefix="SAR "
                    decimalsLimit={0}
                    value={v.buy_amt}
                    onValueChange={(curr) => {
                      const arr = value;
                      arr[i].buy_amt = curr;
                      onChange(arr);
                    }}
                  />
                </div>

              </div>
            </div>
          }

        </div>
      ))}
      {/* <div style={{ marginTop: 16, display: "block" }}>
        <Button
          text='Add'
          onClick={() => {
            onChange([...value, { type: "", name: "" }]);
          }}
        >
          Add
        </Button>
      </div> */}
    </div>
  );
};

export const IdentificationCriteria = ({ value: _value = [], onChange }) => {
  const { language, isEnglish } = useContext(LanguageContext)
  const [value, setValue] = useState(_value.map(v => ({ label: v, value: v })));

  useEffect(() => {
    onChange(value.map(v => v.value))
  }, [value?.length])

  return (
    <Select options={[
      { label: "High Risk", value: "High Risk" },
      { label: "Medium Risk", value: "Medium Risk" },
      { label: "Low Risk", value: "Low Risk" },
      { label: "Politically Exposed Person (PEP)", value: "Politically Exposed Person (PEP)" },
      { label: "Other", value: "Other" }
    ]}
      labelKey='label'
      valueKey="value"
      multi={true}
      onChange={({ value }) => setValue(value)}
      value={value}
    />
  )
}

const styles = {
  table: css`
    display: flex;
    flex-direction: column;
  `,
  row: css`
    display: flex;
    flex-direction: row;
    margin-bottom: 4px;
    background-color: green;
    gap: 8px;
    
    p {
      display: flex;
      align-self: center;
      margin-right: 4px; 
      font-size: 1rem;
      font-family: 'Outfit';
    }

    @media (max-width: 720px) {
      flex-direction: column;
    }
  `,
  column: css`
    display: flex;
    flex: 1;
  `,
  addButton: css`
    display: flex;
    margin-top: 8px;
    justify-content: flex-end;
  `
}