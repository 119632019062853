import React from 'react';
import { css } from '@emotion/css'

export default function Modal({ zIndex = 0, height, width, close, ...rest }) {
	return (

		<div>
			<div className={css`
	              position: absolute;
				//   height: 100vh;
				//   width: 100vw;
				  top: 0;
				  bottom: 0;
				  left: 0; right: 0;
				  background-color: rgba(0,0,0,0.5);
	            `}

			>
			</div>
			<div className={css`
	              position: fixed;
				  display: block;
				  top: -76px;
				  right: 0;
				  left: 0;
				  height: calc(100vh + 76px);
				  width: 100vw;
	            `} >


				<div className={css`
	              	min-height: calc(100% + 46px);
				    display: flex;
				    align-items: center;
				    justify-content: center;
				    position: relative;
				    pointer-events: none;
	            `}>
					<div className={css`
	              		border-radius: 4px;
				      	background-color: #fff;
				      	overflow: hidden;
				      	position: relative;
				      	pointer-events: all;
				      	padding: 24px;

				        `} style={{ height: height, width: width }}>
						<div
							className={css`
				              		&:hover {
				              			cursor: pointer;
				              		}
						        `}
							style={{  position: "absolute", right: 16, top: 16, textAlign: "right", cursor: 'pointer', zIndex: 4 }} >

							<div style={{ cursor: 'pointer' }} onClick={close}  >
								<svg onClick={close} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
							</div>
						</div>

						{rest.children}
					</div>
				</div>
			</div>


		</div>
	)
}