import { http } from './api';

const USER = '/user';
const CLIENT = (userId) => `${USER}/${userId}/client`
const SET_ACTIVE_CLIENT = USER + '/active-client'

export function get(userId) {
    return http.get(USER + '/' + userId)
}

export function create(user) {
    return http.post(USER, user)
}

export function update(userId, user) {
    return http.put(USER + '/' + userId, { ...user })
}

export function setActiveClient(clientId) {
    return http.post(SET_ACTIVE_CLIENT, { clientId })
}

export function getClients(userId) {
    return http.get(CLIENT(userId))
}
