import React, { useEffect, useState } from 'react';
import { ClientApi } from '../api';

const useUsersOfClient = (clientId, rerender=0) => {
    const [status, setStatus] = useState('loading')
    const [users, setUsers] = useState([])

    useEffect(() => {
        const getUsers = async() => {
            if(!clientId) return;
            setStatus('loading')

            try {
                const { data: users } = await ClientApi.getUsers(clientId)
                setUsers(users)
                setStatus('success')
            } catch(e) {
                setStatus('error')
            }

        }
        getUsers()
    }, [clientId, rerender])

    return { status, users }
}

export { useUsersOfClient }