import { http } from './api';

const CAPITAL_CALL = (fundId) => `/fund/${fundId}/capital-call`
const CAPITAL_CALL_STATUS = (fundId) => `/fund/${fundId}/capital-call/status`
const CAPITAL_CALL_ACTIVE = (fundId) => `/fund/${fundId}/capital-call/active`
const CAPITAL_CALL_TOTAL = (fundId) => `/fund/${fundId}/capital-call/total`
const CAPITAL_CALL_CLIENTS = (fundId, capitalCallId) => `/fund/${fundId}/capital-call/${capitalCallId}/clients`
const UPDATE_CLIENT_STATUS = (fundId, capitalCallId) => `/fund/${fundId}/capital-call/${capitalCallId}/status`

export function getAll(fundId) {
    // COMBAK this will come from a session in the server
    return http.get(CAPITAL_CALL(fundId))
}

export function get(fundId, capitalCallId) {
    return http.get(CAPITAL_CALL(fundId) + '/' + capitalCallId)
}

export function create(fundId, capitalCall) {
    // COMBAK this will come from a session in the server
    return http.post(CAPITAL_CALL(fundId), { capitalCall })
}

export function getClients(fundId, capitalCallId) {
    return http.get(CAPITAL_CALL_CLIENTS(fundId, capitalCallId))
}

// export function getStatus(fundId) {
//     return http.get(CAPITAL_CALL_STATUS(fundId))
// }

export function getActive(fundId) {
    return http.get(CAPITAL_CALL_ACTIVE(fundId))
}

export function getTotalCalled(fundId) {
    return http.get(CAPITAL_CALL_TOTAL(fundId))
}

export function getTotalCalledForSingle(fundId, capitalCallId) {
    return http.get(CAPITAL_CALL_TOTAL(fundId) + '/' + capitalCallId)
}

export function updateClientStatus(fundId, capitalCallId, data) {
    return http.put(UPDATE_CLIENT_STATUS(fundId, capitalCallId), data)
}