import { css } from '@emotion/css'

// export const blue = "#5ebf99"
export const blue = "#5D2DF5"
export const black = "#030303"

export const grey1 = "#969696"

export const button = css` 
			font-optical-sizing: auto;
		    font-family: "Outfit";
		    font-style: normal;
		    font-stretch: normal;
		    line-height: initial;
		    font-size: 15px;
		    background-color: ${blue};
		    border: none;
		    padding: 12px 36px;
		    border-radius: 4px;
		    color: #fff;
		    &:hover{
              cursor: pointer;
            }
            &:disabled{
            	pointer-events: none;
            	opacity: 0.5;
            }
		`

export const label = css` 
			font-optical-sizing: auto;
		    font-family: "Outfit";
		    margin-bottom: 8px;
		    font-style: normal;
		    font-stretch: normal;
		    line-height: initial;
		    font-size: 16px;
		    position: relative;
		    display: block;
		`
export const inputOverrides = {
	Root: {
		style: {
			outline: "none",
			border: "none",
			borderRadius: "6px",
			// border: "1px solid #969696",
			':focus': {
				border: "1px solid " + blue,
			},
		}
	},
	Input: {
		style: ({ $theme }) => ({
			...$theme,
			outline: "none",
			border: "none",
			backgroundColor: "#fff",
			fontOpticalSizing: "auto",
			fontFamily: "Outfit",
			fontStyle: "normal",
			fontStretch: "normal",
			lineHeight: "initial",
			boxSizing: "border-box",
			padding: "12px",
			borderRadius: "6px",
			':focus': {
				border: "1px solid " + blue,
			},
		})
	},
}

export const selectOverrides = {
	Root: {
		style: ({ $theme }) => ({
			outline: `blue solid`,
			backgroundColor: "blue"
		})
	},
	InputContainer: {
		style: ({ $theme }) => ({
			outline: `blue solid`,
			backgroundColor: "blue"
		})
	}

}

export const dateOverrides = {
	InputContainer: {
		style: {
			borderRadius: "0"
		}
	},
	Root: {
		style: {
			borderRadius: "0"
		}
	},
	Input: {
		style: {
			outline: "none",
			borderRadius: "none",
		},
		props: {
			overrides: {
				Root: {
					style: {
						backgroundColor: "#fff",
						border: "none",
						borderRadius: "0"
					}
				},
				InputContainer: {
					style: {
						backgroundColor: "#fff",
						border: "none",
						borderRadius: "none"
					}
				},
				Input: {
					style: {
						outline: "none",
						backgroundColor: "#fff",
						fontOpticalSizing: "auto",
						fontFamily: "Outfit",
						fontStyle: "normal",
						fontStretch: "normal",
						lineHeight: "initial",
						boxSizing: "border-box",
						padding: "16px",
						borderRadius: "6px",
						border: "1px solid #969696",
						':focus': {
							border: "1px solid " + blue,
						},
					}
				}
			}
		}
	},

}


export const phoneOverrides = {
	CountrySelect: {

		props: {
			overrides: {
				Root: {
					style: {
						lineHeight: "29px",
					}
				},
				Input: {

					border: "1px solid #969696",
					':focus': {
						border: "1px solid " + blue,
					},
				},
				ControlContainer: {
					style: {
						outline: "1px solid #969696",
						backgroundColor: "#fff"
					}
				},
			}
		}
	},

	Input: {
		style: {

			border: "1px solid #969696",
			':focus': {
				border: "1px solid " + blue,
			},
		},
		props: {
			overrides: {
				Root: {
					style: {
						backgroundColor: "#fff",
						border: "none",
						borderRadius: "0",
						outline: "1px solid #969696",
						borderRadius: "6px",
					}
				},
				InputContainer: {
					style: {
						backgroundColor: "#fff",
						border: "none",
						borderRadius: "none"
					}
				},
				Input: {
					style: {
						outline: "none",
						backgroundColor: "#fff",
						fontOpticalSizing: "auto",
						fontFamily: "Outfit",
						fontStyle: "normal",
						fontStretch: "normal",
						lineHeight: "initial",
						boxSizing: "border-box",
						padding: "16px",
						borderRadius: "6px",

					}
				}
			}
		}
	},

}
