import React, { useEffect, useState } from 'react';
import { AwsApi, CapitalCallApi } from '../api';

const usePreviewUrl = (clientId, id, type) => {
    const [status, setStatus] = useState('loading')
    const [url, setPreviewUrl] = useState(null)

    useEffect(() => {
        const getPreview = async() => {
            if(!clientId || !id || !type) return;
            setStatus('loading')

            try {
                const { data: url } = await AwsApi.getPreview(clientId, id, type)

                setPreviewUrl(url.url)
                setStatus('success')
              
                } catch (e) {
                setStatus('error')
                console.log(e)
            }

        }
        getPreview()
    }, [clientId, id, type])

    return { status, url }
}

export { usePreviewUrl }