import React, { Component } from "react"
import PropTypes from "prop-types"
import { css } from '@emotion/css'
import { button } from "../../data/styles"

import { trigger } from "../../events/events"
import { getOverallProgress, getTotalCompleteAndIncomplete, subsectionHasIncompleteFields } from "../../util/form"

import SubSection from "./SubSection"
import ProgressBar from "../ProgressBar"

class Section extends Component {
  static propTypes = {
    section: PropTypes.objectOf({
      title: PropTypes.string,
      subsections: PropTypes.array,
    }),
    onPrevSection: PropTypes.func,
    onNextSection: PropTypes.func,
  }

  constructor(props) {
    super(props)

    this.state = {
      hasIncompleteFields: true,
    }
  }

  componentDidMount() { }

  onPrev() {
    trigger("form:onPrevSubsection")
  }

  onNext = () => {
    console.log('onnext section')
    const { section, subsectionIndex } = this.props

    // Check for errors first
    const hasIncompleteFields = subsectionHasIncompleteFields(section.subsections[subsectionIndex], this.props.form)
    this.setState({ hasIncompleteFields })
    // if (hasIncompleteFields) {
    //   console.log(hasIncompleteFields)
    //   return this.setState({ hasIncompleteFields: true })
    // }
    this.setState({ hasIncompleteFields: false })
    trigger("form:onNextSubsection")
  }

  onInputField = (field, value, type = null) => {
    const { section, subsectionIndex, onInputField } = this.props
    if (field == null) return alert('field id is null')
    const fieldIndex = section.subsections[subsectionIndex].fields.findIndex((f) => f.id == field)

    if (fieldIndex === -1) return alert("no field index")

    // NOTE if updating a phone's country code, pass in type=phone and it will update country on the phone field
    if (type == "phone") {
      section.subsections[subsectionIndex].fields[fieldIndex] = {
        ...section.subsections[subsectionIndex].fields[fieldIndex],
        country: value,
      }
    } else {
      section.subsections[subsectionIndex].fields[fieldIndex] = {
        ...section.subsections[subsectionIndex].fields[fieldIndex],
        value,
      }
    }

    this.setState({ wasChangeMade: true })
    onInputField(section)
  }

  onAutofill = () => {
    const { section, subsectionIndex, onInputField } = this.props
    for (let i = 0; i < section.subsections[subsectionIndex].fields.length; i++) {
      if (section.subsections[subsectionIndex].fields[i].value == null && section.subsections[subsectionIndex].fields[i].d != null) {
        section.subsections[subsectionIndex].fields[i].value = section.subsections[subsectionIndex].fields[i].d
        // onInputField(section)
      }
    }
    this.setState({ wasChangeMade: true })
    onInputField(section)
    setTimeout(() => {
      this.onNext()
    }, 500)
  }

  

  render() {
    // form is only passed so it can be used to check for incomplete fields
    const { section, subsectionIndex, client, canFieldBeRendered, form } = this.props
    const { hasIncompleteFields } = this.state;

    const formStatus = getTotalCompleteAndIncomplete(form)

    return (
      <div>
        {/* <p class="text-3xl font-bold mb-8">{section.title}</p> */}

        {/*<div class="flex flex-end justify-end mr-8">
          <p onClick={this.onAutofill} class="text-md font-bold" style={{ color: "rgba(140,140,140,0.5)", borderBottom: "2px solid", cursor: "pointer" }}>
            AUTOFILL
          </p>
        </div>*/}

        <div style={{ maxWidth: 800, margin: "0 auto", padding: 16, paddingBottom: 128, paddingTop: 48, }}>
          <SubSection __form={this.props.form} subsection={section.subsections[subsectionIndex]} onInputField={this.onInputField} client={client} canFieldBeRendered={canFieldBeRendered} hasIncompleteFields={hasIncompleteFields} />
        </div>

        <div style={{ position: "fixed", width: "100%", bottom: 0, backgroundColor: "#fff", boxShadow: "2px -4px 7px rgb(0 0 0 / 10%)", border: "1px solid #DFDFDF" }}>
          <div style={{ padding: 16, display: "flex", justifyContent: "space-between", alignItems: 'center' }}>
            <div style={{display: 'flex', flex: 1, maxWidth: '50%', flexDirection: 'column'}}>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', paddingBottom: 8, fontFamily: 'Outfit'}}>
                <p>{formStatus?.completed}/{formStatus?.total}</p>
                <p>{getOverallProgress(form)}%</p>
              </div>
              <ProgressBar total={100} progress={getOverallProgress(form)} />
            </div>
            
            <div style={{marginRight: 8}}>
            <button className={button} onClick={() => {
              const hasIncompleteFields = subsectionHasIncompleteFields(section.subsections[subsectionIndex], this.props.form)
              this.setState({ hasIncompleteFields })
              this.props.onNextMissingField()
            }}>Take me To Missing Field</button>
            </div>
            {subsectionIndex !== 0 && (
              <div style={{ marginRight: 8 }}>
                <button
                  onClick={this.onPrev}
                  className={button}
                  style={{ backgroundColor: "rgb(235, 235, 235)", color: "#000" }}
                >
                  Back
                </button>
              </div>
            )}
            <button
              className={button}
              onClick={this.onNext}>
              Next
            </button>
          </div>
        </div>

      </div>
    )
  }
}

export default Section
