import { useState, useContext, useEffect } from "react"
import { css } from "@emotion/css"
import { Input } from "baseui/input"
import AuthContext from "../../context/AuthContext"
import { Button } from "../../elements/Button"
import { UserApi } from "../../api"

const Profile = () => {
    const { user: _user } = useContext(AuthContext)
    const [user, setUser] = useState()
    const [hasChanges, setHasChanges] = useState(false)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)

    useEffect(() => {
        if (_user?._id != user?._id) {
            setUser(_user)
        }
    }, [_user?._id])

    useEffect(() => {
        if (user?.email != _user?.email || user?.name != _user?.name || user?.name_arabic != _user?.name_arabic)
            return setHasChanges(true)
        setHasChanges(false)
    }, [user?.name, user?.email, user?.name_arabic, user?.email])

    async function handleUpdateUser() {
        setLoading(true)
        const userId = _user._id;
        
        try {
            await UserApi.update(userId, user)
            setLoading(false)
        } catch(e) {
            console.log(e)
            setError('Cannot update your account at this time')
            setLoading(false)
        }
    }

    return (
        <div style={{ backgroundColor: "#F9F9F9", overflow: "hidden", minHeight: '90vh' }}>
            <div onClick={() => window.location.pathname = '/'} style={{position: 'absolute', left: 16, top: 64, backgroundColor: 'rgb(234,234,234)', padding: 8, paddingLeft: 24, paddingRight: 24, borderRadius: 4, fontFamily: "Outfit", cursor: 'pointer'}}>
                <p>Back</p>
            </div>
            <div className={css`
                display: flex;
                flex: 1;
                flex-direction: column;
                // background-color: blue;
                min-height: 92vh;
                max-width: 1100px;
                margin-left: auto;
                margin-right: auto;
                padding-top: 32px;
                font-family: 'Outfit';
            `}>

                <h3 className={css`
                    font-size: 2rem;
                    font-weight: 700;
                `}>{user?.name}</h3>

                <div className={css`
                        display: flex;
                        flex: 1;
                        flex-direction: column;
                        justify-content: space-evenly;
                        gap: 12px;
                        margin-top: 32px;
                    `}>
                    <div >
                        <label>Name</label>
                        <div style={{ height: 8 }} />
                        <Input
                            placeholder="Name"
                            onChange={(e) => setUser({ ...user, name: e.target.value })}
                            value={user?.name}
                        />
                    </div>
                    <div >
                        <label>Arabic Name</label>
                        <div style={{ height: 8 }} />
                        <Input
                            placeholder="Arabic Name"
                            onChange={(e) => setUser({ ...user, name_arabic: e.target.value })}
                            value={user?.name_arabic}
                        />
                    </div>

                    <div >
                        <label>Email</label>
                        <div style={{ height: 8 }} />
                        <Input
                            placeholder='Email'
                            onChange={(e) => setUser({ ...user, email: e.target.value })}
                            value={user?.email}
                        />
                    </div>
                </div>
                <div className={css`
                        display: flex;
                        flex: 1;
                        flex-direction: column;
                        justify-content: space-evenly;
                        gap: 12px;
                        margin-top: 32px;
                        `}>
                    <Button onClick={handleUpdateUser} loading={loading} disabled={!hasChanges} text='Submit Changes' />

                <p style={{color: 'red'}}>{error}</p>

                    <h3 className={css`
                    font-size: 2rem;
                    font-weight: 700;
                `}></h3>
                </div>
            </div>
        </div>
    )

}

export { Profile }