import React, { Component } from "react";
import PropTypes from "prop-types";
import { css } from '@emotion/css'
import { blue } from "../../data/styles"
import { withLanguageContext } from "../../context/LanguageProvider";
import { trans } from "../../translations";
// import * as Theme from "../util/theme";
// import { getFieldIndexes } from "../util/form";

const isMobile = window.innerWidth < 960;

const steps = {
  width: "100%",
  display: "block",
  textAlign: "center"
}

const step = {
  width: 160,
  display: "inline-block",
  float: "none",
  position: "relative"
}

class SideNavForm extends Component {
  static propTypes = {
    title: PropTypes.string,
    formId: PropTypes.string,
    sections: PropTypes.arrayOf({
      title: PropTypes.string,
    }),
    activeIndex: PropTypes.number,
    onSelect: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.state = {
      items: [],
      isMobileNavOpen: false,
    };
  }

  onSelect(index) {
    this.props.onSelect(index);
  }

  hasOustandingFields(sectionIndex) {
    const { sections } = this.props;

    function getFieldIndexesFromSection(id) {
      for (let j = 0; j < sections[sectionIndex].subsections.length; j++) {
        for (let k = 0; k < sections[sectionIndex].subsections[j].fields.length; k++) {
          if (sections[sectionIndex].subsections[j].fields[k].id == id) {
            return [sectionIndex, j, k];
          }
        }
      }
    }

    for (let i = 0; i < sections[sectionIndex].subsections.length; i++) {
      for (let j = 0; j < sections[sectionIndex].subsections[i].fields.length; j++) {
        if (sections[sectionIndex].subsections[i].fields[j].conditionRef != null && sections[sectionIndex].subsections[i].fields[j].conditionRef != "") {
          const indexes = getFieldIndexesFromSection(sections[sectionIndex].subsections[i].fields[j].conditionRef);
          const conditionalField = sections[sectionIndex].subsections[indexes[1]].fields[indexes[2]];

          // if it has a condition, see if that condition is met
          if (conditionalField.value == sections[sectionIndex].subsections[i].fields[j].condition) {
            // total++;
            if (sections[sectionIndex].subsections[i].fields[j].value == null && sections[sectionIndex].subsections[i].fields[j].value == "") {
              // completed++;
              // continue;
              return true
            }
            continue;
          }
          continue;
        }

        if (sections[sectionIndex]?.subsections[i]?.fields[j]?.value == null || sections[sectionIndex]?.subsections[i]?.fields[j]?.value == "") {
          // completed++;
          // continue;
          return true;
        }
      }
    }
  }

  getStyling() {

  }

  render() {
    // const { items } = this.state;
    const { title, subtitle, sections, backTo } = this.props;
    const { activeIndex, onBack } = this.props;
    const isEnglish = this.props.languageContext.isEnglish

    return (
      <div style={{ direction: isEnglish() ? 'ltr' : 'rtl' }}>
        <div style={{ padding: 16 }}>
          <button onClick={onBack} class="css-16zbx6q" style={{ backgroundColor: "rgb(235, 235, 235)", color: "rgb(0, 0, 0)" }}>{trans('Back to Dashboard', this.props.languageContext.language)}</button>
        </div>
        <div style={{ paddingTop: 8, marginBottom: 32 }}>

          {/*<a href={backTo ? backTo : "/dashboard"}>
            <div class='flex-col'>
              <h1 class='text-md font-bold' style={{ opacity: 0.5, padding: 0, margin: 0 }}>
                {subtitle}
              </h1>
              <h1 class='text-2xl font-extrabold' style={{ padding: 0, margin: 0 }}>
                {title}
              </h1>
            </div>
          </a>*/}

          <ul
            // style={steps}

            className={css`
              direction: ${isEnglish() ? 'ltr' : 'rtl'};
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: center;
            `}
          >
            {sections.map((item, i) => (
              <li
                onClick={() => this.onSelect(i)}
                key={i}
                style={{ width: 200,  textAlign: 'center', fontFamily: 'Outfit', cursor: 'pointer'}}
              >
                <p style={{ textDecoration: i === activeIndex ? 'underline' : 'none', color:  blue, padding: '8px 4px', opacity: i === activeIndex ? 0.7 : 1}}>
                  {i+1}. {item.title}
                </p>
              </li>
            ))}
            {/* {sections?.map((item, i) => (
              <li
                onClick={() => this.onSelect(i)}
                // style={activeIndex === i ? { backgroundColor: 'green' } : null}
                key={i}
                style={step}
                className={(i !== sections.length - 1) ? css`
                
                      &:after{
                        position: absolute;
                        // left: 50%;
                        // left: ${isEnglish() ? '50%' : null};
                        // right: ${isEnglish() ? null : '50%'};
                        width: calc(70% + 40px);
                        content: '';
                        height: 4px;
                        background: ${(activeIndex >= i) ? blue : "#e6e6e6"};
                        margin: 0;
                        top: 44px;
                      }
                  ` : ""}
              >
                <div className={css`
                      &:hover{
                        cursor:pointer;
                      }
                      &:after{
                        background-color: ${(activeIndex >= i) ? blue : "#e6e6e6"};
                        content: '';
                        position: absolute;
                        z-index: 1;
                        left: 50%;
                        right: auto;transform: translateX(-50%);
                        height: 12px;
                        width: 12px;
                        border-radius: 50%;
                        top: 40px;
                      }
                  `}>
                  {item.title}
                </div>
              </li>
            ))} */}
          </ul>
        </div>
      </div >
    );
  }
}

export default withLanguageContext(SideNavForm);
