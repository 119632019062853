import { http } from './api';

const CLIENT = '/client';
const REMIND = CLIENT + '/remind';
const FUND = CLIENT + '/fund';
const CLIENT_USERS = (clientId) => CLIENT + '/' + clientId + '/users'

export function get(clientId) {
    return http.get(CLIENT + '/' + clientId)
}

export function create(client) {
    return http.post(CLIENT, client)
}

export function update(clientId, data) {
    return http.put(CLIENT + '/' + clientId, data)
}

export function find(query) {
    return http.get(CLIENT + '?' + new URLSearchParams(query).toString())
}

export function getFunds(clientId) {
    return http.get(FUND + '/' + clientId)
}

export function remind(clientId, type) {
    const data = {
        client_id: clientId,
        type
    }
    return http.post(REMIND, data)
}

export function getUsers(clientId) {
    return http.get(CLIENT_USERS(clientId))
}

export function removeUser(clientId, userId) {
    return http.delete(CLIENT_USERS(clientId) + '/' + userId)
}

export function addUser(clientId, user) {
    return http.post(CLIENT_USERS(clientId), { user })
}