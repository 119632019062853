import axios from 'axios';

axios.defaults.withCredentials = true;
const http = axios.create({ withCredentials: true, baseURL: process.env.REACT_APP_BASE_URL })

function serialize(obj) {
    var str = [];
    for (var p in obj)
        if (obj.hasOwnProperty(p)) {
            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
        }
    return str.join("&");
}


export { http, serialize }