import React, { useEffect, useState } from 'react';
import { CapitalCallApi } from '../api';

const useCapitalCallTotalsForSingle = (fundId, capitalCallId, rerender=0) => {
    const [status, setStatus] = useState('loading')
    const [totals, setTotals] = useState({ called: 0, received: 0 })

    useEffect(() => {
        const getTotals = async() => {
            if(!fundId || !capitalCallId) return;
            setStatus('loading')

            try {
                const { data: _totals } = await CapitalCallApi.getTotalCalledForSingle(fundId, capitalCallId)
                setTotals(_totals)
                setStatus('success')
            } catch(e) {
                setStatus('error')
            }

        }
        getTotals()
    }, [fundId, capitalCallId, rerender])

    return { status, called: totals.called, received: totals.received }
}

export { useCapitalCallTotalsForSingle }