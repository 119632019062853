import { css } from '@emotion/css';
import { useState, useEffect, useContext } from 'react';
import ToastContext from './ToastContext'
import { Spinner } from "baseui/spinner";
import { blue, grey1 } from '../data/styles';
import { Success } from '../svg';
import LanguageContext from './LanguageContext';
import { trans } from '../translations';

export const ToastProvider = ({ children }) => {
    const { language, isEnglish } = useContext(LanguageContext)
    const [toast, setToast] = useState({ title: '', subtitle: '', status: '', isOpen: false })

    function _setToast(title, subtitle, status) {
        console.log('yeah brah')
        setToast({
            title: trans(title, language), subtitle: trans(subtitle, language), status, isOpen: true
        })
        if (status === 'complete') {
            setTimeout(() => {
                _closeToast()
            }, 3000)
        }
    }

    function _closeToast() {
        setToast({
            title: '',
            subtitle: '',
            status: '',
            isOpen: false
        })
    }

    return (
        <ToastContext.Provider
            value={{
                setToast: (title, subtitle, status) => _setToast(title, subtitle, status),
                closeToast: () => _closeToast()
            }}
        >
            {children}
            {toast.isOpen &&
                <div id='toaster' key='toast123'
                    // initial={{ opacity: 1, y: 50, scale: 0.3 }}
                    // animate={{ opacity: 0.5, y: 100, scale: 1 }};
                    className={css`
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-between;
                    position: fixed;
                    background-color: rgb(234,234,234);
                    padding: 16px;
                    margin: none;
                    height: 32px;
                    width: 340px;
                    bottom: 32px;
                    right: ${isEnglish() ? '32px' : 'none'};
                    left: ${isEnglish() ? 'none' : '32px'};
                    box-shadow: 4px 4px 8px rgb(200,200,200);
                    font-family: 'Outfit';
                    direction: ${isEnglish() ? 'ltr' : 'rtl'};
                `}>
                    <div className={css`
                        display: flex; 
                        flex-direction: column;
                    `}>
                        <p>{toast.title}</p>
                        <p style={{ color: grey1, fontSize: '0.75em', marginTop: 4 }}>{toast.subtitle}</p>
                    </div>
                    {toast.status === 'loading' &&
                        <Spinner color={blue} size={24} />
                    }
                    {toast.status === 'complete' &&
                        <Success />
                    }
                </div>
            }

        </ToastContext.Provider>
    );
};

export const Consumer = ToastContext.Consumer;


export function withContext(Component) {
    return function UserManager(props) {
        return (
            <ToastContext.Consumer >
                {context => <Component {...props} context={context} />}
            </ToastContext.Consumer>
        )
    }
}