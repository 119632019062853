import { http, serialize } from './api';

const REPORT = '/report';
const EXPORT = '/report/export';
const EXPORT_IDS = EXPORT + '/ids'
const CLIENT = '/report/client'
const QUARTERLY = '/report/quarterly'
const DOWNLOAD = '/report/quarterly/download'
const ACTUAL_DOWNLOAD = '/report/quarterly/actual-download'
const ACTIVATE = '/report/quarterly/activate'
const ACCOUNT_STATEMENT = (clientId) => `/report/client/${clientId}/account-statement`

export function get(clientId) {
    return http.get(CLIENT + '/' + clientId)
}

export function find(query) {
    const _query = serialize(query)
    return http.get(REPORT + '?' + _query)
}

export function createAccountStatement(clientId) {
    // return http.post(DOWNLOAD, { clientId })
    return http.post(ACCOUNT_STATEMENT(clientId))
}

export function activateQuarterlyReports(fundId, quarter, year) {
    return http.post(ACTIVATE, { fundId, quarter, year })
}

export function createQuarterlyReports(report) {
    return http.post(QUARTERLY, report)
}

export function createExport(clientIds, fields) {
    return http.post(EXPORT, { clientIds, fields })
}

export function createSingleExport(clientId) {
    return http.post(EXPORT + '/' + clientId)
}

export function exportIds(clientIds) {
    return http.post(EXPORT_IDS, { clientIds })
}

export function download(fundId, quarter, year) {
    return http.post(ACTUAL_DOWNLOAD, { fundId, quarter, year })
}